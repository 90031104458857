import React from 'react';
import { Checkbox, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import MediaLinkRow from './media-link-row';

type MediaLink = {
  id: string;
  name: string;
  publishingState: string;
  contentType: string;
  hierarchy: string[];
};

type Props = {
  mediaLinks: MediaLink[];
  handleSelection: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  allSelected?: boolean;
  handleSelectAll?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected: (id: string) => boolean;
  showHierarchy?: boolean;
  multipleSelect?: boolean;
};

function MediaLinkTable({
  mediaLinks,
  handleSelection,
  allSelected,
  handleSelectAll,
  isSelected,
  showHierarchy = false,
  multipleSelect = true,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{multipleSelect && <Checkbox onChange={handleSelectAll} checked={allSelected} />}</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Contenttype</TableCell>
          {showHierarchy && <TableCell>TOC level</TableCell>}
          <TableCell>Publishing State</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mediaLinks?.map(ml => {
          return (
            <MediaLinkRow
              key={ml.id}
              medialink={ml}
              isSelected={isSelected(ml.id)}
              medialinkSelected={e => handleSelection(e, ml.id)}
              showHierarchy={showHierarchy}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default MediaLinkTable;
