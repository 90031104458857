import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { SchoolYear, getSchoolYears } from '../api/school-year';
import { useAppSelector } from '../hooks/store';
import { getCurrentPublishingHouse } from '../selectors/publishing-house';

type SchoolYearContextValue = {
  schoolYears: SchoolYear[];
  refreshSchoolYears: () => Promise<void>;
};

const defaultSchoolYearContextValue: SchoolYearContextValue = {
  schoolYears: [],
  refreshSchoolYears: () => Promise.resolve(),
};

const SchoolYearContext = createContext<SchoolYearContextValue>(defaultSchoolYearContextValue);

export default SchoolYearContext;

export const SchoolYearProvider = ({ children }: { children: ReactNode }) => {
  const [schoolYears, setCurrentSchoolYears] = useState<SchoolYear[]>([]);

  const publishingHouse = useAppSelector(getCurrentPublishingHouse);

  const handleGetSchoolYears = useCallback(async () => {
    if (!publishingHouse?.group) return;
    const sy = await getSchoolYears(publishingHouse.group);
    setCurrentSchoolYears(sy);
  }, [publishingHouse?.group]);

  useEffect(() => {
    handleGetSchoolYears();
  }, [handleGetSchoolYears, publishingHouse?.group]);

  const schoolYearContextValue = useMemo(
    () => ({
      schoolYears,
      refreshSchoolYears: handleGetSchoolYears,
    }),
    [schoolYears, handleGetSchoolYears],
  );

  return <SchoolYearContext.Provider value={schoolYearContextValue}>{children}</SchoolYearContext.Provider>;
};
