import React from 'react';
import { arrayOf, element, object, bool } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import interact from 'interactjs';
import { connect } from 'react-redux';
import { getIsObjectMoving, getIsDrawing } from '../../../../selectors/codex-editor';

const styles = () => ({
  stage: {
    height: '100%',
    width: '100%',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  stageChild: {
    height: 'calc(100% - 20px)',
    width: 'calc(100% - 20px)',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& #dragger': {
      position: 'relative',
    },
  },
});

function dragMoveListener(event) {
  const { target } = event;
  // keep the dragged position in the data-x/data-y attributes
  const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
  const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

  const transform = `translate(${x}px, ${y}px)`;
  target.style.webkitTransform = transform;
  target.style.transform = transform;

  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
}

export class Stage extends React.Component {
  componentDidMount() {
    const that = this;
    this.interactable = interact('#dragger').draggable({
      restrict: {
        restriction: 'parent',
        endOnly: true,
        elementRect: {
          // allow to leave parent for 75%
          top: 0.75,
          left: 0.75,
          bottom: 0.25,
          right: 0.25,
        },
      },
      onmove: e => {
        if (!that.props.isObjectMoving && !that.props.isDrawing) {
          dragMoveListener(e);
          that.props.onPan();
        }
      },
    });
  }

  shouldComponentUpdate(nextProps) {
    const { isObjectMoving } = this.props;
    return isObjectMoving === nextProps.isObjectMoving;
  }

  componentDidUpdate() {
    const { zoomToFit } = this.props;
    if (zoomToFit) {
      const dragger = document.getElementById('dragger');
      dragger.setAttribute('data-x', 0);
      dragger.setAttribute('data-y', 0);
      dragger.removeAttribute('style');
    }
  }

  componentWillUnmount() {
    this.interactable.unset();
  }

  render() {
    const { children, classes } = this.props;

    const childrenWithProps = children.map(child => React.cloneElement(child));
    return (
      <div className={classes.stage}>
        <div id="children-wrapper" className={classes.stageChild}>
          <div id="dragger">{childrenWithProps}</div>
        </div>
      </div>
    );
  }
}

Stage.propTypes = {
  zoomToFit: bool,
  children: arrayOf(element),
  classes: object,
  isObjectMoving: bool.isRequired,
};

Stage.defaultProps = {
  zoomToFit: false,
  children: [],
  classes: {},
};

const mapStateToProps = state => ({
  isObjectMoving: getIsObjectMoving(state),
  isDrawing: getIsDrawing(state),
});

export const ConnectedStage = connect(mapStateToProps)(Stage);
export default withStyles(styles)(ConnectedStage);
