import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { AccountCircle, ExitToApp as ExitToAppIcon } from '@mui/icons-material';

import withStyles from '@mui/styles/withStyles';
import { logOff } from '../services/auth-service';
import { getFullName, getEmail } from '../selectors/authentication';

const styles = {
  container: {},
  header: {
    '&:focus': {
      outline: 'none',
    },
    padding: 16,
    minWidth: 200,
    display: 'flex',
    flexDirection: 'column',
  },
};

export class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuAnchorEl: undefined,
    };
  }

  openMenu = event => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ menuAnchorEl: null });
  };

  render() {
    const { classes, fullName, emailAddress } = this.props;
    const { menuAnchorEl } = this.state;

    return (
      <div className={classes.container}>
        <IconButton
          aria-owns={menuAnchorEl ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.openMenu}
          color="inherit"
          size="large"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          id="menu-appbar"
          onClose={this.closeMenu}
          open={Boolean(menuAnchorEl)}
          MenuListProps={{
            disablePadding: true,
          }}
        >
          <ListSubheader className={classes.header} disableGutters disableSticky>
            <Typography variant="h6">{fullName}</Typography>
            <Typography variant="caption">{emailAddress}</Typography>
          </ListSubheader>
          <Divider />
          <MenuItem onClick={logOff}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object,
  emailAddress: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
};

Account.defaultProps = {
  classes: undefined,
};

const mapStateToProps = state => ({
  emailAddress: getEmail(state),
  fullName: getFullName(state),
});

export const ConnectedAccount = connect(mapStateToProps)(Account);

export default withStyles(styles)(ConnectedAccount);
