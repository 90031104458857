import Storage from '../services/storage';
import StorageKey from '../services/storage-keys';
import { ContextActionType } from '../action-types';

function initialState() {
  const publishingHouseId = Storage.get(StorageKey.PREFERRED_PUBLISHING_HOUSE);

  const state = {};
  if (publishingHouseId) state.publishingHouseId = publishingHouseId;

  return state;
}

export default function contextReducer(state = initialState(), action = {}) {
  switch (action && action.type) {
    case ContextActionType.SET_CURRENT_PUBLISHING_HOUSE: {
      const { subjectId, methodId, moduleId, ...rest } = state;

      const { publishingHouseId } = action.payload;

      return {
        ...rest,
        publishingHouseId,
      };
    }
    case ContextActionType.CLEAR_CURRENT_PUBLISHING_HOUSE: {
      const { publishingHouseId, subjectId, methodId, moduleId, ...rest } = state;

      return { ...rest };
    }
    case ContextActionType.SET_CURRENT_HIERARCHY_SUBJECT: {
      const { methodId, moduleId, ...rest } = state;

      const { subjectId } = action.payload;

      return {
        ...rest,
        subjectId,
      };
    }
    case ContextActionType.CLEAR_CURRENT_HIERARCHY_SUBJECT: {
      const { subjectId, methodId, moduleId, ...rest } = state;

      return {
        ...rest,
      };
    }
    case ContextActionType.SET_CURRENT_HIERARCHY_METHOD: {
      const { moduleId, ...rest } = state;

      const { methodId } = action.payload;

      return {
        ...rest,
        methodId,
      };
    }
    case ContextActionType.CLEAR_CURRENT_HIERARCHY_METHOD: {
      const { methodId, moduleId, ...rest } = state;

      return {
        ...rest,
      };
    }
    case ContextActionType.SET_CURRENT_HIERARCHY_MODULE: {
      const { moduleId } = action.payload || {};

      return {
        ...state,
        moduleId,
      };
    }
    case ContextActionType.CLEAR_CURRENT_HIERARCHY_MODULE: {
      const { moduleId, ...rest } = state;

      return {
        ...rest,
      };
    }
    case ContextActionType.SET_CURRENT_TOC_NODE: {
      const { tocNodeId } = action.payload || {};

      return {
        ...state,
        tocNodeId,
      };
    }
    case ContextActionType.CLEAR_CURRENT_TOC_NODE: {
      const { tocNodeId, ...rest } = state;

      return {
        ...rest,
      };
    }
    default:
      return state;
  }
}
