import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormHelperText,
  IconButton,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useFormikContext } from 'formik';
import { tss } from 'tss-react';
import { getMediaLinkById, getMediaLinksForNodeId } from '../../../../selectors/medialinks';
import MediaLinkContentType from '../../../codex-detail/components/linkareaDetails/medialinks/media-link-content-type';
import { getPublishingStateForMediaLink } from '../../../../utils/medialinks';
import MediaLinkHierarchy from '../../../codex-detail/components/linkareaDetails/medialinks/media-link-hierarchy';
import AddMedialinkModal from '../../../codex-detail/components/linkareaDetails/medialinks/add-media-link-modal';
import { useAppDispatch } from '../../../../hooks/store';
import { setCurrentNodeForMediaContext } from '../../../../actions/codex-editor';
import { fetchTableOfContentFor } from '../../../../actions/table-of-content';
import { ActiveAreaForm } from '../../utils';
import { fetchContentTypesForModule } from '../../../../actions/medialinks';
import { ACTIVE_AREA_TYPE, AUDIO_MODE } from '../../api';
import VideoEmbed from './video-embed';
import AudioEmbed from './audio-embed';

const useStyles = tss.create(() => ({
  button: {
    marginRight: '16px',
  },
  errorMessage: {
    marginTop: '16px',
  },
  container: {
    width: 'initial',
  },
  table: {
    marginBottom: '32px',
  },
}));

type Props = {
  deletedMedialink: boolean;
};

const isAudio = (mimeType: string) => mimeType.includes('audio');
const isVideo = (mimeType: string) => mimeType.includes('video');

const Embed = ({ deletedMedialink }: Props) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [openModal, setOpenModal] = React.useState(false);
  const { values, setFieldValue, errors, touched } = useFormikContext<ActiveAreaForm>();
  const selectedMedialink = useSelector(state => getMediaLinkById(state, values.medialinkId));
  const mediaLinks = useSelector(state => getMediaLinksForNodeId(state, params.nodeId));
  const medialink = selectedMedialink ?? values.medialink;
  const isAudioFile = medialink?.file?.s3file?.mimeType ? isAudio(medialink.file.s3file.mimeType) : false;
  const isVideoFile = medialink?.file?.s3file?.mimeType ? isVideo(medialink.file.s3file.mimeType) : false;

  useEffect(() => {
    const { moduleId, nodeId } = params;
    dispatch(setCurrentNodeForMediaContext(nodeId));
    dispatch(fetchTableOfContentFor(moduleId));
    dispatch(fetchContentTypesForModule(moduleId));
  }, [params, dispatch]);

  useEffect(() => {
    if (isAudioFile && !values.audioMode) setFieldValue('audioMode', AUDIO_MODE.CLASSIC);
    if (!isAudioFile && values.audioMode) setFieldValue('audioMode', null);
    if (isVideoFile) setFieldValue('audioMode', null);
    if (values.type === ACTIVE_AREA_TYPE.EMBED && !isAudioFile) setFieldValue('startSeconds', null);
    if (values.type === ACTIVE_AREA_TYPE.EMBED && !isAudioFile) setFieldValue('endSeconds', null);
  }, [isAudioFile, setFieldValue, values.audioMode, isVideoFile, values.type]);

  return (
    <>
      {openModal && (
        <AddMedialinkModal
          linkAreaMediaLinks={mediaLinks}
          handleClose={() => setOpenModal(false)}
          handleSave={val => {
            setFieldValue('medialinkId', val[0]);
            setOpenModal(false);
          }}
          multipleSelection={false}
          pageMapping={false}
          filterMediaLinksForActiveArea
        />
      )}
      <Grid container className={classes.container} margin="32px" flexDirection="column">
        <div>
          <Button
            className={classes.button}
            variant="outlined"
            disabled={Boolean(values.medialinkId) || Boolean(values.medialink)}
            onClick={() => setOpenModal(true)}
          >
            ADD MEDIA LINK
          </Button>
        </div>
        {deletedMedialink && !values.medialinkId && (
          <FormHelperText className={classes.errorMessage} error>
            Medialink has been deleted.
          </FormHelperText>
        )}
        {errors.medialinkId && touched.medialinkId && (
          <FormHelperText className={classes.errorMessage} error>
            {errors.medialinkId}
          </FormHelperText>
        )}
        {medialink && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Contenttype</TableCell>
                <TableCell>TOC level</TableCell>
                <TableCell>Publishing state</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell component="th" scope="row">
                  {medialink?.name}
                </TableCell>
                <TableCell>
                  {medialink.contentType && <MediaLinkContentType contentTypeId={medialink.contentType} />}
                </TableCell>
                <TableCell>
                  <MediaLinkHierarchy hierarchy={medialink.hierarchy} />
                </TableCell>
                <TableCell>{getPublishingStateForMediaLink(medialink.reviewState, medialink.published)}</TableCell>
                <TableCell>
                  <IconButton
                    data-testid="delete-area"
                    size="large"
                    aria-label="comments"
                    color="secondary"
                    onClick={() => {
                      setFieldValue('medialinkId', null);
                      setFieldValue('medialink', null);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {isAudioFile && <AudioEmbed />}
        {isVideoFile && <VideoEmbed />}
      </Grid>
    </>
  );
};

export default Embed;
