import React, { useContext, useEffect, useRef } from 'react';
import { tss } from 'tss-react/mui';
import { useParams } from 'react-router';
import ActiveAreaService from '../services/ActiveAreaService';
import SlideEditorContext from '../context/SlideEditorContext';
import { Shape, addActiveArea, putActiveArea } from '../api';
import { SlideEditorParams } from '../utils';
import { useAppDispatch } from '../../../hooks/store';
import { onStoreError } from '../../../store';

const useStyles = tss.create(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

type Props = {
  viewportTransform: number[];
  width: number;
  height: number;
  slideWidth: number;
  slideHeight: number;
};

export function ActiveAreaLayer({ viewportTransform, width, height, slideHeight, slideWidth }: Props) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { activeAreas, isDrawing, fetchActiveAreas } = useContext(SlideEditorContext);
  const params = useParams<SlideEditorParams>() as Required<SlideEditorParams>;
  const activeAreaServiceRef = useRef<ActiveAreaService>();

  useEffect(() => {
    const service = new ActiveAreaService('active-area-canvas', slideWidth, slideHeight);
    service.initialize(width, height);

    activeAreaServiceRef.current = service;

    return () => {
      activeAreaServiceRef.current?.destroy();
    };
    // must be an empty dependency array, see PAKP-8065
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    activeAreaServiceRef.current?.fabricCanvas.setViewportTransform(viewportTransform);
  }, [viewportTransform]);

  useEffect(() => {
    activeAreaServiceRef.current?.setDimensions(width, height);
  }, [width, height]);

  useEffect(() => {
    const onAreaMoved = async ({ id, ...shape }: Shape & { id: number }, index: number) => {
      try {
        const currentActiveArea = {
          ...activeAreas[index],
          medialinkId: activeAreas[index].medialinkMongoId,
        };

        await putActiveArea({ ...params, activeAreaId: `${currentActiveArea.id}` }, { ...currentActiveArea, ...shape });
        await fetchActiveAreas();
      } catch (e) {
        if (e instanceof Error) dispatch(onStoreError(e));
      }
    };

    const object = activeAreaServiceRef?.current?.fabricCanvas.getActiveObject();

    activeAreaServiceRef.current?.drawActiveAreas(activeAreas, onAreaMoved);

    if (object) activeAreaServiceRef.current?.restoreSelectedArea(object);
  }, [activeAreas, dispatch, fetchActiveAreas, params]);

  useEffect(() => {
    if (!activeAreaServiceRef.current) return;

    const service = activeAreaServiceRef.current;

    if (isDrawing) {
      const onAreaDrawn = async (shape: Shape) => {
        try {
          await addActiveArea(params, shape);
          await fetchActiveAreas();
        } catch (e) {
          if (e instanceof Error) dispatch(onStoreError(e));
        }
      };
      service.startDrawingMode(onAreaDrawn);
    } else {
      service.stopDrawingMode();
    }
  }, [activeAreas, dispatch, fetchActiveAreas, isDrawing, params]);

  return (
    <div className={classes.container}>
      <canvas id="active-area-canvas" data-testid="active-area-canvas" />
    </div>
  );
}
