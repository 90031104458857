import api from '../services/api';
import { SlideSetVersionState } from './slideSet';

export type SlideSetVersionStateHistory = {
  from: string;
  state: SlideSetVersionState;
};

export type SlideSetVersion = {
  id: number;
  versionNumber: number;
  state: SlideSetVersionState;
  isImporting: boolean;
  stateHistory: SlideSetVersionStateHistory[];
  updatedAt: string;
  createdAt: string;
  slideSetId: number;
};

export const postSlideSetVersion = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
): Promise<SlideSetVersion> => {
  const response = await api.post(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions`,
  );
  return response.data;
};

export const getSlideSetVersion = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
  versionId: string,
): Promise<SlideSetVersion> => {
  const response = await api.get(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions/${versionId}`,
  );
  return response.data;
};

export const getSlideSetVersions = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
): Promise<SlideSetVersion[]> => {
  const response = await api.get(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions`,
  );
  return response.data.data;
};

export const deleteSlideSetVersion = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
  versionId: string,
) => {
  return api.delete(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions/${versionId}`,
  );
};

export type CopyParams = {
  moduleId: string;
  nodeId: string;
  slideSetId: string;
  versionId: string;
};

export type CopyTarget = {
  methodId: string;
  moduleId: string;
  nodeId: string;
};

export const copySlideSetVersion = async (params: CopyParams, target: CopyTarget) => {
  const { data } = await api.post<SlideSetVersion>(
    `/shell/modules/${params.moduleId}/table-of-content/${params.nodeId}/slide-sets/${params.slideSetId}/versions/${params.versionId}/copy`,
    { target },
  );

  return data;
};

export const putSlideSetVersionState = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
  slideSetVersionId: string,
  state: SlideSetVersionState,
) => {
  return api.put(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions/${slideSetVersionId}/state`,
    { state },
  );
};
