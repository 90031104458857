import Storage from '../services/storage';
import StorageKeys from '../services/storage-keys';
import { ContextActionType } from '../action-types';

export const setCurrentPublishingHouse = publishingHouseId => {
  Storage.set(StorageKeys.PREFERRED_PUBLISHING_HOUSE, publishingHouseId);

  return {
    type: ContextActionType.SET_CURRENT_PUBLISHING_HOUSE,
    payload: {
      publishingHouseId,
    },
  };
};

export const clearCurrentPublishingHouse = () => {
  Storage.remove(StorageKeys.PREFERRED_PUBLISHING_HOUSE);

  return {
    type: ContextActionType.CLEAR_CURRENT_PUBLISHING_HOUSE,
  };
};

export const setCurrentHierarchySubject = subjectId => ({
  type: ContextActionType.SET_CURRENT_HIERARCHY_SUBJECT,
  payload: {
    subjectId,
  },
});

export const clearCurrentHierarchySubject = () => ({
  type: ContextActionType.CLEAR_CURRENT_HIERARCHY_SUBJECT,
});

export const setCurrentHierarchyMethod = methodId => ({
  type: ContextActionType.SET_CURRENT_HIERARCHY_METHOD,
  payload: {
    methodId,
  },
});

export const clearCurrentHierarchyMethod = () => ({
  type: ContextActionType.CLEAR_CURRENT_HIERARCHY_METHOD,
});

export const setCurrentHierarchyModule = moduleId => ({
  type: ContextActionType.SET_CURRENT_HIERARCHY_MODULE,
  payload: {
    moduleId,
  },
});

export const clearCurrentHierarchyModule = () => ({
  type: ContextActionType.CLEAR_CURRENT_HIERARCHY_MODULE,
});

export const setCurrentTocNode = tocNodeId => ({
  type: ContextActionType.SET_CURRENT_TOC_NODE,
  payload: {
    tocNodeId,
  },
});

export const clearCurrentTocNode = () => ({
  type: ContextActionType.CLEAR_CURRENT_TOC_NODE,
});
