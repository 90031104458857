import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TableRow, TableCell, IconButton } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Delete, Edit } from '@mui/icons-material';

import ProductionalState from '../../../../components/productional-state';
import AppPublishingState from '../../../../components/app-publishing-state';
import LinkIconButton from '../../../../components/buttons/link-icon-button';
import SlidingConfirmation from '../../../../components/sliding-confirmation';
import { editDigibookEnabled } from '../../../../selectors/digibook';

const styles = theme => ({
  icons: {
    width: 100,
    padding: theme.spacing(),
  },
  button: {
    padding: theme.spacing(0.5),
  },
});

export function DigibookRow({ digibook, classes, onDelete, editEnabledFor }) {
  return (
    <TableRow>
      <TableCell className={classes.cell}>{digibook.name}</TableCell>

      <TableCell className={classes.cell}>
        <AppPublishingState state={digibook.appPublishingState} />
      </TableCell>

      <TableCell className={classes.cell}>
        <ProductionalState productionalState={digibook.productionalState} />
      </TableCell>

      <TableCell className={classes.icons}>
        <LinkIconButton
          buttonProps={{
            id: 'edit',
            className: classes.button,
            color: 'primary',
            disabled: !editEnabledFor(digibook),
          }}
          to={`/codex/${digibook.id}`}
        >
          <Edit />
        </LinkIconButton>

        <SlidingConfirmation
          title={`Are you sure you want to delete the digibook: ${digibook.name}?`}
          context="Beware, by executing this action your digibook will be permanently deleted."
          disagreeAction="No"
          agreeAction="Yes, Delete"
        >
          <IconButton
            id="delete"
            disabled={!!digibook.appPublishingState}
            className={classes.button}
            onClick={() => onDelete(digibook.id)}
            color="secondary"
            size="large"
          >
            <Delete />
          </IconButton>
        </SlidingConfirmation>
      </TableCell>
    </TableRow>
  );
}

DigibookRow.propTypes = {
  onDelete: PropTypes.func.isRequired,
  editEnabledFor: PropTypes.func.isRequired,
  digibook: PropTypes.object,
  classes: PropTypes.object,
};

DigibookRow.defaultProps = {
  digibook: {},
  classes: {},
};

const mapStateToProps = state => ({
  editEnabledFor: digibook => editDigibookEnabled(state, digibook),
});

export const ConnectedDigibookRow = connect(mapStateToProps)(DigibookRow);

export default connect(mapStateToProps)(withStyles(styles)(DigibookRow));
