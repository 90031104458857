import classNames from 'classnames';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  ChromeReaderMode as BookIcon,
  CalendarToday as CalendarIcon,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  Menu as MenuIcon,
  PublishedWithChanges,
  Store as StoreIcon,
} from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { tss } from 'tss-react/mui';

import Role from '../../constants/roles';
import { useAppSelector } from '../../hooks/store';
import { getRoles } from '../../selectors/authentication';
import Account from '../account';
import PublishingHouseSelect from '../hierarchy/publishing-house-select-with-context';

const useStyles = tss.create(({ theme }) => ({
  container: {
    flexGrow: 1,
  },
  appBar: {
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${theme.drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.drawerWidth,
  },
  appFrame: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    // @ts-ignore
    ...(theme.mixins.toolbar as Record<CSSPropertyRule, CSSPropertyRule[string]>),
  },
  drawerHeaderLeft: {
    flexGrow: 1,
  },
  drawerHeaderTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  drawerPaper: {
    position: 'relative',
    width: theme.drawerWidth,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 16,
  },
  publishingHouseContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    paddingLeft: theme.spacing(),
    paddingTop: 2,
    paddingBottom: 2,
    marginRight: theme.spacing(2),
  },
  publishingHouseSelect: {
    color: 'inherit',
    marginLeft: 8,
    minWidth: 235,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -theme.drawerWidth + 12,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  hide: {
    display: 'none',
  },
}));

type ShellProps = {
  subTitle?: string;
  children: React.ReactNode;
};

export default function Shell({ subTitle, children }: ShellProps) {
  const { cx, classes } = useStyles();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const roles = useAppSelector(getRoles);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.appFrame}>
        <AppBar
          className={classNames(classes.appBar, {
            [classes.appBarShift]: isDrawerOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={openDrawer}
              className={classNames(classes.menuButton, isDrawerOpen && classes.hide)}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              P Content Beheer
              {subTitle && ` (${subTitle})`}
            </Typography>
            <div className={classes.publishingHouseContainer}>
              <StoreIcon />
              <PublishingHouseSelect className={classes.publishingHouseSelect} />
            </div>
            <Account />
          </Toolbar>
        </AppBar>

        <Drawer
          id="drawer"
          variant="persistent"
          open={isDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div className={classes.drawerHeaderLeft}>
              <Typography variant="h6" className={classes.drawerHeaderTitle}>
                P-SHELL
              </Typography>
              <Typography variant="caption">
                v &nbsp;
                {/** @ts-expect-error global variable */}
                {VERSION}
              </Typography>
            </div>
            <IconButton onClick={closeDrawer} size="large">
              <ChevronLeftIcon />
            </IconButton>
          </div>

          <Divider />

          <List>
            <ListItemButton component={NavLink} to="/" onClick={closeDrawer}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>

            <ListItemButton component={NavLink} to="/codex" onClick={closeDrawer}>
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="Digibooks" />
            </ListItemButton>
            <ListItemButton component={NavLink} to="/digislides" onClick={closeDrawer}>
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="DigiSlides" />
            </ListItemButton>
          </List>

          {roles.includes(Role.SYSADMIN) && (
            <>
              <Divider />

              <Typography variant="h6" className={cx([classes.drawerHeader, classes.drawerHeaderTitle])}>
                ADMIN
              </Typography>

              <List>
                <ListItemButton component={NavLink} to="/admin/modules/phased-out" onClick={closeDrawer}>
                  <ListItemIcon>
                    <PublishedWithChanges />
                  </ListItemIcon>
                  <ListItemText primary="Phased out modules" />
                </ListItemButton>
                <ListItemButton component={NavLink} to="/admin/school-years" onClick={closeDrawer}>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText primary="School years" />
                </ListItemButton>
                <ListItemButton component={NavLink} to="/admin/maintenance-mode" onClick={closeDrawer}>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Maintenance Mode" />
                </ListItemButton>
              </List>
            </>
          )}
        </Drawer>

        <main
          className={classNames(classes.content, {
            [classes.contentShift]: isDrawerOpen,
          })}
        >
          {children}
        </main>
      </div>
    </div>
  );
}
