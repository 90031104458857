import React, { MouseEventHandler } from 'react';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Uploader from 'rc-upload';
import { Cancel } from '@mui/icons-material';
import { tss } from 'tss-react/mui';

const useAdornmentStyles = tss.create(({ theme }) => ({
  icon: {
    padding: theme.spacing(0.5),
  },
}));

type ClearAdornmentProps = {
  onClear: () => void;
  disabled?: boolean;
};

function ClearAdornment({ onClear, disabled = false }: ClearAdornmentProps) {
  const { classes } = useAdornmentStyles();

  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();

    onClear();
  };

  return (
    <InputAdornment position="end">
      <IconButton onClick={handleClick} className={classes.icon} size="large" disabled={disabled}>
        <Cancel color="secondary" />
      </IconButton>
    </InputAdornment>
  );
}

type Props = {
  id: string;
  displayName: string;
  label: string;
  value?: File;
  isUploading: boolean;
  fileSelected: (name: string, file: File) => void;
  onClear: (name: string) => void;
};

function UploadFormControl({ id, displayName, label, value, isUploading, fileSelected, onClear }: Props) {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel htmlFor={id} shrink variant="outlined">
        {label}
      </InputLabel>
      <Uploader
        id={id}
        customRequest={({ file }) => fileSelected(displayName, file as File)}
        accept="application/pdf"
        disabled={isUploading}
      >
        <OutlinedInput
          type="text"
          name={displayName}
          value={(value && value.name) || ''}
          notched
          label={label}
          placeholder="Click to upload a file"
          readOnly
          fullWidth
          endAdornment={value && <ClearAdornment disabled={isUploading} onClear={() => onClear(displayName)} />}
        />
      </Uploader>
    </FormControl>
  );
}

export default UploadFormControl;
