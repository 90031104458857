import React, { useContext } from 'react';
import { Delete, Edit, PanToolAltOutlined } from '@mui/icons-material';
import {
  Badge,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { tss } from 'tss-react/mui';
import { useParams } from 'react-router';
import classNames from 'classnames';

import LinkIconButton from '../../../components/buttons/link-icon-button';
import SlideEditorContext from '../context/SlideEditorContext';
import SlidingConfirmation from '../../../components/sliding-confirmation';
import { ACTIVE_AREA_TYPE, deleteActiveArea } from '../api';
import { SlideEditorParams } from '../utils';
import { useAppDispatch } from '../../../hooks/store';
import { onStoreError } from '../../../store';

const useStyles = tss.create(() => ({
  subtitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '0.75rem',
  },
  badge: {
    top: 20,
    border: '2px solid #fff',
  },
  warning: {
    color: 'red',
  },
  text: {
    flex: 2,
  },
  type: {
    flex: 1,
  },
}));

const ACTIVE_AREA_TYPE_TRANSLATION = {
  [ACTIVE_AREA_TYPE.REVEAL]: 'Reveal',
  [ACTIVE_AREA_TYPE.NAVIGATION]: 'Slide link',
  [ACTIVE_AREA_TYPE.WEBLINK]: 'Weblink',
  [ACTIVE_AREA_TYPE.EMBED]: 'Embed',
};

export default function ActiveAreas() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { slideInfo, activeAreas, isDrawing, setIsDrawing, fetchActiveAreas } = useContext(SlideEditorContext);
  const params = useParams<SlideEditorParams>() as Required<SlideEditorParams>;

  async function onDelete(id: number) {
    try {
      await deleteActiveArea({ ...params, areaId: id });
      await fetchActiveAreas();
    } catch (e) {
      if (e instanceof Error) dispatch(onStoreError(e));
    }
  }

  return (
    <>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Active areas
      </Typography>
      <Button
        color={isDrawing ? 'secondary' : 'primary'}
        variant="contained"
        onClick={() => {
          setIsDrawing(prev => !prev);
        }}
        disabled={!slideInfo?.slideSourceUrl}
      >
        {isDrawing ? 'STOP DRAWING' : 'START DRAWING'}
      </Button>
      <List>
        {activeAreas.map((area, index) => {
          const deletedSlideLink = area.type === ACTIVE_AREA_TYPE.NAVIGATION && !area.linkedSlideId;
          const deletedMedialink = area.type === ACTIVE_AREA_TYPE.EMBED && !area.medialinkId;
          return (
            <React.Fragment key={area.id}>
              <ListItem
                secondaryAction={
                  <>
                    <LinkIconButton
                      aria-label="comments"
                      to={`active-area/${area.id}`}
                      buttonProps={{
                        'data-testid': 'edit-area',
                        id: 'edit',
                        size: 'large',
                        color: 'primary',
                      }}
                    >
                      <Edit />
                    </LinkIconButton>
                    <SlidingConfirmation
                      title="Warning: permanent delete"
                      context="This area will be permanently deleted. It cannot be restored."
                      disagreeAction="Cancel"
                      agreeAction="Delete"
                      isWarning
                    >
                      <IconButton
                        data-testid="delete-area"
                        size="large"
                        aria-label="comments"
                        color="secondary"
                        onClick={() => onDelete(area.id)}
                      >
                        <Delete />
                      </IconButton>
                    </SlidingConfirmation>
                  </>
                }
              >
                <ListItemIcon>
                  <Badge
                    color="secondary"
                    badgeContent={index + 1}
                    classes={{ badge: classes.badge }}
                    overlap="rectangular"
                  >
                    <PanToolAltOutlined />
                  </Badge>
                </ListItemIcon>
                <ListItemText
                  data-testid={`area-text-${area.id}`}
                  className={classNames(
                    classes.text,
                    deletedMedialink || deletedSlideLink ? classes.warning : undefined,
                  )}
                  primary={area.name}
                />
                <ListItemText
                  data-testid={`area-type-${area.id}`}
                  className={classNames(
                    classes.type,
                    deletedMedialink || deletedSlideLink ? classes.warning : undefined,
                  )}
                  primary={ACTIVE_AREA_TYPE_TRANSLATION[area.type]}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </>
  );
}
