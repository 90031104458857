import React, { useState } from 'react';
import { Alert, Button, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';
import Uploader from 'rc-upload';
import { UploadRequestError, UploadRequestOption } from 'rc-upload/lib/interface';
import { Add } from '@mui/icons-material';
import api from '../../services/api';
import { parseCSVFile } from '../../api/parse-csv';

const useStyles = tss.create(({ theme }) => ({
  root: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  table: {
    border: `1px solid ${theme.palette.divider}`,
    '& tr': {
      '& td': {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1),
      },
    },
  },
}));

export default function PhasedOutModules() {
  const { classes } = useStyles();

  const [uploadState, setUploadState] = useState<
    'empty' | 'processing' | 'finished' | 'failed-upload' | 'failed-parsing'
  >('empty');

  const parseCSV = async ({ file, onSuccess, onError }: UploadRequestOption) => {
    try {
      const transformedFile = file as File;
      setUploadState('processing');

      const {
        data: { data: csvData },
      } = await parseCSVFile<{ licenseSettingId: number; replacementVoucherSettingId: number }>(transformedFile);

      if (onSuccess) onSuccess(csvData);
    } catch (err) {
      if (onError) onError(err as UploadRequestError);
    }
  };

  const onCSVParsingSuccess = async csvData => {
    try {
      setUploadState('processing');
      await api.post('/shell/admin/modules/phased-out', { replacements: csvData });

      setUploadState('finished');
    } catch (e) {
      setUploadState('failed-upload');
    }
  };

  const onCSVParsingError = () => {
    setUploadState('failed-parsing');
  };

  const canUpload = uploadState !== 'processing';

  return (
    <div className={classes.root}>
      <Typography>
        Import for phased out modules license configuration. Import CSV file below to run. Expects ; delimited csv with
        licenseSettingId and replacementVoucherSettingId as columns.
      </Typography>
      <Uploader
        disabled={!canUpload}
        accept=".csv"
        onSuccess={onCSVParsingSuccess}
        onError={onCSVParsingError}
        customRequest={parseCSV}
      >
        <Button variant="contained" data-testid="select-file-button" disabled={!canUpload}>
          <Add /> Select File
        </Button>
      </Uploader>
      {uploadState === 'processing' && (
        <Alert severity="info">Processing file. This may take a while depending on the size of the file.</Alert>
      )}
      {uploadState === 'finished' && <Alert severity="success">Import successful!</Alert>}
      {uploadState === 'failed-parsing' && (
        <Alert severity="error">
          Something went wrong while parsing your file. Please try adjusting it and reupload.
        </Alert>
      )}
      {uploadState === 'failed-upload' && (
        <Alert severity="error">Something went wrong while importing your data.</Alert>
      )}
    </div>
  );
}
