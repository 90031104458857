/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  List,
  ListItem,
  FormHelperText,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector, useDispatch } from 'react-redux';
import { addManualMapping } from '../../../actions/codex-editor';
import MappingRow from './digibook-manual/mapping-row';
import { getManualMappingForCurrentBook } from '../../../selectors/codex-editor';

const useStyles = makeStyles(() => ({
  fixedColumn: {
    width: '100px',
  },
  subtitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '0.75rem',
  },
}));

export default function DigibookManual() {
  const classes = useStyles();
  const manualMapping = useSelector(getManualMappingForCurrentBook);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState();

  const handleError = useCallback((error, id) => {
    setErrors(prevState => ({ ...prevState, [id]: error }));
  }, []);

  const clearError = useCallback(id => {
    setErrors(({ [id]: _, ...nextErrors }) => nextErrors);
  }, []);

  return (
    <>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Page ranges to skip
      </Typography>
      <Button color="primary" onClick={() => dispatch(addManualMapping())}>
        ADD
      </Button>
      {manualMapping.length > 0 && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.fixedColumn}>Layer</TableCell>
              <TableCell className={classes.fixedColumn}>From</TableCell>
              <TableCell className={classes.fixedColumn}>To</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {manualMapping.map((mapping, index) => (
              <MappingRow
                key={mapping.id || mapping.tempId}
                index={index}
                mapping={mapping}
                onError={handleError}
                onClearError={clearError}
              />
            ))}
          </TableBody>
        </Table>
      )}
      {errors && Object.values(errors).length > 0 && (
        <List>
          {[...new Set(Object.values(errors).flatMap(x => Object.values(x)))].map((error, index) => (
            <ListItem key={index}>
              <FormHelperText error>{error}</FormHelperText>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
}
