import React from 'react';
import { tss } from 'tss-react/mui';
import { useDrag } from 'react-dnd';

import { SlideGroup } from '../../../api/slideSet';
import { DND_TYPES } from '../drag-and-drop-utils';
import SlideDisplay from './slide-display';

type SingleSlideProps = {
  group: SlideGroup;
  slide: SlideGroup['slides'][number];
  indexString: string;
  refetchData: () => void;
};

const useStyles = tss.create(() => ({
  slide: {
    width: '100%',
  },
}));

export default function SingleSlide({ group, slide, indexString, refetchData }: SingleSlideProps) {
  const { classes } = useStyles();

  const type = DND_TYPES.SINGLE_GROUP;
  const [{ isDragging }, dragRef, previewRef] = useDrag(
    () => ({
      type,
      item: { group, slide, type },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [group, slide],
  );

  return (
    <div ref={previewRef} className={classes.slide}>
      <SlideDisplay
        refetchData={refetchData}
        group={group}
        slide={slide}
        indexString={indexString}
        isDragging={isDragging}
        ref={dragRef}
      />
    </div>
  );
}
