/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Select } from '@mui/material';

import { fetchSubjectsByPublishingHouse } from '../../actions/subject';
import getSubjectsByPublishingHouse from '../../selectors/subject';

export class SubjectSelect extends React.Component {
  componentDidMount() {
    const { fetchSubjectsBy, publishingHouseId } = this.props;
    fetchSubjectsBy(publishingHouseId);
  }

  componentDidUpdate(prevProps) {
    const { publishingHouseId: prevPublishingHouseId } = prevProps;
    const { publishingHouseId, fetchSubjectsBy } = this.props;

    if (prevPublishingHouseId !== publishingHouseId) fetchSubjectsBy(publishingHouseId);
  }

  render() {
    const {
      autoFocus,
      inputProps: { id, name, className },
      subjects,
      onChange = () => {},
      value,
    } = this.props;

    return (
      <Select
        variant="standard"
        autoFocus={autoFocus}
        className={className}
        id={id}
        name={name}
        native
        onChange={e => onChange(e.target.value)}
        value={value}
      >
        <option value="" />
        {subjects.map(subject => (
          <option value={subject.id} key={`subject-${subject.id}`}>
            {subject.name}
          </option>
        ))}
      </Select>
    );
  }
}

SubjectSelect.propTypes = {
  // Real props
  autoFocus: PropTypes.bool,
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
  }),
  onChange: PropTypes.func,
  publishingHouseId: PropTypes.string.isRequired,
  value: PropTypes.string,

  // Connected props
  fetchSubjectsBy: PropTypes.func.isRequired,
  subjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

SubjectSelect.defaultProps = {
  autoFocus: undefined,
  inputProps: {},
  onChange: undefined,
  subjects: [],
  value: undefined,
};

const mapStateToProps = (state, props) => ({
  subjects: getSubjectsByPublishingHouse(state, props),
});

const mapDispatchToProps = {
  fetchSubjectsBy: fetchSubjectsByPublishingHouse,
};

export const ConnectedSubjectSelect = connect(mapStateToProps, mapDispatchToProps)(SubjectSelect);

export default ConnectedSubjectSelect;
