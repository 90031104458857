import { SecuredFileActionType } from '../action-types';

export default function securedFileReducer(state = {}, action = {}) {
  switch (action && action.type) {
    case SecuredFileActionType.FETCH_FILE_BY_ID_SUCCESS: {
      const { entities, ...rest } = state;
      const { file } = action.payload;

      return {
        entities: {
          ...entities,
          [file.id]: file,
        },
        ...rest,
      };
    }
    case SecuredFileActionType.CLEAR_FILE_BY_ID:
    case SecuredFileActionType.FETCH_FILE_BY_ID_FAILURE: {
      const { entities, ...rest } = state;
      const { id } = action.payload;
      return {
        entities: {
          ...entities,
          [id]: undefined,
        },
        ...rest,
      };
    }
    case SecuredFileActionType.UPLOAD_FILE_STARTED: {
      const { entities, ...rest } = state;
      const { file, source } = action.payload;

      return {
        entities: {
          ...entities,
          [file.id]: {
            name: file.name,
            source,
          },
        },
        ...rest,
      };
    }
    case SecuredFileActionType.UPLOAD_FILE_REQUESTED: {
      const { uploading, ...rest } = state;
      const { form, field } = action.payload;

      return {
        uploading: {
          ...uploading,
          [form + field]: true,
        },
        ...rest,
      };
    }
    case SecuredFileActionType.UPLOAD_FILE_SUCCESS: {
      const { uploading, ...rest } = state;
      const { form, field } = action.payload;

      return {
        uploading: {
          ...uploading,
          [form + field]: false,
        },
        ...rest,
      };
    }
    case SecuredFileActionType.UPLOAD_FILE_FAILED: {
      const { entities, uploading, ...rest } = state;
      const { id, form, field } = action.payload;

      return {
        entities: {
          ...entities,
          [id]: undefined,
        },
        uploading: {
          ...uploading,
          [form + field]: false,
        },
        ...rest,
      };
    }
    default: {
      return state;
    }
  }
}
