import { DigibookActionType } from '../action-types';

export default function digibookReducer(state = {}, action = {}) {
  switch (action && action.type) {
    case DigibookActionType.CLEAR_LAST_ADDED: {
      const { lastAdded, ...rest } = state;

      return {
        ...rest,
      };
    }
    case DigibookActionType.DIGIBOOK_UPDATED: {
      const { entities, byModule, ...rest } = state;
      const { id: digibookId, module: moduleId } = action.payload;

      const { lastUpdated, ...restOfByModuleState } = byModule[moduleId] || {};

      return {
        entities: {
          ...entities,
          [digibookId]: action.payload,
        },
        byModule: {
          ...byModule,
          [moduleId]: {
            ...restOfByModuleState,
          },
        },
        ...rest,
      };
    }
    case DigibookActionType.DIGIBOOK_ADDED: {
      const { entities, byModule = {}, ...rest } = state;
      const { id: digibookId, module: moduleId } = action.payload;

      const { ids, lastUpdated, ...restOfByModuleState } = byModule[moduleId] || {};

      return {
        entities: {
          ...entities,
          [digibookId]: action.payload,
        },
        byModule: {
          ...byModule,
          [moduleId]: {
            ...restOfByModuleState,
            ids: [...(ids || []), digibookId],
          },
        },
        lastAdded: digibookId,
        ...rest,
      };
    }
    case DigibookActionType.FETCH_DIGIBOOKS_BY_MODULE: {
      const { moduleId } = action.payload;
      const { entities, byModule, ...rest } = state;

      return {
        entities,
        byModule: {
          ...byModule,
          [moduleId]: {
            ...(byModule || {})[moduleId],
            isFetching: true,
          },
        },
        ...rest,
      };
    }
    case DigibookActionType.FETCH_DIGIBOOKS_BY_MODULE_FAILURE: {
      const { moduleId } = action.payload;
      const { entities, byModule, ...restOfState } = state;

      const { isFetching, ...rest } = byModule[moduleId];

      return {
        entities: {
          ...entities,
        },
        byModule: {
          ...byModule,
          [moduleId]: {
            ...rest,
          },
        },
        ...restOfState,
      };
    }
    case DigibookActionType.FETCH_DIGIBOOKS_BY_MODULE_SUCCESS: {
      const { moduleId, entities: digibooks, ids } = action.payload;
      const { entities, byModule, ...rest } = state;

      return {
        entities: {
          ...entities,
          ...digibooks,
        },
        byModule: {
          ...byModule,
          [moduleId]: {
            ids,
            lastUpdated: Date.now(),
          },
        },
        ...rest,
      };
    }
    case DigibookActionType.FETCH_DIGIBOOK_BY_ID_SUCCESS: {
      const { digibook } = action.payload;
      const { entities, ...rest } = state;

      return {
        entities: {
          ...entities,
          [digibook.id]: digibook,
        },
        ...rest,
      };
    }
    case DigibookActionType.FETCH_DIGIBOOK_BY_ID_FAILURE: {
      return state;
    }
    case DigibookActionType.DELETE_DIGIBOOK_SUCCESS: {
      const { id } = action.payload;
      const { entities, byModule } = state;

      const moduleToUpdate = entities[id].module;
      const filteredModule = byModule[moduleToUpdate];
      filteredModule.ids = filteredModule.ids.filter(i => i !== id);

      byModule[moduleToUpdate] = filteredModule;
      delete entities[id];

      return {
        entities,
        byModule,
      };
    }
    default:
      return state;
  }
}
