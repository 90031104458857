import get from 'get-value';
import { normalize, schema } from 'normalizr';
import api from '../services/api';

import { ModuleActionType } from '../action-types';

const fetchModulesByMethodRequested = methodId => ({
  type: ModuleActionType.FETCH_MODULES_BY_METHOD,
  payload: {
    methodId,
  },
});

const fetchModulesByMethodSuccess = (methodId, entities, ids) => ({
  type: ModuleActionType.FETCH_MODULES_BY_METHOD_SUCCESS,
  payload: {
    methodId,
    entities,
    ids,
  },
});

const fetchModulesByMethodFailed = methodId => ({
  type: ModuleActionType.FETCH_MODULES_BY_METHOD_FAILURE,
  payload: {
    methodId,
  },
});

export function forceFetchModulesByMethod(methodId) {
  return dispatch => {
    dispatch(fetchModulesByMethodRequested(methodId));

    return api
      .get('/shell/modules', {
        params: {
          orderBy: 'name',
          methodId,
        },
      })
      .then(({ data: { data } }) => {
        const {
          entities: { modules: entities },
          result: ids,
        } = normalize(data, [new schema.Entity('modules')]);

        dispatch(fetchModulesByMethodSuccess(methodId, entities, ids));
      })
      .catch(err => {
        dispatch(fetchModulesByMethodFailed(methodId));
        throw err;
      });
  };
}

export function fetchModulesByMethod(methodId) {
  return (dispatch, getState) => {
    const moduleData = get(getState(), ['module', 'byMethod', methodId]) || {};

    if (moduleData.isFetching || moduleData.lastUpdated) return Promise.resolve();

    return dispatch(forceFetchModulesByMethod(methodId));
  };
}
