import get from 'get-value';
import { normalize, schema } from 'normalizr';
import api from '../services/api';

import { MethodActionType } from '../action-types';

const fetchMethodsBySubjectRequested = subjectId => ({
  type: MethodActionType.FETCH_METHODS_BY_SUBJECT,
  payload: {
    subjectId,
  },
});

const fetchMethodsBySubjectSuccess = (subjectId, entities, ids) => ({
  type: MethodActionType.FETCH_METHODS_BY_SUBJECT_SUCCESS,
  payload: {
    subjectId,
    entities,
    ids,
  },
});

const fetchMethodsBySubjectFailed = subjectId => ({
  type: MethodActionType.FETCH_METHODS_BY_SUBJECT_FAILURE,
  payload: {
    subjectId,
  },
});

/**
 * Fetch the methods belonging to the specified subject.
 *
 * @export
 * @param {string} subjectId
 */
export function forcefetchMethodsBySubject(subjectId) {
  return dispatch => {
    dispatch(fetchMethodsBySubjectRequested(subjectId));

    return api
      .get('/shell/methods', {
        params: {
          orderBy: 'name',
          subjectId,
        },
      })
      .then(({ data: { data } }) => {
        const {
          entities: { methods: entities },
          result: ids,
        } = normalize(data, [new schema.Entity('methods')]);

        dispatch(fetchMethodsBySubjectSuccess(subjectId, entities, ids));
      })
      .catch(err => {
        dispatch(fetchMethodsBySubjectFailed(subjectId));
        throw err;
      });
  };
}

/**
 * Fetch the methods belonging to the specified subject if not already in state.
 *
 * @export
 * @param {string} subjectId
 */
export function fetchMethodsBySubject(subjectId) {
  return (dispatch, getState) => {
    const subjectData = get(getState(), ['method', 'bySubject', subjectId]) || {};

    if (subjectData.isFetching || subjectData.lastUpdated) return Promise.resolve();

    return dispatch(forcefetchMethodsBySubject(subjectId));
  };
}
