import withStyles from '@mui/styles/withStyles';
import { object } from 'prop-types';
import { hot } from 'react-hot-loader';
import { Outlet, Route, RouterProvider, createRoutesFromElements } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import { Protected } from './components/protected';

import Shell from './components/layouts/shell';
import Splash from './components/layouts/splash';
import MaintenanceMode from './components/maintenance-mode';
import Notifier from './components/notifications/notifier';
import Login from './components/splash-cards/login';
import NotFound from './components/splash-cards/not-found';
import Codex from './modules/codex';
import DigibookDetail from './modules/codex-detail';
import Home from './modules/home';
import CallBack from './modules/oauth2-redirect/callback';

import HierarchyBuilder from './components/hierarchy-builder';
import DetailView from './components/layouts/detailview';
import Role from './constants/roles';
import { SchoolYearProvider } from './contexts/school-year-context';
import { VersionContextProvider } from './contexts/slideset-version-context';
import ReplacedModules from './modules/phased-out-modules';
import SchoolYears from './modules/school-years';
import MaintenanceMessageMode from './modules/maintenance-mode';
import ActiveAreaEditor from './modules/slide-editor/active-area-editor';
import { SlideEditorContextProvider } from './modules/slide-editor/context/SlideEditorContext';
import SlideEditor from './modules/slide-editor/slide-editor';
import SlidesImporter from './modules/slides-importer/slides-importer';
import SlideSetDetail from './modules/slideset-detail';
import SlidesetVersionDetail from './modules/slideset-version-detail/slideset-version-detail';
import SlideSets from './modules/slidesets';
import { SlidesetVersionGroupsContextProvider } from './modules/slideset-version-detail/context/slideset-version-groups-context';

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  notifier: {
    marginBottom: 24,
  },
});

export const routes = (
  <Route>
    <Route
      path="/login"
      element={
        <Splash>
          <Login />
        </Splash>
      }
    />
    <Route path="/oauth2-redirect" element={<CallBack />} />
    <Route element={<Protected requiresAnyOfRoles={[Role.SYSADMIN, Role.PRODUCTION, Role.EXTERNAL]} />}>
      <Route
        path="/codex"
        element={
          <Shell subTitle="Codex">
            <Codex />
          </Shell>
        }
      />
      <Route path="/codex/:id" element={<DigibookDetail />} />
      <Route
        path="/digislides"
        element={
          <Shell subTitle="SlideSets">
            <SlideSets />
          </Shell>
        }
      />
      <Route path="/modules/:moduleId/table-of-content/:nodeId" element={<HierarchyBuilder />}>
        <Route path="slide-sets/:setId" element={<Outlet />}>
          <Route
            index
            element={
              <Shell subTitle="SlideSets">
                <VersionContextProvider>
                  <SlideSetDetail />
                </VersionContextProvider>
              </Shell>
            }
          />
          <Route
            path="versions/:versionId"
            element={
              <SlidesetVersionGroupsContextProvider>
                <Outlet />
              </SlidesetVersionGroupsContextProvider>
            }
          >
            <Route
              index
              element={
                <DetailView title="Slide Set Editor" previousPage="..">
                  <SlidesetVersionDetail />
                </DetailView>
              }
            />
            <Route path="import" element={<SlidesImporter />} />
            <Route path="groups/:linkId/slides/:slideId" element={<Outlet />}>
              <Route
                index
                element={
                  <DetailView title="DigiSlide-Editor" previousPage="..">
                    <SlideEditorContextProvider>
                      <SlideEditor />
                    </SlideEditorContextProvider>
                  </DetailView>
                }
              />
              <Route path="active-area/:activeAreaId" element={<ActiveAreaEditor />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
    <Route element={<Protected requiresAnyOfRoles={[Role.SYSADMIN]} />}>
      <Route path="/admin" element={<Outlet />}>
        <Route
          path="modules/phased-out"
          element={
            <Shell subTitle="Phased out modules">
              <ReplacedModules />
            </Shell>
          }
        />
        <Route
          path="school-years"
          element={
            <Shell subTitle="School years">
              <SchoolYearProvider>
                <SchoolYears />
              </SchoolYearProvider>
            </Shell>
          }
        />
        <Route
          path="maintenance-mode"
          element={
            <Shell subTitle="Maintenance Mode">
              <MaintenanceMessageMode />
            </Shell>
          }
        />
      </Route>
    </Route>
    <Route element={<Protected />}>
      <Route
        path="/"
        element={
          <Shell>
            <Home />
          </Shell>
        }
      />
    </Route>
    <Route
      path="*"
      element={
        <Splash>
          <NotFound />
        </Splash>
      }
    />
  </Route>
);

const router = createBrowserRouter(createRoutesFromElements(routes));

export function App({ classes }) {
  return (
    <div className={classes.root}>
      <MaintenanceMode>
        <RouterProvider router={router} />
      </MaintenanceMode>

      <Notifier className={classes.notifier} />
    </div>
  );
}

App.defaultProps = {
  classes: {},
};

App.propTypes = {
  classes: object,
};

export default hot(module)(withStyles(styles)(App));
