import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { tss } from 'tss-react';
import { ActiveAreaForm } from '../../utils';

const useStyles = tss.create(() => ({
  secondsInput: {
    width: '150px',
  },
  checkbox: {
    marginRight: '8px',
  },
  checkboxLabel: {
    marginLeft: 0,
  },
  label: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

const VideoEmbed = () => {
  const { values, errors, setFieldValue, handleChange, touched } = useFormikContext<ActiveAreaForm>();
  const { classes } = useStyles();
  const [enableStartTime, setEnableStartTime] = React.useState(Number.isInteger(values.startSeconds));
  const [enableEndTime, setEnableEndTime] = React.useState(Number.isInteger(values.endSeconds));

  return (
    <FormControl component="fieldset">
      <FormLabel className={classes.label}>Specify video start and end time:</FormLabel>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          className={classes.checkboxLabel}
          value="top"
          control={
            <Checkbox
              size="small"
              className={classes.checkbox}
              checked={enableStartTime}
              onChange={() => {
                setFieldValue('startSeconds', enableStartTime ? null : 0);
                setEnableStartTime(!enableStartTime);
              }}
            />
          }
          label="Start"
          labelPlacement="start"
        />
        <TextField
          name="startSeconds"
          className={classes.secondsInput}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          InputProps={{
            inputProps: {
              min: 0,
              'data-testid': 'startSeconds',
            },
            endAdornment: <InputAdornment position="end">sec</InputAdornment>,
          }}
          disabled={!enableStartTime}
          value={values.startSeconds}
          onChange={handleChange}
        />
        <FormControlLabel
          value="top"
          control={
            <Checkbox
              size="small"
              className={classes.checkbox}
              checked={enableEndTime}
              onChange={() => {
                setFieldValue('endSeconds', enableEndTime ? null : 0);
                setEnableEndTime(!enableEndTime);
              }}
            />
          }
          label="End"
          labelPlacement="start"
        />
        <TextField
          name="endSeconds"
          className={classes.secondsInput}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          InputProps={{
            inputProps: {
              min: 0,
              'data-testid': 'endSeconds',
            },
            endAdornment: <InputAdornment position="end">sec</InputAdornment>,
          }}
          disabled={!enableEndTime}
          value={values.endSeconds}
          onChange={handleChange}
        />
      </FormGroup>
      {errors.endSeconds && touched.endSeconds && <FormHelperText error>{errors.endSeconds}</FormHelperText>}
    </FormControl>
  );
};

export default VideoEmbed;
