import api from '../../services/api';

type BaseParams = {
  moduleId: string;
  nodeId: string;
  setId: string;
  versionId: string;
  linkId: string;
  slideId: string;
};

export function updatePreviewText({
  moduleId,
  nodeId,
  setId,
  versionId,
  linkId,
  previewText,
}: BaseParams & { previewText: string }) {
  return api.put(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${linkId}/preview-text`,
    { previewText },
  );
}

export type SlideInfo = {
  previewText: string;
  slideSourceId?: number;
  slideSourceMongoId?: string;
  revealSourceId?: number;
  revealSourceMongoId?: string;
  slideSourceUrl?: string;
  revealSourceUrl?: string;
};

export async function getSlideInfo({ moduleId, nodeId, setId, versionId, linkId, slideId }: BaseParams) {
  const { data } = await api.get<SlideInfo>(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${linkId}/slides/${slideId}`,
  );

  return data;
}

export const ACTIVE_AREA_TYPE = {
  REVEAL: 'REVEAL',
  NAVIGATION: 'NAVIGATION',
  WEBLINK: 'WEBLINK',
  EMBED: 'EMBED',
} as const;

export type ActiveAreaType = (typeof ACTIVE_AREA_TYPE)[keyof typeof ACTIVE_AREA_TYPE];

export type Shape = {
  top: number;
  left: number;
  width: number;
  height: number;
  angle: number;
};

export type ActiveArea = Shape & {
  id: number;
  name: string;
  type: ActiveAreaType;
  createdAt: string;
  url: string | null;
  medialinkId: number | null;
  medialinkMongoId?: string;
  linkedSlideId: number | null;
  audioMode: AudioMode | null;
  startSeconds: number | null;
  endSeconds: number | null;
};

export type ActiveAreaSingle = Shape & {
  id: number;
  name: string;
  type: ActiveAreaType;
  linkedSlideId: number | null;
  url: string | null;
  medialinkId: number | null;
  medialink?: Medialink;
  audioMode: AudioMode | null;
  startSeconds: number | null;
  endSeconds: number | null;
};

type Medialink = {
  id: string;
  name: string;
  published: boolean;
  reviewState: string;
  contentType: string;
  file: {
    s3file: {
      id: string;
      mimeType: string;
      preview: string;
    };
  };
};

export type AudioMode = (typeof AUDIO_MODE)[keyof typeof AUDIO_MODE];

export const AUDIO_MODE = {
  CLASSIC: 'CLASSIC',
  SINGLE: 'SINGLE',
} as const;

export async function getActiveAreas({ moduleId, nodeId, setId, versionId, linkId, slideId }: BaseParams) {
  const { data } = await api.get<{ data: ActiveArea[] }>(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${linkId}/slides/${slideId}/active-areas`,
  );

  return data.data;
}

export async function getActiveArea({
  moduleId,
  nodeId,
  setId,
  versionId,
  linkId,
  slideId,
  activeAreaId,
}: BaseParams & { activeAreaId: string }) {
  const { data } = await api.get<ActiveAreaSingle>(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${linkId}/slides/${slideId}/active-areas/${activeAreaId}`,
  );

  return data;
}

export async function deleteActiveArea({
  moduleId,
  nodeId,
  setId,
  versionId,
  linkId,
  slideId,
  areaId,
}: BaseParams & { areaId: number }) {
  await api.delete(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${linkId}/slides/${slideId}/active-areas/${areaId}`,
  );
}

export async function getSecuredFile(securedFileId: string) {
  const { data } = await api.get(`/shell/secured-files/${securedFileId}`);
  return data;
}

export async function deleteSourceFileOfSlide({
  moduleId,
  nodeId,
  setId,
  versionId,
  linkId,
  slideId,
  securedFileId,
}: BaseParams & { securedFileId: number }) {
  await api.delete(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${linkId}/slides/${slideId}/source/${securedFileId}`,
  );
}

export type ActiveAreaPutBody = Shape & {
  id: number;
  name: string;
  type: ActiveAreaType;
  url: string | null;
  medialinkId?: string | null;
  medialinkMongoId?: string;
  linkedSlideId: number | null;
  audioMode: AudioMode | null;
  startSeconds: number | null;
  endSeconds: number | null;
  createdAt?: string;
};

export async function putActiveArea(
  { moduleId, nodeId, setId, versionId, linkId, slideId, activeAreaId }: BaseParams & { activeAreaId: string },
  { id, createdAt, medialinkMongoId, medialinkId, ...activeArea }: ActiveAreaPutBody,
) {
  await api.put(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${linkId}/slides/${slideId}/active-areas/${activeAreaId}`,
    { ...activeArea, medialinkId: medialinkMongoId || medialinkId },
  );
}

export async function addActiveArea({ moduleId, nodeId, setId, versionId, linkId, slideId }: BaseParams, area: Shape) {
  await api.post(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${linkId}/slides/${slideId}/active-areas`,
    area,
  );
}
