import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { tss } from 'tss-react/mui';
import { useNavigate, useParams } from 'react-router';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Card, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import SlideCanvas from '../services/slide-canvas';
import { ActiveAreaLayer } from './ActiveAreaLayer';
import SlideEditorContext from '../context/SlideEditorContext';
import { useSlidesetVersionGroups } from '../../slideset-version-detail/context/slideset-version-groups-context';
import { SlideEditorParams } from '../utils';
import SlideNumberInput from './slide-number-input';

const useStyles = tss.create(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  canvasContainer: {
    flexGrow: 1,
    position: 'relative',
  },
  toolbar: {
    backgroundColor: '#ECECEC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 15px',
    fontSize: '13px',
    zIndex: 5,
  },
}));

const VIEW_MODES = {
  SLIDE: 'SLIDE',
  REVEAL: 'REVEAL',
  SLIDE_WITH_REVEAL: 'SLIDE_WITH_REVEAL',
} as const;

type ViewMode = (typeof VIEW_MODES)[keyof typeof VIEW_MODES];
function isViewMode(value: string): asserts value is ViewMode {
  if (!Object.values(VIEW_MODES).includes(value as ViewMode)) {
    throw new Error('Invalid view mode value');
  }
}

type PreviewProps = {
  slideUrl?: string;
  revealUrl?: string;
};

export default function Preview({ slideUrl, revealUrl }: PreviewProps) {
  const { classes } = useStyles();
  const { groups } = useSlidesetVersionGroups();
  const { linkId, slideId, moduleId, nodeId, setId, versionId } = useParams<SlideEditorParams>();
  const fabricRef = React.useRef<SlideCanvas>();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { activeAreas } = useContext(SlideEditorContext);
  const navigate = useNavigate();

  const initialRender = useRef(true);
  const [initialLoad, setInitialLoad] = useState(false);

  const [slideImage, setSlideImage] = React.useState<HTMLImageElement | null>(null);
  const [revealImage, setRevealImage] = React.useState<HTMLImageElement | null>(null);
  const [viewportTransform, setViewportTransform] = useState<number[] | undefined>();

  const [viewMode, setViewMode] = React.useState<ViewMode>(
    revealUrl && !slideUrl ? VIEW_MODES.REVEAL : VIEW_MODES.SLIDE,
  );

  useEffect(() => {
    fabricRef.current = new SlideCanvas('image-canvas', {
      width: containerRef.current!.clientWidth,
      height: containerRef.current!.clientHeight,
    });
  }, []);

  useEffect(() => {
    let persist = true;

    async function loadLayers() {
      async function loadImage(url?: string): Promise<HTMLImageElement | undefined> {
        if (!url) return Promise.resolve(undefined);

        return new Promise((resolve, reject) => {
          const img = new Image();

          img.onload = () => resolve(img);
          img.onerror = () => {
            // Image might still be processing, trying again in 2 seconds if no new url has been provided meanwhile.
            if (persist) setTimeout(() => loadLayers(), 2000);
            reject();
          };
          img.oncancel = () => resolve(undefined);

          img.src = url;
        });
      }

      const promises = [slideUrl, revealUrl].map(loadImage);
      const [slide, reveal] = await Promise.all(promises);

      if (!persist) return;

      if (slide) setSlideImage(slide);
      else setSlideImage(null);

      if (reveal) setRevealImage(reveal);
      else setRevealImage(null);

      if (initialRender.current) {
        setInitialLoad(true);
        initialRender.current = false;
      }
    }

    loadLayers();

    return () => {
      persist = false;
    };
  }, [slideUrl, revealUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeViewMode = useCallback(
    (vm: string) => {
      if (!fabricRef.current) return;

      isViewMode(vm);
      setViewMode(vm);

      if (vm === VIEW_MODES.SLIDE) fabricRef.current.showSlide(slideImage);
      if (vm === VIEW_MODES.REVEAL) fabricRef.current.showSlide(revealImage);
      if (vm === VIEW_MODES.SLIDE_WITH_REVEAL)
        fabricRef.current.showBothSources(slideImage!, revealImage!, activeAreas);

      setViewportTransform(fabricRef.current!.getCanvasViewportTransform());
    },
    [activeAreas, revealImage, slideImage],
  );

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);

      if (!slideImage && !revealImage) changeViewMode(VIEW_MODES.SLIDE);
      if (!slideImage && revealImage) changeViewMode(VIEW_MODES.REVEAL);

      return;
    }

    if (!slideImage && !revealImage) {
      changeViewMode(VIEW_MODES.SLIDE);
    } else if (!revealImage && viewMode === VIEW_MODES.REVEAL) {
      changeViewMode(VIEW_MODES.SLIDE);
    } else if (revealImage && viewMode === VIEW_MODES.REVEAL) {
      changeViewMode(VIEW_MODES.REVEAL);
    } else if (viewMode === VIEW_MODES.SLIDE) {
      changeViewMode(VIEW_MODES.SLIDE);
    } else if (VIEW_MODES.SLIDE_WITH_REVEAL) {
      if (slideImage && revealImage) {
        changeViewMode(VIEW_MODES.SLIDE_WITH_REVEAL);
      } else {
        changeViewMode(VIEW_MODES.SLIDE);
      }
    }
  }, [slideImage, revealImage, viewMode, changeViewMode, initialLoad]);

  useEffect(() => {
    const listener = () => {
      fabricRef.current!.resizeCanvas({
        height: containerRef.current!.clientHeight,
        width: containerRef.current!.clientWidth,
      });

      setViewportTransform(fabricRef.current!.getCanvasViewportTransform());
    };
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const navMap = useMemo(
    () =>
      groups.reduce<{ linkId: number; slideId: number }[]>((acc, group) => {
        acc.push(...group.slides.map(slide => ({ linkId: group.linkId, slideId: slide.id })));

        return acc;
      }, []),
    [groups],
  );

  const currentIndex = navMap.findIndex(
    navItem => navItem.linkId === Number(linkId) && navItem.slideId === Number(slideId),
  );

  function goToPreviousSlide() {
    if (currentIndex <= 0) return;
    const newItem = navMap[currentIndex - 1];

    navigate(
      `/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${newItem.linkId}/slides/${newItem.slideId}`,
    );
  }

  function goToNextSlide() {
    if (currentIndex >= navMap.length - 1) return;
    const newItem = navMap[currentIndex + 1];

    navigate(
      `/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${newItem.linkId}/slides/${newItem.slideId}`,
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.canvasContainer} ref={containerRef}>
        <canvas id="image-canvas" data-testid="image-canvas" />
        {viewportTransform && containerRef.current && slideImage && (
          <ActiveAreaLayer
            viewportTransform={viewportTransform}
            width={containerRef.current!.clientWidth}
            height={containerRef.current!.clientHeight}
            slideWidth={slideImage.width}
            slideHeight={slideImage.height}
          />
        )}
      </div>
      <div>
        <Card className={classes.toolbar}>
          <RadioGroup row name="viewMode" value={viewMode} onChange={e => changeViewMode(e.target.value)}>
            <FormControlLabel
              value={VIEW_MODES.SLIDE}
              control={<Radio />}
              onChange={() => {
                if (viewMode !== VIEW_MODES.SLIDE) changeViewMode(VIEW_MODES.SLIDE);
              }}
              label="Slide"
              labelPlacement="end"
            />
            <FormControlLabel
              disabled={!revealUrl}
              value={VIEW_MODES.REVEAL}
              control={<Radio />}
              onChange={() => {
                if (viewMode !== VIEW_MODES.REVEAL) changeViewMode(VIEW_MODES.REVEAL);
              }}
              label="Reveal"
              labelPlacement="end"
            />
            <FormControlLabel
              disabled={!revealUrl || !slideUrl}
              value={VIEW_MODES.SLIDE_WITH_REVEAL}
              control={<Radio />}
              label="Slide with reveal"
              labelPlacement="end"
            />
            <IconButton
              id="previous"
              size="large"
              title="Previous slide"
              disabled={currentIndex <= 0}
              onClick={() => goToPreviousSlide()}
            >
              <ChevronLeft />
            </IconButton>
            <SlideNumberInput />
            <IconButton
              id="next"
              size="large"
              title="Next slide"
              disabled={currentIndex >= navMap.length - 1}
              onClick={() => goToNextSlide()}
            >
              <ChevronRight />
            </IconButton>
          </RadioGroup>
        </Card>
      </div>
    </div>
  );
}
