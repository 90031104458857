import React, { useMemo } from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import VersionRow from './VersionRow';
import { SlideSetVersion } from '../../../api/slideSetVersion';
import { CopyOverMethodsContext } from '../context/CopyOverMethodsContext';

type Props = {
  versions: SlideSetVersion[];
};

const VersionList = ({ versions }: Props): JSX.Element => {
  const [versionIdToCopyOverMethods, setVersionIdToCopyOverMethods] = React.useState<number | undefined>(undefined);

  const contextValue = useMemo(
    () => ({
      versionIdToCopy: versionIdToCopyOverMethods,
      openCopyDialog: (versionId: number) => setVersionIdToCopyOverMethods(versionId),
      closeCopy: () => setVersionIdToCopyOverMethods(undefined),
    }),
    [versionIdToCopyOverMethods],
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Version</TableCell>
          <TableCell>Last edited</TableCell>
          <TableCell>State</TableCell>
          <TableCell>State History</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody data-testid="version-table-rows">
        <CopyOverMethodsContext.Provider value={contextValue}>
          {versions.map(version => (
            <VersionRow key={version.id} version={version} />
          ))}
        </CopyOverMethodsContext.Provider>
      </TableBody>
    </Table>
  );
};

export default VersionList;
