import { tss } from 'tss-react/mui';
import SchoolYearList from './components/school-year-list';

const useStyles = tss.create(({ theme }) => ({
  root: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const SchoolYears = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <SchoolYearList />
    </div>
  );
};

export default SchoolYears;
