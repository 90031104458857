/* eslint-disable import/prefer-default-export */
export const updateWeights = (answers = []) =>
  answers.map((answer, index) => ({
    ...answer,
    weight: `${index + 1}`,
    members: answer.members?.map((member, memberIndex) => ({
      ...member,
      weight: `${index + 1}.${memberIndex + 1}`,
    })),
  }));
