import React from 'react';
import PropTypes, { string } from 'prop-types';

import * as Yup from 'yup';
import { Formik } from 'formik';

import RenderedLinkArea from './rendered-link-area-detail';

import linkAreaShape from '../../../proptype-shapes/link-area';

const linkAreaValidation = Yup.object().shape({
  name: Yup.string().required(),
  linkType: Yup.string(),
  url: Yup.string().url().when('linkType', {
    is: 'url',
    then: Yup.string().url().required(),
  }),
  digibookLink: Yup.object().when('linkType', {
    is: 'digibook',
    then: Yup.object()
      .shape({
        digibook: Yup.string().required(),
        superModuleId: Yup.string().required(),
        pageNumber: Yup.number().required(),
      })
      .required(),
  }),
  mediaLinks: Yup.array().when('linkType', {
    is: 'medialinks',
    then: Yup.array().of(Yup.string()).min(1, 'Need at least one media link').required(),
  }),
});

export function LinkAreaDetail({ digibookId, linkArea, handleClose, editLinkArea, index }) {
  return (
    <Formik
      initialValues={linkArea}
      onSubmit={values => editLinkArea(digibookId, values, index)}
      validationSchema={linkAreaValidation}
      enableReinitialize
    >
      {props => <RenderedLinkArea handleClose={handleClose} {...props} />}
    </Formik>
  );
}

LinkAreaDetail.propTypes = {
  digibookId: string.isRequired,
  linkArea: linkAreaShape.isRequired,
  handleClose: PropTypes.func.isRequired,
  editLinkArea: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default LinkAreaDetail;
