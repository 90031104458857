import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { SlideGroup, getSlideGroups } from '../../../../api/slideSet';
import { ActiveAreaForm } from '../../utils';

type Props = {
  deletedSlide: boolean;
};

const SlideLink = ({ deletedSlide }: Props) => {
  const { values, handleChange, errors, touched } = useFormikContext<ActiveAreaForm>();
  const [slideGroups, setSlideGroups] = React.useState<SlideGroup[]>([]);
  const { moduleId, nodeId, setId, versionId } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (!moduleId || !nodeId || !setId || !versionId) return;
      const groups = await getSlideGroups(moduleId, nodeId, setId, versionId);
      setSlideGroups(groups);
    }
    fetchData();
  }, [moduleId, nodeId, setId, versionId]);

  const generateMenuItems = () => {
    const menuItems: React.JSX.Element[] = [];

    slideGroups.forEach((group, index) => {
      group.slides.forEach((slide, slideIndex) => {
        menuItems.push(
          <MenuItem value={slide.id} key={slide.id} data-testid={`option-${slide.id}`}>
            {group.type === 'SINGLE' ? index + 1 : `${index + 1}.${slideIndex + 1}`}
          </MenuItem>,
        );
      });
    });
    return menuItems;
  };

  const getError = (): boolean | string => {
    if (values.linkedSlideId) return false;
    if (errors.linkedSlideId && touched.linkedSlideId) return errors.linkedSlideId;
    if (deletedSlide) return 'Slide has been deleted.';
    return false;
  };
  const menuItems = generateMenuItems();
  const error = getError();

  return (
    <Grid container marginLeft="32px" marginTop="16px" width="400px">
      <FormControl fullWidth>
        <InputLabel id="linkedSlideId">Slide</InputLabel>
        <Select
          labelId="linkedSlideId"
          data-testid="linkedSlideId"
          data-items={menuItems.length}
          name="linkedSlideId"
          label="Slide"
          variant="outlined"
          fullWidth
          value={values.linkedSlideId || ''}
          error={Boolean(error)}
          onChange={handleChange}
        >
          {menuItems}
        </Select>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export default SlideLink;
