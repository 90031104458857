import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { array, object, string, arrayOf, shape, bool, func } from 'prop-types';

import { Table, TableBody, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import LinkArea from './digibook-link-area';
import TocNodeSelect from './toc-node-select';
import { toggleIsDrawing } from '../../../actions/codex-editor';
import { getTocNodes } from '../../../selectors/table-of-content';

import { getFileForLayer, getLinkAreasForCurrentPages, getIsDrawing } from '../../../selectors/codex-editor';

const useStyles = makeStyles(() => ({
  subtitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '0.75rem',
  },
  nthSubtitle: {
    marginTop: '1rem',
  },
}));

export function DigibookLinks(props) {
  const { linkAreas, activeBook, tocNodes, pageRanges, toggleDrawing, isDrawing, digibookId } = props;
  const classes = useStyles();
  return (
    <>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Table of Contents
      </Typography>
      <TocNodeSelect tocNodes={tocNodes} pageRanges={pageRanges} />

      <Typography variant="subtitle2" className={classNames(classes.subtitle, classes.nthSubtitle)}>
        Page material
      </Typography>
      <Table>
        <TableBody>
          {linkAreas &&
            linkAreas.map((linkArea, index) => (
              <LinkArea key={`${linkArea.name + index}`} linkArea={linkArea} index={index} digibookId={digibookId} />
            ))}
        </TableBody>
      </Table>
      <Button
        variant="contained"
        color={isDrawing ? 'secondary' : 'primary'}
        disabled={!activeBook}
        style={{ marginTop: 8 }}
        onClick={() => toggleDrawing()}
      >
        {isDrawing ? 'Cancel link drawing' : 'Draw link area'}
      </Button>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  linkAreas: getLinkAreasForCurrentPages(state),
  activeBook: !!getFileForLayer(state, 'bookLayer'),
  tocNodes: getTocNodes(state, ownProps.module),
  isDrawing: getIsDrawing(state),
});

const mapDispatchToProps = {
  toggleDrawing: toggleIsDrawing,
};

DigibookLinks.propTypes = {
  digibookId: string.isRequired,
  linkAreas: arrayOf(
    shape({
      pages: array.isRequired,
      shape: object.isRequired,
      name: string.isRequired,
    }),
  ),
  toggleDrawing: func.isRequired,
  activeBook: bool.isRequired,
  tocNodes: arrayOf(object.isRequired).isRequired,
  pageRanges: arrayOf(object.isRequired).isRequired,
  isDrawing: bool.isRequired,
};

DigibookLinks.defaultProps = {
  linkAreas: [],
};

export const ConnectedDigibookLinks = connect(mapStateToProps, mapDispatchToProps)(DigibookLinks);
export default ConnectedDigibookLinks;
