import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
import thunkMiddleware from 'redux-thunk-recursion-detect';
import { error, warning, maintenance } from './actions/notification';
import reducer from './reducers';
import tryExtractStatusInfo from './utils/error';

export const onStoreError = err => {
  const statusInfo = tryExtractStatusInfo(err);

  if (statusInfo) {
    const title = `HTTP Status ${statusInfo.status} (${statusInfo.statusText})`;
    const message = statusInfo?.message || 'No further details';

    if (statusInfo.status === 503) return maintenance();

    return statusInfo.status < 500 ? warning(message, title) : error(message, title);
  }

  return error(err?.message, 'Oops :(');
};

const errorHandlerMiddleware = createThunkErrorHandlerMiddleware({
  onError: onStoreError,
});
const store = createStore(reducer, {}, composeWithDevTools(applyMiddleware(errorHandlerMiddleware, thunkMiddleware)));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
