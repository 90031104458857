import React from 'react';
import { node, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import DND_TYPES from './digibook-answer-types';

import { getAnswerSetsForCurrentPages, getSelectedAnswerSetId } from '../../../../selectors/codex-editor';
import { editAndSaveAnswerSet } from '../../../../actions/codex-editor';
import { updateWeights } from '../../../../utils/update-dnd-weights';

export function DigibookCatchAllDropZone({ children, digibookId }) {
  const dispatch = useDispatch();
  const answerSets = useSelector(getAnswerSetsForCurrentPages);
  const selectedAnswerSet = useSelector(getSelectedAnswerSetId);
  const currentAnswerSet = answerSets.find(x => x.id === selectedAnswerSet);

  const [, drop] = useDrop({
    accept: [DND_TYPES.ANSWER, DND_TYPES.GROUP],
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      dispatch(
        editAndSaveAnswerSet(digibookId, {
          ...currentAnswerSet,
          answers: updateWeights(currentAnswerSet.answers),
        }),
      );
    },
  });

  return <div ref={drop}>{children}</div>;
}

DigibookCatchAllDropZone.propTypes = {
  children: node.isRequired,
  digibookId: string.isRequired,
};

export default DigibookCatchAllDropZone;
