import React, { useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { getAnswerSetsForCurrentPages, getSelectedAnswerSetId } from '../../../../selectors/codex-editor';
import { editAndSaveAnswerSet, editAnswerSet, removeFromAnswerGroup } from '../../../../actions/codex-editor';
import DND_TYPES from './digibook-answer-types';
import { updateWeights } from '../../../../utils/update-dnd-weights';

function DigibookAnswerDropZone({ children, weight, digibookId }) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const answerSets = useSelector(getAnswerSetsForCurrentPages);
  const selectedAnswerSet = useSelector(getSelectedAnswerSetId);
  const currentAnswerSet = answerSets.find(x => x.id === selectedAnswerSet);

  const onHover = useCallback(
    (oldWeight, moveToWeight, x) => {
      const oldIndex = currentAnswerSet.answers.findIndex(answer => answer.weight === oldWeight);
      const newIndex = currentAnswerSet.answers.findIndex(answer => answer.weight === moveToWeight) + x;
      const nextAnswers = [...currentAnswerSet.answers];
      nextAnswers.splice(newIndex, 0, nextAnswers.splice(oldIndex, 1)[0]);

      dispatch(
        editAnswerSet({
          ...currentAnswerSet,
          answers: nextAnswers,
        }),
      );
    },
    [currentAnswerSet, dispatch],
  );

  const [{ isOverCurrent }, drop] = useDrop({
    accept: [DND_TYPES.ANSWER, DND_TYPES.GROUP],
    collect: monitor => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        return;
      }

      if (isOverCurrent) {
        dispatch(
          editAndSaveAnswerSet(digibookId, {
            ...currentAnswerSet,
            answers: updateWeights(currentAnswerSet.answers),
          }),
        );
      }
    },
    hover: (item, monitor) => {
      if (item.weight === weight || !isOverCurrent) {
        return;
      }

      const dropBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (dropBoundingRect.bottom - dropBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - dropBoundingRect.top;

      const oldIndex = currentAnswerSet.answers.findIndex(answer => answer.weight === item.weight);
      const newIndex = currentAnswerSet.answers.findIndex(answer => answer.weight === weight);

      if (oldIndex === -1) {
        dispatch(removeFromAnswerGroup(item.id, newIndex));
        return;
      }

      // dragging down
      if (oldIndex < newIndex) {
        if (hoverClientY < hoverMiddleY) {
          onHover(item.weight, weight, -1);
          return;
        }
        onHover(item.weight, weight, 0);
        return;
      }

      // dragging upwards
      if (hoverClientY > hoverMiddleY) {
        onHover(item.weight, weight, 1);
        return;
      }

      onHover(item.weight, weight, 0);
    },
  });

  drop(ref);

  return (
    <div ref={ref} className="digibook-answer-drop-zone">
      {children}
    </div>
  );
}

DigibookAnswerDropZone.propTypes = {
  children: PropTypes.node.isRequired,
  weight: PropTypes.string.isRequired,
  digibookId: PropTypes.string.isRequired,
};

export default DigibookAnswerDropZone;
