import { func } from 'prop-types';
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormControl, IconButton, MenuItem, Select, TableCell, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';

const parsePageNumber = page => {
  const pageNumber = parseInt(page, 10);

  return Number.isNaN(pageNumber) ? undefined : pageNumber;
};

export default function MappingCells({ onError, onDelete }) {
  const { dirty, values, setFieldValue: onChange, errors, submitForm, resetForm } = useFormikContext();

  const { excludeFrom, from, to } = values;

  useEffect(() => {
    if (dirty) submitForm();
  }, [dirty, submitForm, resetForm, errors]);

  useEffect(() => {
    if (onError) onError(errors);
  }, [errors, onError]);

  return (
    <>
      <TableCell>
        <FormControl variant="standard" fullWidth>
          <Select
            variant="standard"
            name="excludeFrom"
            value={excludeFrom !== undefined ? excludeFrom : 'manual'}
            onChange={e => onChange(e.target.name, e.target.value)}
          >
            <MenuItem value="manual">Manual</MenuItem>
            <MenuItem value="book">Book</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <TextField
          variant="standard"
          type="number"
          name="from"
          value={from !== undefined ? from : ''}
          inputProps={{ min: 0 }}
          error={!!errors.from}
          onChange={e => onChange(e.target.name, parsePageNumber(e.target.value))}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant="standard"
          type="number"
          name="to"
          value={to !== undefined ? to : ''}
          inputProps={{ min: 0 }}
          error={!!errors.to}
          onChange={e => onChange(e.target.name, parsePageNumber(e.target.value))}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton id="delete" onClick={onDelete} color="secondary" size="large">
          <Delete />
        </IconButton>
      </TableCell>
    </>
  );
}

MappingCells.propTypes = {
  onError: func,
  onDelete: func.isRequired,
};

MappingCells.defaultProps = {
  onError: undefined,
};
