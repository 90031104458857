import React from 'react';
import { object, func, number } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { IconButton } from '@mui/material';
import { ZoomOutMap, ZoomIn, ZoomOut } from '@mui/icons-material';

const styles = theme => ({
  icon: {
    padding: theme.spacing(0.5),
  },
  item: {
    margin: '0 8px',
  },
});

export function Zoom(props) {
  const { classes, setScale, zoomToFit, scale } = props;

  return (
    <div className={classes.item}>
      <IconButton
        id="zoom_in"
        className={classes.icon}
        disabled={scale <= 0.25}
        onClick={() => setScale(scale - 0.25)}
        size="large"
      >
        <ZoomIn />
      </IconButton>
      <IconButton
        id="zoom_out"
        className={classes.icon}
        disabled={scale >= 1.75}
        onClick={() => setScale(scale + 0.25)}
        size="large"
      >
        <ZoomOut />
      </IconButton>
      <IconButton id="zoom_to_fit" className={classes.icon} onClick={() => zoomToFit()} size="large">
        <ZoomOutMap />
      </IconButton>
    </div>
  );
}

Zoom.propTypes = {
  zoomToFit: func.isRequired,
  classes: object,
  scale: number.isRequired,
  setScale: func.isRequired,
};

Zoom.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Zoom);
