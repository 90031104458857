import React from 'react';
import { object, func, number } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';

import { IconButton } from '@mui/material';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import PageNumberInput from './page-number-input';

const styles = theme => ({
  left: {
    marginRight: 4,
  },
  right: {
    marginLeft: 4,
  },
  disabled: {
    fill: 'rgba(0, 0, 0, 0.24);',
  },
  icon: {
    padding: theme.spacing(0.5),
  },
  textField: {
    height: 30,
    width: 60,
    margin: 'auto',
    '& input': {
      textAlign: 'center',
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  item: {
    margin: '0 8px',
  },
});

export function Pagination(props) {
  const { classes, totalPages, viewMode, currentPage, setPage } = props;

  const decrement =
    currentPage === 2 || currentPage === 1 || currentPage === totalPages + 1 ? currentPage - 1 : currentPage - viewMode;

  const increment =
    currentPage === totalPages || currentPage === 0 || currentPage === 1 ? currentPage + 1 : currentPage + viewMode;

  return (
    <div className={classes.item}>
      <IconButton
        id="previous"
        className={classnames(classes.icon, classes.left)}
        disabled={decrement < 0}
        onClick={() => setPage(decrement)}
        size="large"
      >
        <ChevronLeft />
      </IconButton>
      <PageNumberInput currentPage={currentPage} onValueChanged={setPage} totalPages={totalPages} />
      <IconButton
        id="next"
        disabled={increment > totalPages + 1}
        className={classnames(classes.icon, classes.right)}
        onClick={() => setPage(increment)}
        size="large"
      >
        <ChevronRight />
      </IconButton>
    </div>
  );
}

Pagination.propTypes = {
  classes: object,
  totalPages: number,
  viewMode: number,
  currentPage: number,
  setPage: func.isRequired,
};

Pagination.defaultProps = {
  classes: {},
  totalPages: 0,
  viewMode: 2,
  currentPage: 0,
};

export default React.memo(withStyles(styles)(Pagination));
