/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem, TextField, FormHelperText, FormControl, FormControlLabel, Checkbox } from '@mui/material';

import DigibookPropTypes from '../digibook-proptypes';

import Upload from '../../../components/forms/formik/form-upload-field';
import { useAppDispatch } from '../../../hooks/store';
import { uploadFileForDigibook } from '../../../actions/secured-file';
import { setFileForLayer } from '../../../actions/codex-editor';

function DigibookInfo({
  setFieldValue,
  module,
  values,
  errors: { name: nameErr, productionalState: productionalStateErr },
}) {
  const dispatch = useAppDispatch();

  function handleChange(e) {
    setFieldValue(e.target.name, e.target.value);
  }

  function onManualChanged(name, value) {
    setFieldValue(
      'manualMargins',
      value
        ? {
            width: 0.7,
            height: 0.7,
            top: 0.15,
            left: 0.15,
            angle: 0,
          }
        : null,
    );

    setFieldValue(name, value);
  }

  function handleUpload(layer, file, setValue = setFieldValue) {
    dispatch(uploadFileForDigibook(layer, file, module.id, setValue));
  }

  function handleFileUploadClear(layer) {
    dispatch(setFileForLayer(layer, null));
  }

  const {
    name,
    productionalState,
    bookLayer,
    answerLayer,
    cover,
    backCover,
    manual,
    disableAnswerLayerBlend,
    solutionsIncluded,
    enableManual,
    manualType,
  } = values;

  return (
    <>
      <TextField
        name="moduleDisplay"
        label="Module"
        id="module"
        inputProps={{ readOnly: true }}
        margin="normal"
        fullWidth
        variant="outlined"
        value={module && module.name}
      />
      <TextField
        name="name"
        type="text"
        id="name"
        label="Name"
        margin="normal"
        variant="outlined"
        fullWidth
        autoFocus
        required
        value={name || ''}
        error={!!nameErr}
        onChange={handleChange}
      />
      {nameErr && <FormHelperText error>{nameErr}</FormHelperText>}
      <TextField
        data-testid="productional-state"
        name="productionalState"
        label="Productional State"
        id="productionalstate"
        margin="normal"
        variant="outlined"
        fullWidth
        required
        select
        value={productionalState}
        error={!!productionalStateErr}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      >
        <MenuItem value={0}>Input in progress</MenuItem>
        <MenuItem value={1}>Input ready</MenuItem>
        <MenuItem value={2}>Ready for review/publishing</MenuItem>
      </TextField>
      <Upload
        name="bookLayer"
        form="digibook"
        displayName="pdf-book-layer"
        label="Book layer PDF"
        accept="application/pdf"
        upload={file => handleUpload('bookLayer', file)}
        onClear={handleFileUploadClear}
        setFieldValue={setFieldValue}
        value={bookLayer}
      />
      <Upload
        name="answerLayer"
        form="digibook"
        displayName="pdf-answer-layer"
        label="Answer layer PDF"
        accept="application/pdf"
        upload={file => handleUpload('answerLayer', file)}
        onClear={handleFileUploadClear}
        setFieldValue={setFieldValue}
        value={answerLayer}
      />
      <FormControl variant="standard" fullWidth>
        <FormControlLabel
          onChange={e => {
            setFieldValue('disableAnswerLayerBlend', e.target.checked);
          }}
          checked={disableAnswerLayerBlend || false}
          control={<Checkbox color="primary" />}
          label="Don't blend answer layer"
          labelPlacement="end"
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <FormControlLabel
          onChange={e => {
            setFieldValue('solutionsIncluded', e.target.checked);
          }}
          checked={solutionsIncluded || false}
          control={<Checkbox color="primary" />}
          label="Solutions included"
          labelPlacement="end"
        />
      </FormControl>
      <TextField
        data-testid="manual-type"
        name="manualType"
        label="Manual Type"
        id="manualType"
        margin="normal"
        variant="outlined"
        fullWidth
        required
        select
        value={manualType}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      >
        <MenuItem value="manual-layer" selected>
          Manual layer
        </MenuItem>
        <MenuItem value="pop-up-manual">Pop-up manual</MenuItem>
      </TextField>
      <Upload
        name="manual"
        form="digibook"
        displayName="manual-layer"
        label="Manual PDF"
        accept="application/pdf"
        upload={file => handleUpload('manual', file, onManualChanged)}
        onClear={handleFileUploadClear}
        setFieldValue={onManualChanged}
        value={manual}
      />

      {manual && (
        <FormControl variant="standard" fullWidth>
          <FormControlLabel
            onChange={e => {
              setFieldValue('enableManual', e.target.checked);
            }}
            checked={enableManual || false}
            control={<Checkbox color="primary" />}
            label="Enable manual layer"
            labelPlacement="end"
          />
        </FormControl>
      )}
      <Upload
        name="cover"
        form="digibook"
        displayName="book-cover-layer"
        label="Cover PDF"
        accept="application/pdf"
        upload={file => handleUpload('cover', file)}
        onClear={handleFileUploadClear}
        setFieldValue={setFieldValue}
        value={cover}
      />
      <Upload
        name="backCover"
        form="digibook"
        displayName="back-cover-layer"
        label="Back-cover PDF"
        accept="application/pdf"
        upload={file => handleUpload('backCover', file)}
        onClear={handleFileUploadClear}
        setFieldValue={setFieldValue}
        value={backCover}
      />
    </>
  );
}

DigibookInfo.propTypes = {
  module: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    productionalState: PropTypes.string,
    bookLayer: PropTypes.string,
    answerLayer: PropTypes.string,
    pageRanges: PropTypes.string,
  }),
  setFieldValue: PropTypes.func.isRequired,
  values: DigibookPropTypes.isRequired,
};

DigibookInfo.defaultProps = {
  errors: undefined,
};

export default DigibookInfo;
