import { TableOfContentActionType } from '../action-types';

export default function tableOfContentReducer(state = {}, action = {}) {
  switch (action && action.type) {
    case TableOfContentActionType.FETCH_TOC_BY_MODULE_SUCCESS: {
      const { byModule, ...rest } = state;
      const { nodes, moduleId } = action.payload;

      return {
        byModule: {
          ...byModule,
          [moduleId]: {
            nodes,
          },
        },
        ...rest,
      };
    }
    case TableOfContentActionType.FETCH_TOC_BY_MODULE_FAILURE: {
      return state;
    }
    default:
      return state;
  }
}
