const productionalState = {
  0: {
    color: 'red',
    label: 'Input in progress',
  },
  1: {
    color: 'orange',
    label: 'Input ready',
  },
  2: {
    color: 'green',
    label: 'Ready for Review/Publishing',
  },
};

export default productionalState;
