import React from 'react';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import { ListItem, ListItemIcon } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { grey, green } from '@mui/material/colors';
import { object, func, bool, shape, string } from 'prop-types';
import DND_TYPES from './digibook-answer-types';
import DropZone from './digibook-answer-drop-zone';

const styles = {
  row: {
    cursor: 'pointer',
  },
  dragIndicator: {
    minWidth: 'auto',
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  iconWrapper: {
    alignSelf: 'center',
  },
  badge: {
    backgroundColor: green[600],
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    borderRadius: '50%',
  },
  isDragging: {
    background: grey[200],
  },
  indent: {
    marginLeft: 24,
    padding: 2,
  },
};

export function DigibookAnswer({ handleClick, classes, selected, answer, digibookId, groupWeight }) {
  const [{ isDragging }, drag] = useDrag({
    type: DND_TYPES.ANSWER,
    item: answer,
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const content = (
    <ListItem
      ref={drag}
      divider
      selected={selected}
      onClick={handleClick}
      className={classNames(classes.listItem, {
        [classes.isDragging]: isDragging,
      })}
    >
      <ListItemIcon className={classes.iconWrapper}>
        <div
          className={classNames(classes.badge, {
            [classes.indent]: !!groupWeight,
          })}
        >
          <span>{answer.weight}</span>
        </div>
      </ListItemIcon>
      <ListItemIcon classes={{ root: classes.dragIndicator }}>
        <DragIndicator />
      </ListItemIcon>
    </ListItem>
  );

  if (groupWeight) {
    return <>{content}</>;
  }

  return (
    <DropZone weight={answer.weight} digibookId={digibookId}>
      {content}
    </DropZone>
  );
}

DigibookAnswer.propTypes = {
  handleClick: func.isRequired,
  classes: object,
  selected: bool,
  digibookId: string.isRequired,
  groupWeight: string,
  answer: shape({
    weight: string.isRequired,
  }).isRequired,
};

DigibookAnswer.defaultProps = {
  classes: {},
  selected: false,
  groupWeight: undefined,
};

export default withStyles(styles)(DigibookAnswer);
