import { PublishingHouseActionType } from '../action-types';

export default function publishingHouseReducer(state = {}, action = {}) {
  switch (action && action.type) {
    case PublishingHouseActionType.FETCH_PUBLISHINGHOUSES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case PublishingHouseActionType.FETCH_PUBLISHINGHOUSES_SUCCESS: {
      const { isFetching, ...rest } = state;

      const { entities, ids } = action.payload;

      return {
        ...rest,
        entities,
        ids,
        lastUpdated: Date.now(),
      };
    }
    case PublishingHouseActionType.FETCH_PUBLISHINGHOUSES_FAILURE: {
      const { isFetching, ...rest } = state;
      return {
        ...rest,
      };
    }
    default:
      return state;
  }
}
