import React from 'react';
import qs from 'query-string';

import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router';
import { isAuthenticated, getAllowedRoles } from '../selectors/authentication';

import Forbidden from './splash-cards/forbidden';
import roles from '../constants/roles';
import Splash from './layouts/splash';

type Role = (typeof roles)[keyof typeof roles];
export function Protected({ requiresAnyOfRoles }: { requiresAnyOfRoles?: Role[] }) {
  const location = useLocation();
  const authenticated = useSelector(isAuthenticated);
  const allowedRoles = useSelector(getAllowedRoles);

  if (!authenticated) {
    const search = `?${qs.stringify({ returnUrl: location.pathname })}`;
    return <Navigate to={{ pathname: '/login', search }} />;
  }

  const hasAnyRole = allowedRoles.length > 0;
  const hasAnyOfRequiredRoles = !requiresAnyOfRoles || requiresAnyOfRoles.some(role => allowedRoles.includes(role));

  if (!hasAnyRole || !hasAnyOfRequiredRoles) {
    return (
      <Splash>
        <Forbidden allowHome={hasAnyRole} />
      </Splash>
    );
  }

  return <Outlet />;
}
