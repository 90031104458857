import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';

import { object, arrayOf, string } from 'prop-types';

import { Typography, Button, List, Grid } from '@mui/material';

import TocNodeSelect from '../toc-node-select';
import { getTocNodes } from '../../../../selectors/table-of-content';
import {
  addAnswerSet,
  setSelectedAnswerSet,
  setSelectedAnswer,
  toggleIsDrawing,
  disableIsDrawing,
  clearSelectedAnswerSet,
  clearSelectedAnswer,
  removeAnswerFromSet,
  removeAnswerSet,
  addAnswerGroupToSet,
} from '../../../../actions/codex-editor';
import {
  getAnswerSetsForCurrentPages,
  getCurrentPagesForView,
  getSelectedAnswerSetId,
  getIsDrawing,
  getSelectedAnswer,
} from '../../../../selectors/codex-editor';
import AnswerSet from './digibook-answer-set';
import Answer from './digibook-answer';
import AnswerGroup from './digibook-answer-group';

const styles = {
  list: {
    minHeight: '100%',
  },
  subtitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '0.75rem',
  },
  nthSubtitle: {
    marginTop: '1rem',
  },
};

export function DigibookAnswerSets({ module, classes, pageRanges, digibookId }) {
  const dispatch = useDispatch();
  const tocNodes = useSelector(state => getTocNodes(state, module));
  const answerSets = useSelector(getAnswerSetsForCurrentPages);
  const currentPages = useSelector(getCurrentPagesForView);
  const selectedAnswerSet = useSelector(getSelectedAnswerSetId);
  const selectedAnswer = useSelector(getSelectedAnswer);
  const isDrawing = useSelector(getIsDrawing);
  const currentAnswerSet = useMemo(
    () => answerSets.find(x => x.id === selectedAnswerSet),
    [answerSets, selectedAnswerSet],
  );

  useEffect(
    () => () => {
      dispatch(clearSelectedAnswerSet());
      dispatch(disableIsDrawing());
    },
    [dispatch],
  );

  const handleSetClick = set => {
    dispatch(disableIsDrawing());
    if (selectedAnswerSet === set.id) {
      dispatch(clearSelectedAnswerSet());
    } else {
      dispatch(setSelectedAnswerSet(set.id));
    }
  };

  const handleAnswerClick = answerId => {
    if (selectedAnswer === answerId) {
      dispatch(clearSelectedAnswer());
    } else {
      dispatch(setSelectedAnswer(answerId));
    }
  };

  return (
    <Grid container>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Table of Contents
      </Typography>
      <TocNodeSelect tocNodes={tocNodes} pageRanges={pageRanges} />

      <Grid item md={6} xs={12}>
        <Typography variant="subtitle2" className={classNames(classes.subtitle, classes.nthSubtitle)}>
          Answersets
        </Typography>

        <div className={classes.btnContainer}>
          <Button
            data-testid="add-answer-set"
            color="primary"
            onClick={() => dispatch(addAnswerSet(digibookId, currentPages))}
            disabled={isDrawing || currentPages?.length === 0}
          >
            ADD
          </Button>
          <Button
            data-testid="delete-answer-set"
            color="secondary"
            disabled={isDrawing || !selectedAnswerSet}
            onClick={() => dispatch(removeAnswerSet(digibookId, selectedAnswerSet))}
          >
            DELETE
          </Button>
        </div>

        <List>
          {answerSets.map((set, i) => (
            <AnswerSet
              key={set.id}
              index={i}
              selected={selectedAnswerSet === set.id}
              handleClick={() => handleSetClick(set)}
            />
          ))}
        </List>
      </Grid>
      {selectedAnswerSet && (
        <Grid item md={6} xs={12}>
          <Typography variant="subtitle2" className={classNames(classes.subtitle, classes.nthSubtitle)}>
            Answers for current set
          </Typography>
          <Button
            data-testid="toggle-drawing"
            color={isDrawing ? 'secondary' : 'primary'}
            onClick={() => dispatch(toggleIsDrawing())}
          >
            {isDrawing ? 'STOP DRAWING' : 'START DRAWING'}
          </Button>
          <Button
            data-testid="add-answer-group"
            color="primary"
            onClick={() => dispatch(addAnswerGroupToSet(digibookId, selectedAnswerSet))}
          >
            ADD GROUP
          </Button>
          <Button
            data-testid="delete-answer"
            color="secondary"
            disabled={isDrawing || selectedAnswer === undefined}
            onClick={() => dispatch(removeAnswerFromSet(digibookId, selectedAnswer, selectedAnswerSet))}
          >
            DELETE
          </Button>
          <List className={classes.list}>
            {currentAnswerSet &&
              currentAnswerSet.answers.map(answer =>
                answer.members ? (
                  <AnswerGroup
                    digibookId={digibookId}
                    key={answer.id}
                    group={answer}
                    handleClick={() => handleAnswerClick(answer.id)}
                    selected={selectedAnswer === answer.id}
                  />
                ) : (
                  <Answer
                    digibookId={digibookId}
                    key={answer.id}
                    answer={answer}
                    handleClick={() => handleAnswerClick(answer.id)}
                    selected={selectedAnswer === answer.id}
                  />
                ),
              )}
          </List>
        </Grid>
      )}
    </Grid>
  );
}

DigibookAnswerSets.propTypes = {
  module: string.isRequired,
  digibookId: string.isRequired,
  pageRanges: arrayOf(object.isRequired).isRequired,
  classes: object,
};

DigibookAnswerSets.defaultProps = {
  classes: {},
};

export default withStyles(styles)(DigibookAnswerSets);
