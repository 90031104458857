import React from 'react';
import { number, object, bool, func } from 'prop-types';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';

import { setViewport as setVP } from '../../../../actions/codex-editor';
import resizeCanvasForScale from '../../../../utils/retina-helper';

export class Page extends React.Component {
  constructor(props) {
    super(props);

    this.renderTask = null;
    this.canvasRef = React.createRef();
    this.renderPage = this.renderPage.bind(this);
    this.debouncedRender = debounce(this.renderPage, 150);
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedRender);
    this.renderPage();
  }

  shouldComponentUpdate(nextProps) {
    const { pdf, page, scale } = this.props;
    const { pdf: nextPdf, page: nextPage, scale: nextScale } = nextProps;

    const pdfChanged = pdf.fingerprint !== nextPdf.fingerprint;
    const pageChanged = page !== nextPage;
    const scaleChanged = scale !== nextScale;

    const shouldUpdate = pdfChanged || pageChanged || scaleChanged;
    if (shouldUpdate) this.pdfPage = null;

    return shouldUpdate;
  }

  componentDidUpdate() {
    this.debouncedRender();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedRender);
    this.debouncedRender.cancel();
    this.abortCurrentRender();
  }

  setupCanvas(pdfPage) {
    this.abortCurrentRender();

    const canvas = this.canvasRef.current;

    if (canvas) {
      // get scaled viewport
      const { scale, setViewport, setViewportOnRender } = this.props;
      const unscaledViewport = pdfPage.getViewport({ scale });
      const pdfContainer = document.getElementById('children-wrapper');
      const { offsetWidth: containerWidth, offsetHeight: containerHeight } = pdfContainer;
      const dpr = window.devicePixelRatio || 1;
      const newScale =
        dpr * Math.min(containerHeight / unscaledViewport.height, containerWidth / 2 / unscaledViewport.width);

      const viewport = pdfPage.getViewport({ scale: newScale });
      if (setViewportOnRender) setViewport(Math.trunc(viewport.width / dpr), Math.trunc(viewport.height / dpr));

      const canvasContext = canvas.getContext('2d');
      resizeCanvasForScale(canvas, viewport, dpr);

      const renderContext = {
        canvasContext,
        viewport,
      };

      const { transparent } = this.props;
      if (transparent) renderContext.background = 'rgba(0,0,0,0)';

      this.renderTask = pdfPage.render(renderContext);

      this.renderTask.promise
        .catch(e => {
          if (e.name !== 'RenderingCancelledException') throw e;
        })
        .then(() => {
          this.renderTask = null;
        });
    }
  }

  abortCurrentRender() {
    if (this.renderTask) {
      this.renderTask.cancel();
      this.renderTask = null;
    }
  }

  renderPage() {
    const { pdf, page } = this.props;

    if (this.pdfPage) this.setupCanvas(this.pdfPage);
    else {
      pdf.getPage(page).then(pdfPage => {
        this.pdfPage = pdfPage;
        this.setupCanvas(pdfPage);
      });
    }
  }

  render() {
    const { page } = this.props;
    return <canvas ref={this.canvasRef} key={page} />;
  }
}

Page.propTypes = {
  pdf: object.isRequired,
  page: number.isRequired,
  scale: number.isRequired,
  transparent: bool,
  setViewport: func.isRequired,
  setViewportOnRender: bool,
};

Page.defaultProps = {
  transparent: false,
  setViewportOnRender: true,
};

const mapDispatchToProps = {
  setViewport: setVP,
};

export default connect(undefined, mapDispatchToProps)(Page);
