import React from 'react';
import { node, bool } from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Card, CardContent, CardHeader, Avatar, Typography, Fab, Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Build as BuildIcon, Refresh as RefreshIcon } from '@mui/icons-material';

import MaintenanceModeImage from '../../assets/images/maintenance.svg';
import { getMaintenanceMode } from '../selectors/notification';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '100vh',
    backgroundColor: useTheme().palette.background.default,
  },
  card: {
    minWidth: 500,
    margin: 'auto',
  },
  avatar: {
    backgroundColor: useTheme().palette.error.light,
  },
  header: {
    backgroundColor: useTheme().palette.error.dark,
  },
  title: {
    color: useTheme().palette.common.white,
  },
  button: {
    position: 'absolute',
    bottom: 15,
    right: 15,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  textBubble: {
    marginLeft: 32,
    width: 300,
    height: 80,
    background: '#E6E6E6',
    position: 'relative',
    borderRadius: 8,
    '&:before': {
      content: '""',
      position: 'absolute',
      right: '100%',
      top: 45,
      width: 0,
      height: 0,
      borderTop: '13px solid transparent',
      borderRight: '26px solid #E6E6E6',
      borderBottom: '13px solid transparent',
    },
  },
  text: {
    overflow: 'hidden',
    letterSpacing: 2,
    width: 0,
    animation: '$typing-line-1 2s steps(30) forwards',
    animationDelay: '1s',
    whiteSpace: 'nowrap',
    fontFamily: 'monospace',
    margin: '0 8px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      animation: '$hide-blink 1s forwards',
      animationDelay: '3s',
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      width: 3,
      height: 16,
    },
  },
  textlineTwo: {
    animation: '$typing-line-2 2s steps(30) forwards',
    animationDelay: '3s',
    '&:before': {
      animation: '$hide-blink 1s forwards',
      animationDelay: '5s',
    },
  },
  textlineThree: {
    animation: '$typing-line-3 2s steps(30) forwards',
    animationDelay: '5s',
    '&:before': {
      animation: '$blink .75s steps(30) infinite',
      animationDelay: '7s',
    },
  },
  '@keyframes typing-line-1': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: 250,
    },
  },
  '@keyframes typing-line-2': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: 250,
    },
  },
  '@keyframes typing-line-3': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: 260,
    },
  },
  '@keyframes blink': {
    '0%': {
      backgroundColor: 'transparent',
    },
    '100%': {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
    },
  },
  '@keyframes hide-blink': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: 0,
    },
  },
}));

export function MaintenanceMode(props) {
  const { children, maintenance } = props;
  const classes = useStyles();
  return maintenance ? (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          avatar={
            <Avatar className={classes.avatar}>
              <BuildIcon />
            </Avatar>
          }
          title={
            <Typography variant="h6" className={classes.title}>
              Out of order
            </Typography>
          }
          subheader={
            <Typography variant="subtitle1" className={classes.title}>
              Try again in a few minutes
            </Typography>
          }
        />
        <CardContent className={classes.content}>
          <MaintenanceModeImage />
          <Fade in timeout={{ enter: 1000 }}>
            <div className={classes.textBubble}>
              <Typography variant="h6" className={classes.text}>
                We apologize for the
              </Typography>
              <Typography variant="h6" className={classNames(classes.text, classes.textlineTwo)}>
                disruption and thank
              </Typography>
              <Typography variant="h6" className={classNames(classes.text, classes.textlineThree)}>
                you for your patience.
              </Typography>
            </div>
          </Fade>
        </CardContent>
      </Card>
      <Fab color="primary" className={classes.button} onClick={() => window.location.reload()}>
        <RefreshIcon />
      </Fab>
    </div>
  ) : (
    children
  );
}

MaintenanceMode.propTypes = {
  children: node.isRequired,
  maintenance: bool,
};

MaintenanceMode.defaultProps = {
  maintenance: false,
};

const mapStateToProps = state => ({
  maintenance: getMaintenanceMode(state),
});

export const ConnectedMaintenanceMode = connect(mapStateToProps)(MaintenanceMode);

export default connect(mapStateToProps)(MaintenanceMode);
