import { fabric } from 'fabric';
import FabricService from './fabric-service';

export default class ManualMarginsService extends FabricService {
  constructor(id) {
    super(id);
    this.RECTANGLE_FILL = 'rgba(240,20,20,0.15)';
    this.MARKER_FILL = 'rgb(240,20,20)';
    this.fabricCanvas.centeredScaling = true;
  }

  renderManualMargins(rect, isSinglePage, setObjectMoving, onRectangleChanged) {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects('rect'));

    const scaleX = isSinglePage ? this.fabricCanvas.width * 2 : this.fabricCanvas.width;

    const scaleY = this.fabricCanvas.height;

    const absoluteShape = FabricService.getAbsoluteCoordinatesForRectangle(rect, scaleX, scaleY);

    const rectangle = new fabric.Rect({
      ...absoluteShape,
      strokeWidth: 1,
      fill: this.RECTANGLE_FILL,
      stroke: this.MARKER_FILL,
      lockUniScaling: true,
      lockMovementX: true,
      strokeDashArray: [5, 5],
    });

    rectangle.on('mousedown', () => {
      setObjectMoving(true);
    });

    rectangle.on('mouseup', () => {
      setObjectMoving(false);
    });

    /* http://fabricjs.com/v5-breaking-changes Transform events removed */
    rectangle.on('modified', () => {
      const { width, height, top, left, angle } = FabricService.getRelativeCoordinatesForRectangle(
        rectangle,
        scaleX,
        scaleY,
      );
      onRectangleChanged({
        width,
        height,
        top,
        left,
        angle,
      });
    });

    rectangle.setControlsVisibility({
      mt: false, // middle top disable
      mb: false, // midle bottom
      ml: false, // middle left
      mr: false, // I think you get it
      mtr: false,
    });

    this.fabricCanvas.add(rectangle);
    this.fabricCanvas.bringToFront(rectangle);
  }
}
