import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FormControl, Grid, InputLabel, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import SubjectSelect from './subject-select';
import MethodSelect from './method-select';
import ModuleSelect from './module-select';

import {
  getCurrentPublishingHouseId,
  getCurrentHierarchySubjectId,
  getCurrentHierarchyMethodId,
  getCurrentHierarchyModuleId,
} from '../../selectors/context';

import {
  setCurrentHierarchySubject,
  setCurrentHierarchyMethod,
  setCurrentHierarchyModule,
  clearCurrentHierarchySubject,
  clearCurrentHierarchyMethod,
  clearCurrentHierarchyModule,
} from '../../actions/context';

const styles = {
  select: {
    width: '100%',
  },
};

export class Hierarchy extends React.Component {
  onSubjectChange(subjectId) {
    const { setSubject, clearSubject } = this.props;

    return subjectId === '' ? clearSubject() : setSubject(subjectId);
  }

  onMethodChange(methodId) {
    const { setMethod, clearMethod } = this.props;

    return methodId === '' ? clearMethod() : setMethod(methodId);
  }

  onModuleChange(moduleId) {
    const { setModule, clearModule } = this.props;

    return moduleId === '' ? clearModule() : setModule(moduleId);
  }

  render() {
    const { classes, publishingHouseId, title, subjectId, methodId, moduleId } = this.props;

    return (
      <Grid container spacing={2}>
        {title && (
          <Grid item xs={12}>
            <Typography variant="h6" id="hierarchy-title">
              {title}
            </Typography>
          </Grid>
        )}
        {publishingHouseId ? (
          [
            <Grid item xs={12} md={4} key="subject">
              <FormControl variant="standard" className={classes.select} margin="dense" id="hierarchy-subject">
                <InputLabel htmlFor="hierarchy-subject-id">Subject</InputLabel>
                <SubjectSelect
                  autoFocus
                  publishingHouseId={publishingHouseId}
                  inputProps={{
                    id: 'hierarchy-subject-id',
                  }}
                  onChange={selectedSubjectId => this.onSubjectChange(selectedSubjectId)}
                  value={subjectId}
                />
              </FormControl>
            </Grid>,
            <Grid item xs={12} md={4} key="method">
              {subjectId && (
                <FormControl variant="standard" className={classes.select} margin="dense" id="hierarchy-method">
                  <InputLabel htmlFor="hierarchy-method-id">Method</InputLabel>
                  <MethodSelect
                    inputProps={{
                      id: 'hierarchy-method-id',
                    }}
                    onChange={selectedMethodId => this.onMethodChange(selectedMethodId)}
                    subjectId={subjectId}
                    value={methodId}
                  />
                </FormControl>
              )}
            </Grid>,
            <Grid item xs={12} md={4} key="module">
              {methodId && (
                <FormControl variant="standard" className={classes.select} margin="dense" id="hierarchy-module">
                  <InputLabel htmlFor="hierarchy-module-id">Module</InputLabel>
                  <ModuleSelect
                    inputProps={{
                      id: 'hierarchy-module-id',
                    }}
                    methodId={methodId}
                    onChange={selectedModuleId => this.onModuleChange(selectedModuleId)}
                    value={moduleId}
                  />
                </FormControl>
              )}
            </Grid>,
          ]
        ) : (
          <Grid item xs={12}>
            <Typography id="hierarchy-message-no-publishing-house" variant="body1" color="error">
              No PublishingHouse selected
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}

Hierarchy.propTypes = {
  // WithStyles
  classes: PropTypes.object,

  // Real props
  title: PropTypes.string,

  // Connected
  clearMethod: PropTypes.func.isRequired,
  clearModule: PropTypes.func.isRequired,
  clearSubject: PropTypes.func.isRequired,
  methodId: PropTypes.string,
  moduleId: PropTypes.string,
  publishingHouseId: PropTypes.string,
  setMethod: PropTypes.func.isRequired,
  setModule: PropTypes.func.isRequired,
  setSubject: PropTypes.func.isRequired,
  subjectId: PropTypes.string,
};

Hierarchy.defaultProps = {
  classes: {},
  methodId: undefined,
  moduleId: undefined,
  publishingHouseId: undefined,
  subjectId: undefined,
  title: undefined,
};

const mapStateToProps = state => ({
  publishingHouseId: getCurrentPublishingHouseId(state),
  subjectId: getCurrentHierarchySubjectId(state),
  methodId: getCurrentHierarchyMethodId(state),
  moduleId: getCurrentHierarchyModuleId(state),
});

const mapDispatchToProps = {
  clearMethod: clearCurrentHierarchyMethod,
  clearModule: clearCurrentHierarchyModule,
  clearSubject: clearCurrentHierarchySubject,
  setMethod: setCurrentHierarchyMethod,
  setModule: setCurrentHierarchyModule,
  setSubject: setCurrentHierarchySubject,
};

export const ConnectedHierarchy = connect(mapStateToProps, mapDispatchToProps)(Hierarchy);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Hierarchy));
