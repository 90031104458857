import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Snackbar } from '@mui/material';

import Notification from './notification';
import NotificationType from '../../constants/notification-type';
import { getNextNotification } from '../../selectors/notification';
import { dismiss } from '../../actions/notification';

export class Notifier extends React.Component {
  onSnackBarClose(reason) {
    if (reason === 'clickaway') {
      return;
    }

    const { current, dismissNotification } = this.props;

    dismissNotification(current.id);
  }

  render() {
    const { current, className } = this.props;

    const { title, message, variant } = current || {};

    return (
      <Snackbar open={Boolean(current)} onClose={(e, reason) => this.onSnackBarClose(reason)} autoHideDuration={5000}>
        {current && (
          <Notification
            className={className}
            title={title}
            message={message}
            variant={variant}
            onClose={() => this.onSnackBarClose()}
          />
        )}
      </Snackbar>
    );
  }
}

Notifier.propTypes = {
  className: PropTypes.string,
  dismissNotification: PropTypes.func.isRequired,
  current: PropTypes.shape({
    id: PropTypes.any.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(Object.values(NotificationType)),
  }),
};

Notifier.defaultProps = {
  className: undefined,
  current: undefined,
};

const mapStateToProps = state => ({
  current: getNextNotification(state),
});

const mapDispatchToProps = {
  dismissNotification: dismiss,
};

export const ConnectedNotifier = connect(mapStateToProps, mapDispatchToProps)(Notifier);

export default ConnectedNotifier;
