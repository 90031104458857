import { SubjectActionType } from '../action-types';

export default function subjectReducer(state = {}, action = {}) {
  switch (action && action.type) {
    case SubjectActionType.FETCH_SUBJECTS_BY_PUBLISHING_HOUSE: {
      const { publishingHouseId } = action.payload;
      const { entities, byPublishingHouse = {} } = state;

      return {
        entities,
        byPublishingHouse: {
          ...byPublishingHouse,
          [publishingHouseId]: {
            ...byPublishingHouse[publishingHouseId],
            isFetching: true,
          },
        },
      };
    }
    case SubjectActionType.FETCH_SUBJECTS_BY_PUBLISHING_HOUSE_SUCCESS: {
      const { publishingHouseId, entities: subjects, ids } = action.payload;
      const { entities, byPublishingHouse } = state;

      return {
        entities: {
          ...entities,
          ...subjects,
        },
        byPublishingHouse: {
          ...byPublishingHouse,
          [publishingHouseId]: {
            ids,
            lastUpdated: Date.now(),
          },
        },
      };
    }
    case SubjectActionType.FETCH_SUBJECTS_BY_PUBLISHING_HOUSE_FAILURE: {
      const { publishingHouseId } = action.payload;
      const { entities, byPublishingHouse = {} } = state;

      const { isFetching, ...rest } = byPublishingHouse[publishingHouseId] || {};

      return {
        entities,
        byPublishingHouse: {
          ...byPublishingHouse,
          [publishingHouseId]: {
            ...rest,
          },
        },
      };
    }
    default:
      return state;
  }
}
