import React from 'react';
import { object, func, string, arrayOf } from 'prop-types';

import { TextField, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = {
  tocRange: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export function TocSelector({ currentNode, tocNodes, handleChange, classes }) {
  return (
    <TextField
      name="selectedToc"
      label="Table Of Contents"
      id="selectedToc"
      margin="normal"
      variant="outlined"
      fullWidth
      select
      value={currentNode}
      onChange={handleChange}
    >
      <MenuItem value="" style={{ paddingLeft: 10 }}>
        -- Select level --
      </MenuItem>
      {tocNodes.map(node => (
        <MenuItem key={node.id} value={node.id} style={{ paddingLeft: 10 * (node.level + 1) }}>
          <div className={classes.tocRange}>
            <span>{node.name}</span>
            <span>{node.from !== undefined && node.to !== undefined && `${node.from} - ${node.to}`}</span>
          </div>
        </MenuItem>
      ))}
    </TextField>
  );
}

TocSelector.propTypes = {
  currentNode: string,
  tocNodes: arrayOf(object),
  handleChange: func.isRequired,
  classes: object.isRequired,
};

TocSelector.defaultProps = {
  currentNode: '',
  tocNodes: [],
};

export default withStyles(styles)(TocSelector);
