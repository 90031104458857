import { batch, useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { getCurrentHierarchyModuleId, getCurrentTocNodeId } from '../selectors/context';
import api from '../services/api';
import {
  setCurrentHierarchyMethod,
  setCurrentHierarchyModule,
  setCurrentHierarchySubject,
  setCurrentPublishingHouse,
  setCurrentTocNode,
} from '../actions/context';
import { fetchPublishingHouses } from '../actions/publishing-house';
import { fetchSubjectsByPublishingHouse } from '../actions/subject';
import { fetchMethodsBySubject } from '../actions/method';
import { fetchModulesByMethod } from '../actions/module';

export default function HierarchyBuilder() {
  const { moduleId, nodeId } = useParams();
  const currentModuleId = useSelector(getCurrentHierarchyModuleId);
  const currentNodeId = useSelector(getCurrentTocNodeId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function getModuleData() {
      if (moduleId !== currentModuleId || nodeId !== currentNodeId) {
        try {
          const { data } = await api.get(`/shell/modules/${moduleId}/path`);

          await Promise.all([
            fetchPublishingHouses(),
            fetchSubjectsByPublishingHouse(data.publishingHouse.id),
            fetchMethodsBySubject(data.subject.id),
            fetchModulesByMethod(data.method.id),
          ]);

          batch(() => {
            dispatch(setCurrentPublishingHouse(data.publishingHouse.id));
            dispatch(setCurrentHierarchySubject(data.subject.id));
            dispatch(setCurrentHierarchyMethod(data.method.id));
            dispatch(setCurrentHierarchyModule(data.module.id));
            dispatch(setCurrentTocNode(nodeId));
          });
        } catch (e) {
          navigate('/');
        }
      }
    }

    getModuleData();
  }, [moduleId, currentModuleId, nodeId, dispatch, navigate, currentNodeId]);

  return currentModuleId ? <Outlet /> : null;
}
