import React from 'react';
import { string, shape } from 'prop-types';
import { connect } from 'react-redux';

import { getContentTypeById } from '../../../../../selectors/medialinks';

export function MediaLinkContentType({ contentType }) {
  return <>{contentType && contentType.name}</>;
}

MediaLinkContentType.propTypes = {
  contentTypeId: string.isRequired, // eslint-disable-line react/no-unused-prop-types
  contentType: shape({
    name: string,
  }),
};

MediaLinkContentType.defaultProps = {
  contentType: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  contentType: getContentTypeById(state, ownProps.contentTypeId),
});

export default connect(mapStateToProps)(MediaLinkContentType);
