import { format } from 'date-fns';
import { SlideSetVersionState } from '../api/slideSet';
import { SlideSetVersionStateHistory } from '../api/slideSetVersion';

export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

export const formatState = (arr: any[]) => {
  const flattenAndUnique = [...new Set(arr.flat(1))];
  const capitalized = flattenAndUnique.map((state: string) => capitalizeFirstLetter(state));
  return capitalized.join(', ');
};

export const getLatestStateHistory = (
  versionStateHistory: SlideSetVersionStateHistory[],
  versionState?: SlideSetVersionState,
) => {
  const sortedStateHistory = versionStateHistory
    .filter(history => {
      if (versionState) return history.state === versionState;
      return true;
    })
    .sort((a, b) => new Date(b.from).getTime() - new Date(a.from).getTime());

  return sortedStateHistory.shift();
};

export const getStateHistory = (stateHistory: SlideSetVersionStateHistory[]) => {
  const latest = getLatestStateHistory(stateHistory);
  if (!latest) return '';

  const latestDate = format(new Date(latest.from), 'dd-MM-yyyy');

  switch (latest.state) {
    case SlideSetVersionState.PUBLISHED:
      return `Published since ${latestDate}`;
    case SlideSetVersionState.REVIEW:
      return `In review since ${latestDate}`;
    case SlideSetVersionState.ARCHIVED: {
      const latestPublished = getLatestStateHistory(stateHistory, SlideSetVersionState.PUBLISHED)!;
      return `Published from ${format(new Date(latestPublished.from), 'dd-MM-yyyy')} to ${latestDate}`;
    }
    case SlideSetVersionState.DRAFT: {
      const latestReviewed = getLatestStateHistory(stateHistory, SlideSetVersionState.REVIEW);
      if (!latestReviewed) return '';
      return `Reviewed from ${format(new Date(latestReviewed.from), 'dd-MM-yyyy')} to ${latestDate}`;
    }
    default:
      return null;
  }
};

export const getTranslatedGeneral = (studioGroup: string) => {
  if (studioGroup === 'ES') return 'Général';

  return 'Algemeen';
};
