import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PublishingHouseSelect from './publishing-house-select';

import { setCurrentPublishingHouse, clearCurrentPublishingHouse } from '../../actions/context';

import { getCurrentPublishingHouseId } from '../../selectors/context';
import { getPublishingHousesByGroup } from '../../selectors/publishing-house';

export function PublishingHouseSelectWithContext({
  className,
  publishingHouseId,
  publishingHousesByGroup,
  setPublishingHouseId,
  clearPublishingHouseId,
}) {
  return (
    <PublishingHouseSelect
      className={className}
      publishingHouseId={publishingHouseId}
      publishingHousesByGroup={publishingHousesByGroup}
      setPublishingHouseId={setPublishingHouseId}
      clearPublishingHouseId={clearPublishingHouseId}
    />
  );
}

PublishingHouseSelectWithContext.propTypes = {
  className: PropTypes.string,
  clearPublishingHouseId: PropTypes.func.isRequired,
  publishingHouseId: PropTypes.string,
  setPublishingHouseId: PropTypes.func.isRequired,
  publishingHousesByGroup: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      publishingHouses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
};

PublishingHouseSelectWithContext.defaultProps = {
  className: undefined,
  publishingHouseId: undefined,
  publishingHousesByGroup: [],
};

const mapStateToProps = state => ({
  publishingHousesByGroup: getPublishingHousesByGroup(state),
  publishingHouseId: getCurrentPublishingHouseId(state),
});

const mapDispatchToProps = {
  setPublishingHouseId: setCurrentPublishingHouse,
  clearPublishingHouseId: clearCurrentPublishingHouse,
};

export const ConnectedPublishingHouseSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublishingHouseSelectWithContext);

export default ConnectedPublishingHouseSelect;
