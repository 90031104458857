import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { List, ListItem, ListItemIcon } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { grey, green } from '@mui/material/colors';
import { object, func, bool, shape, string } from 'prop-types';
import DND_TYPES from './digibook-answer-types';
import DropZone from './digibook-answer-drop-zone';
import Answer from './digibook-answer';
import { addToAnswerGroup, clearSelectedAnswer, setSelectedAnswer } from '../../../../actions/codex-editor';
import { getSelectedAnswer } from '../../../../selectors/codex-editor';

const styles = {
  row: {
    cursor: 'pointer',
  },
  dragIndicator: {
    minWidth: 'auto',
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    justifyContent: 'space-between',
    backgroundColor: 'white',
    paddingTop: 5,
    paddingBottom: 5,
  },
  iconWrapper: {
    alignSelf: 'center',
    minWidth: 28,
  },
  badge: {
    backgroundColor: green[600],
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    borderRadius: '50%',
  },
  isAnswerGroup: {
    borderRadius: '0',
  },
  isDragging: {
    background: grey[200],
  },
  dropTarget: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    border: `1px dashed ${green[600]}`,
    height: 24,
    flex: 1,
    marginLeft: 8,
    marginRight: 8,
    opacity: 0.5,
  },
  dropTargetHover: {
    background: `repeating-linear-gradient(
      45deg,
      ${green[200]},
      ${green[200]} 10px,
      ${green[600]} 10px,
      ${green[600]} 20px
    );`,
    color: '#fff',
  },
};

export function DigibookAnswerGroup({ handleClick, classes, selected, group, digibookId }) {
  const dispatch = useDispatch();
  const selectedAnswer = useSelector(getSelectedAnswer);

  const [{ isDragging }, drag] = useDrag({
    type: DND_TYPES.GROUP,
    item: group,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: DND_TYPES.ANSWER,
    collect: monitor => ({
      isOverCurrent: monitor.isOver({ shallow: false }),
    }),
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        return;
      }

      dispatch(addToAnswerGroup(group.id, item.id));
    },
  });

  const toggleHoverClass = ({ currentTarget }) => currentTarget.classList.toggle(classes.dropTargetHover);

  const handleAnswerClick = answerId => {
    if (selectedAnswer === answerId) {
      dispatch(clearSelectedAnswer());
    } else {
      dispatch(setSelectedAnswer(answerId));
    }
  };

  return (
    <DropZone weight={group.weight} digibookId={digibookId}>
      <ListItem
        ref={drag}
        divider
        selected={selected}
        onClick={handleClick}
        className={classNames(classes.listItem, {
          [classes.isDragging]: isDragging,
        })}
      >
        <ListItemIcon className={classes.iconWrapper}>
          <div className={classNames(classes.badge, classes.isAnswerGroup)}>
            <span>{group.weight}</span>
          </div>
        </ListItemIcon>
        <div
          ref={drop}
          onDragEnter={toggleHoverClass}
          onDragLeave={toggleHoverClass}
          onDrop={toggleHoverClass}
          className={classes.dropTarget}
        />
        <ListItemIcon classes={{ root: classes.dragIndicator }}>
          <DragIndicator />
        </ListItemIcon>
      </ListItem>
      <List disablePadding>
        {!isDragging &&
          group.members.map(a => (
            <Answer
              key={a.id}
              answer={a}
              groupWeight={group.weight}
              digibookId={digibookId}
              handleClick={() => handleAnswerClick(a.id)}
              selected={selectedAnswer === a.id}
            />
          ))}
      </List>
    </DropZone>
  );
}

DigibookAnswerGroup.propTypes = {
  handleClick: func.isRequired,
  classes: object,
  selected: bool,
  digibookId: string.isRequired,
  group: shape({
    weight: string.isRequired,
  }).isRequired,
};

DigibookAnswerGroup.defaultProps = {
  classes: {},
  selected: false,
};

export default withStyles(styles)(DigibookAnswerGroup);
