import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import { Add as AddIcon } from '@mui/icons-material';

import Hierarchy from '../../components/hierarchy/hierarchy';
import DigibookList from './components/digibook-list/digibook-list';
import LinkButton from '../../components/buttons/link-button';

import { getCurrentHierarchyModuleId } from '../../selectors/context';

const styles = theme => ({
  actions: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
});

export function Codex({ classes, moduleId }) {
  return (
    <div>
      <Hierarchy title="Module" />
      <div className={classes.actions}>
        <LinkButton
          id="digibook__action--add"
          to="/codex/new"
          buttonProps={{
            color: 'primary',
            disabled: !moduleId,
          }}
        >
          <AddIcon />
          Add digibook
        </LinkButton>
      </div>
      {moduleId && <DigibookList moduleId={moduleId} />}
    </div>
  );
}

Codex.propTypes = {
  classes: PropTypes.object,
  moduleId: PropTypes.string,
};

Codex.defaultProps = {
  classes: {},
  moduleId: undefined,
};

const mapStateToProps = state => ({
  moduleId: getCurrentHierarchyModuleId(state),
});

export const ConnectedCodex = connect(mapStateToProps)(Codex);

export default connect(mapStateToProps)(withStyles(styles)(Codex));
