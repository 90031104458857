import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { IconButton } from '@mui/material';
import { Flag, FlagOutlined } from '@mui/icons-material';
import { SlideSetVersion, putSlideSetVersionState } from '../../../api/slideSetVersion';
import { SlideSetVersionState } from '../../../api/slideSet';
import SlidingConfirmation from '../../../components/sliding-confirmation';
import SlideSetVersionContext from '../../../contexts/slideset-version-context';

type ReviewProps = {
  version: SlideSetVersion;
};

const ReviewAction = ({ version }: ReviewProps) => {
  const { refreshVersions, versions } = useContext(SlideSetVersionContext);
  const { nodeId, moduleId, setId } = useParams();
  const isInReview = version.state === SlideSetVersionState.REVIEW;

  const handlePutReview = async () => {
    if (!moduleId || !nodeId || !setId) return;
    await putSlideSetVersionState(moduleId, nodeId, setId, version.id.toString(), SlideSetVersionState.REVIEW);
    refreshVersions();
  };

  const handlePutDraft = async () => {
    if (!moduleId || !nodeId || !setId) return;
    await putSlideSetVersionState(moduleId, nodeId, setId, version.id.toString(), SlideSetVersionState.DRAFT);
    refreshVersions();
  };

  const hasReviewSlidesetVersion = versions.some(v => v.state === SlideSetVersionState.REVIEW);
  const isInDraft = version.state === SlideSetVersionState.DRAFT;

  const button = (
    <IconButton
      id="flag"
      disabled={!isInReview && !isInDraft}
      color="primary"
      size="large"
      data-testid="review-button"
      onClick={isInReview ? handlePutDraft : handlePutReview}
    >
      <span title={isInReview ? 'Unreview' : 'Review'}>{isInReview ? <Flag /> : <FlagOutlined />} </span>
    </IconButton>
  );

  if (hasReviewSlidesetVersion && !isInReview) {
    return (
      <SlidingConfirmation
        title="Warning: replacing current set"
        context="This slide set will replace the current slides in review, which will be put back to draft state."
        disagreeAction="Cancel"
        agreeAction="Put in review"
      >
        {button}
      </SlidingConfirmation>
    );
  }

  return button;
};

export default ReviewAction;
