import { createSelector } from 'reselect';

const getNotifications = state => state.notification.notifications;
const getNextNotificationId = state => state.notification.queue[0];
const getMaintenance = state => state.notification.maintenance;

export const getNextNotification = createSelector(
  getNextNotificationId,
  getNotifications,
  (id, notifications) => notifications[id],
);

export const getMaintenanceMode = createSelector(getMaintenance, maintenance => maintenance);
