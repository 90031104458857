import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import { Card, CardActions, CardContent, CardHeader, Typography, Button } from '@mui/material';

import { logOff } from '../../services/auth-service';

const styles = theme => ({
  container: {
    width: '40vw',
  },
  action: {
    textDecoration: 'none',
  },
  cardHeader: {
    backgroundColor: theme.palette.error.main,
  },
  title: {
    color: '#fafafa',
  },
});

export function NotFound({ classes, allowHome }) {
  return (
    <Card className={classes.container}>
      <CardHeader
        className={classes.cardHeader}
        disableTypography
        title={
          <Typography variant="h5" className={classes.title}>
            Access Denied/Forbidden :(
          </Typography>
        }
      />
      <CardContent>
        <Typography>The page you were trying to reach is absolutely forbidden for some reason</Typography>
      </CardContent>
      <CardActions>
        {allowHome && (
          <Link to="/" className={classes.action}>
            <Button color="primary" size="small">
              Go to homepage
            </Button>
          </Link>
        )}
        <Button color="primary" onClick={() => logOff()} size="small">
          Log out
        </Button>
      </CardActions>
    </Card>
  );
}

NotFound.propTypes = {
  classes: PropTypes.object,
  allowHome: PropTypes.bool,
};

NotFound.defaultProps = {
  classes: {},
  allowHome: false,
};

export default withStyles(styles)(NotFound);
