import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { ActiveAreaForm } from '../../utils';

const WebLink = () => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<ActiveAreaForm>();
  return (
    <Grid container marginLeft="32px" marginTop="16px" width="400px">
      <TextField
        id="url"
        label="URL"
        variant="outlined"
        value={values.url}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.url && Boolean(errors.url)}
        helperText={touched.url && errors.url}
        fullWidth
        required
      />
    </Grid>
  );
};

export default WebLink;
