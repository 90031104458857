import { array, shape, string, number } from 'prop-types';

export default shape({
  pages: array.isRequired,
  shape: shape({
    top: number,
    left: number,
    width: number,
    height: number,
    angle: number,
  }),
  name: string.isRequired,
  id: string,
  linkType: string,
  url: string,
});
