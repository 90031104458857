import get from 'get-value';

import { getCurrentHierarchyModuleId } from './context';
import { getTocNodes } from './table-of-content';

export const getMediaLinkById = (state, id) => get(state, ['medialinks', 'entities', id]);
const getMediaLinkIdsForNode = (state, nodeId) => get(state, ['medialinks', 'byNode', nodeId, 'ids']);

export const getContentTypeById = (state, id) => get(state, ['medialinks', 'contentTypes', 'entities', id]);
export const getMediaLinksForIds = (state, ids) => (ids || []).map(id => getMediaLinkById(state, id)).filter(x => x);

export function getMediaLinksForNodeId(state, nodeId) {
  if (!nodeId) return [];
  const mediaLinkIds = getMediaLinkIdsForNode(state, nodeId);
  return getMediaLinksForIds(state, mediaLinkIds);
}

export function getMediaLinksForIdsOrderedByToc(state, ids) {
  const currentHierarchyModuleId = getCurrentHierarchyModuleId(state);
  const allNodesInOrder = getTocNodes(state, currentHierarchyModuleId);

  const mediaLinks = getMediaLinksForIds(state, ids);

  return allNodesInOrder.reduce((arr, node) => {
    arr.push(...mediaLinks.filter(link => link.hierarchy[link.hierarchy.length - 1] === node.id));
    return arr;
  }, []);
}
