import React from 'react';
import Uploader from 'rc-upload';
import { connect } from 'react-redux';

import { func, object, string, shape, bool, oneOf } from 'prop-types';

import { OutlinedInput, FormControl, InputLabel } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import UploadAdornment from '../components/upload-adornment';

import { getSecuredFileById, getLoadingForField } from '../../../selectors/secured-file';
import { uploadFileFailed } from '../../../actions/secured-file';

const styles = {
  inputAdornedEnd: {
    padding: 0,
  },
};

export class RenderedUpload extends React.Component {
  componentWillUnmount() {
    this.cancelUpload();
  }

  handleDownload(ev) {
    ev.stopPropagation();
    const { onDownload } = this.props;
    onDownload();
  }

  unlinkFile(ev) {
    ev.stopPropagation();
    const { setFieldValue, name, onClear } = this.props;
    onClear(name);
    setFieldValue(name, null);
  }

  uploadToS3(file) {
    const { upload, currentFile } = this.props;

    return {
      uploadPromise: upload(file),
      abort: () => currentFile && currentFile.source.cancel(), // need this in case the UploadField gets 'destroyed'. This triggers a clean cancel of the current request.
    };
  }

  cancelUpload(ev) {
    const { currentFile, clearFileLoadingState, name, form } = this.props;
    if (ev) ev.stopPropagation();
    if (currentFile && currentFile.source) {
      currentFile.source.cancel();
      clearFileLoadingState(currentFile.id, form, name);
    }
  }

  render() {
    const { label, currentFile, displayName, classes, accept, isUploading } = this.props;

    return (
      <FormControl data-testid={label} variant="standard" fullWidth margin="normal">
        <InputLabel htmlFor={displayName} shrink variant="outlined">
          {label}
        </InputLabel>
        <Uploader
          customRequest={({ file }) => this.uploadToS3(file)}
          disabled={!!currentFile || isUploading}
          accept={accept}
        >
          <OutlinedInput
            className={classes.inputAdornedEnd}
            fullWidth
            name={displayName}
            value={(currentFile && currentFile.name) || ''}
            notched
            label={label}
            placeholder="Click to upload a file"
            readOnly
            endAdornment={
              <UploadAdornment
                file={!!currentFile}
                loading={isUploading}
                unlinkFile={ev => this.unlinkFile(ev)}
                cancelUpload={ev => this.cancelUpload(ev)}
                onDownload={ev => this.handleDownload(ev)}
              />
            }
          />
        </Uploader>
      </FormControl>
    );
  }
}

RenderedUpload.propTypes = {
  name: string.isRequired,
  setFieldValue: func.isRequired,
  clearFileLoadingState: func.isRequired,
  onClear: func.isRequired,
  upload: func.isRequired,
  label: string.isRequired,
  currentFile: shape({
    name: string,
    id: string,
    uploadUrl: string,
    isUploading: bool,
    source: object,
  }),
  displayName: string.isRequired,
  classes: object,
  accept: string,
  isUploading: bool,
  onDownload: func,
  // eslint-disable-next-line react/no-unused-prop-types
  form: oneOf(['digibook', 'digislides-import', 'slide-editor']),
};

RenderedUpload.defaultProps = {
  currentFile: undefined,
  classes: {},
  accept: undefined,
  isUploading: false,
  onDownload: undefined,
  form: 'digibook',
};

const mapStateToProps = (state, { value: fileId, name, form }) => ({
  currentFile: getSecuredFileById(state, fileId),
  isUploading: getLoadingForField(state, form, name),
});

const mapDispatchToProps = dispatch => {
  return {
    clearFileLoadingState: (id, form, field) => dispatch(uploadFileFailed(id, form, field)),
  };
};

export const ConnectedDigibookFormUpload = connect(mapStateToProps, mapDispatchToProps)(RenderedUpload);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RenderedUpload));
