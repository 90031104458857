import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Button, FormControl, Grid, InputLabel } from '@mui/material';
import Hierarchy from '../../components/hierarchy/hierarchy';
import { useAppSelector } from '../../hooks/store';
import { getCurrentHierarchyModuleId, getCurrentTocNodeId } from '../../selectors/context';
import { TocNodeSelect } from '../../components/hierarchy/TocNodeSelect';
import { postSlideSet, getSlideSets, deleteSlideSet, SlideSet } from '../../api/slideSet';
import DigislideList from './components/slideset-list/slideset-list';
import { getCurrentPublishingHouse } from '../../selectors/publishing-house';
import { getTranslatedGeneral } from '../../utils/slideset-helper';

export default function SlideSets() {
  const [digislides, setDigiSlides] = React.useState<SlideSet[]>([]);
  const nodeId = useAppSelector(getCurrentTocNodeId);
  const moduleId = useAppSelector(getCurrentHierarchyModuleId);
  const publishingHouse = useAppSelector(getCurrentPublishingHouse);

  const navigate = useNavigate();

  const handleGetSlideSets = async () => {
    const slideSets = await getSlideSets(moduleId, nodeId);
    if (!slideSets) return;

    const translatedSlideSets = slideSets.map(slideSet => {
      if (slideSet.name === 'general') {
        return {
          ...slideSet,
          name: getTranslatedGeneral(publishingHouse.studioGroup),
        };
      }
      return slideSet;
    });

    const sortedSlideSets = translatedSlideSets.sort((a, b) => a.name.localeCompare(b.name));

    setDigiSlides(sortedSlideSets);
  };

  const handlePostSlideSet = async () => {
    const newSlideSet = await postSlideSet(moduleId, nodeId);
    navigate(`/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${newSlideSet.id}`);
  };

  const handleDeleteSlideSet = async (id: string) => {
    deleteSlideSet(moduleId, nodeId, id);
    setDigiSlides(digislides.filter((d: SlideSet) => d.id !== id));
  };

  const cachedHandleGetSlideSets = React.useCallback(handleGetSlideSets, [moduleId, nodeId, publishingHouse]);

  useEffect(() => {
    if (nodeId && moduleId) cachedHandleGetSlideSets();
  }, [nodeId, moduleId, cachedHandleGetSlideSets]);

  return (
    <div data-testid="slidesets">
      <Hierarchy title="Module" />
      {moduleId && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} key="tocNode">
            <FormControl variant="standard" margin="dense" id="toc-node" fullWidth>
              <InputLabel htmlFor="toc-node-select">Table of Contents</InputLabel>
              <TocNodeSelect moduleId={moduleId} />
            </FormControl>
          </Grid>
          {nodeId && (
            <Grid item xs={12} md={4} key="addButton" style={{ display: 'flex', alignItems: 'center' }}>
              <Button variant="contained" color="primary" onClick={handlePostSlideSet}>
                Add slide set
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {nodeId && moduleId && <DigislideList digislides={digislides} onDelete={handleDeleteSlideSet} />}
    </div>
  );
}
