import { fabric } from 'fabric';
import { ACTIVE_AREA_TYPE, ActiveArea } from '../api';
import FabricService from '../../codex-detail/components/editor/services/fabric-service';

const CANVAS_BACKGROUND_COLOR = '#F0F0F0';
const PADDING = 0;

type Dimensions = { width: number; height: number };

export default class ImageCanvas {
  canvas: fabric.Canvas;

  constructor(id: string, dimensions: Dimensions) {
    this.canvas = new fabric.Canvas(id, {
      renderOnAddRemove: false,
      ...dimensions,
      backgroundColor: CANVAS_BACKGROUND_COLOR,
    });
  }

  private createEmptySlide() {
    // aspect ratio is 16/9
    return new fabric.Rect({
      // @ts-expect-error meta is not defined in fabric.
      meta: 'slide',
      left: 0,
      top: 0,
      width: 2560, // default width of converted slides
      height: 1440, // default height of converted slides
      fill: '#000000',
      selectable: false,
      evented: false,
    });
  }

  private createFabricImage(slide: HTMLImageElement, meta?: string, clipPath?: fabric.Group) {
    return new fabric.Image(slide, {
      left: 0,
      top: 0,
      // @ts-expect-error meta is not defined in fabric.
      meta,
      selectable: false,
      hasControls: false,
      evented: false,
      clipPath,
    });
  }

  private createClipPathGroup(slideSource: fabric.Image, activeAreas: ActiveArea[]) {
    const rects = activeAreas.reduce((acc, current) => {
      if (current.type === ACTIVE_AREA_TYPE.REVEAL) {
        const coord = FabricService.getAbsoluteCoordinatesForRectangle(current, slideSource.width, slideSource.height);
        const rect = new fabric.Rect(coord);

        acc.push(rect);
      }
      return acc;
    }, [] as fabric.Rect[]);

    return new fabric.Group(rects, { absolutePositioned: true });
  }

  showSlide(slide: HTMLImageElement | null) {
    this.removeObjects();

    let slideToAdd: fabric.Image | fabric.Rect | undefined;
    if (!slide) {
      slideToAdd = this.createEmptySlide();
    } else {
      slideToAdd = this.createFabricImage(slide, 'slide');
    }

    this.canvas.add(slideToAdd);
    this.adjustCanvasToSlide();
    this.render();
  }

  showBothSources(slideSource: HTMLImageElement, revealSource: HTMLImageElement, activeAreas: ActiveArea[]) {
    this.removeObjects();
    const sourceImage = this.createFabricImage(slideSource, 'slide');
    this.canvas.add(sourceImage);

    const revealImage = this.createFabricImage(
      revealSource,
      undefined,
      this.createClipPathGroup(sourceImage, activeAreas),
    );
    this.canvas.add(revealImage);

    this.adjustCanvasToSlide();
    this.render();
  }

  destroySlide() {
    this.removeObjects();
    this.render();
  }

  adjustCanvasToSlide() {
    // @ts-expect-error meta is not defined in fabric.
    const slide = this.canvas.getObjects().find(x => x.meta === 'slide') as fabric.Image;
    if (!slide) return;
    this.canvas.setZoom(1);

    const scaleHeightToFit = (this.canvas.height! - PADDING * 2) / slide.getScaledHeight();
    const scaleWidthToFit = (this.canvas.width! - PADDING * 2) / slide.getScaledWidth();

    const scale = Math.min(scaleHeightToFit, scaleWidthToFit);

    const fitToWidth = scaleWidthToFit < scaleHeightToFit;

    this.canvas.setZoom(scale);

    const neededHeight = slide.height! * scale;
    const neededWidth = slide.width! * scale;
    const targetY = fitToWidth ? Math.round((this.canvas.height! - neededHeight) / 2) : PADDING;

    const targetX = fitToWidth ? PADDING : Math.round((this.canvas.width! - neededWidth) / 2);

    this.setViewportLeftOffset(targetX);
    this.setViewportTopOffset(targetY);
  }

  resizeCanvas(dimensions: Dimensions) {
    this.canvas.setDimensions(dimensions);
    this.adjustCanvasToSlide();
  }

  setViewportLeftOffset(offset: number) {
    this.canvas.viewportTransform![4] = Math.round(offset);
    this.canvas.setViewportTransform(this.canvas.viewportTransform!);
  }

  setViewportTopOffset(offset: number) {
    this.canvas.viewportTransform![5] = Math.round(offset);
    this.canvas.setViewportTransform(this.canvas.viewportTransform!);
  }

  destroy() {
    this.canvas.dispose();
  }

  render() {
    this.canvas.requestRenderAll();
  }

  removeObjects() {
    this.canvas.remove(...this.canvas.getObjects());
  }

  getCanvasViewportTransform() {
    return this.canvas.viewportTransform;
  }
}
