import { createSelector } from 'reselect';
import Roles from '../constants/roles';

const authenticationState = state => state.authentication;

export const getSubject = state => authenticationState(state).sub;
export const getFirstName = state => authenticationState(state).givenname;
export const getLastName = state => authenticationState(state).surname;
export const isAuthenticated = state => !!getSubject(state);
export const getEmail = state => authenticationState(state).email;
export const getRoles = state => authenticationState(state).roles;

export const getFullName = createSelector(getFirstName, getLastName, (firstName, lastName) => {
  const names = [];
  if (firstName) names.push(firstName);
  if (lastName) names.push(lastName);
  return names.join(' ');
});

export const getAllowedRoles = createSelector(getRoles, roles =>
  roles ? roles.filter(role => Object.values(Roles).includes(role)) : [],
);
