import { useDrag, useDrop } from 'react-dnd';
import { SlideGroup } from '../../../api/slideSet';
import { DND_TYPES, Item } from '../drag-and-drop-utils';

export default function useGroupDropZone({
  addSlideToGroup,
  group,
}: {
  addSlideToGroup: (originalGroupId: number, targetGroupId: number, slide: SlideGroup['slides'][number]) => void;
  group: SlideGroup;
}) {
  const type = DND_TYPES.NON_SINGLE_GROUP;

  const [{ isDragging }, dragRef, previewRef] = useDrag(
    () => ({
      type,
      item: { group, type },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [],
  );

  const [{ isOver }, dropRef] = useDrop<Item, unknown, { isOver: boolean }>(
    () => ({
      accept: [DND_TYPES.SINGLE_GROUP, DND_TYPES.GROUP_SLIDE],
      collect: monitor => ({
        isOver: monitor.isOver() && monitor.getItem().group.id !== group.id,
      }),
      drop: item => {
        addSlideToGroup(item.group.id, group.id, item.slide);
      },
    }),
    [addSlideToGroup, group],
  );

  return {
    isDragging,
    dragRef,
    previewRef,
    isOver,
    dropRef,
  };
}
