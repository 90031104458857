import { SlideGroup } from '../../api/slideSet';

export const DND_TYPES = {
  GROUP_SLIDE: 'GROUP_SLIDE',
  SINGLE_GROUP: 'SINGLE_GROUP',
  NON_SINGLE_GROUP: 'NON_SINGLE_GROUP',
};

export type Item = {
  group: SlideGroup;
  slide: SlideGroup['slides'][number];
  type: (typeof DND_TYPES)[keyof typeof DND_TYPES];
};
