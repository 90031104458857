import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '64px',
  },
}));

const Home = () => {
  const classes = useStyles();

  return <h1 className={classes.root}>Home</h1>;
};

export default Home;
