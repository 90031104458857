export const AuthenticationActionType = {
  AUTHENTICATE_SUCCEEDED: 'AUTHENTICATION_AUTHENTICATE_SUCCEEDED',
  REFRESHTOKEN_SUCCEEDED: 'AUTHENTICATION_REFRESHTOKEN_SUCCEEDED',
  REFRESHTOKEN_FAILED: 'AUTHENTICATION_REFRESHTOKEN_FAILED',
};

export const PublishingHouseActionType = {
  FETCH_PUBLISHINGHOUSES: 'FETCH_PUBLISHINGHOUSES',
  FETCH_PUBLISHINGHOUSES_SUCCESS: 'FETCH_PUBLISHINGHOUSES_SUCCESS',
  FETCH_PUBLISHINGHOUSES_FAILURE: 'FETCH_PUBLISHINGHOUSES_FAILURE',
};

export const SubjectActionType = {
  FETCH_SUBJECTS_BY_PUBLISHING_HOUSE: 'FETCH_SUBJECTS_BY_PUBLISHING_HOUSE',
  FETCH_SUBJECTS_BY_PUBLISHING_HOUSE_SUCCESS: 'FETCH_SUBJECTS_BY_PUBLISHING_HOUSE_SUCCESS',
  FETCH_SUBJECTS_BY_PUBLISHING_HOUSE_FAILURE: 'FETCH_SUBJECTS_BY_PUBLISHING_HOUSE_FAILURE',
};

export const MethodActionType = {
  FETCH_METHODS_BY_SUBJECT: 'FETCH_METHODS_BY_SUBJECT',
  FETCH_METHODS_BY_SUBJECT_SUCCESS: 'FETCH_METHODS_BY_SUBJECT_SUCCESS',
  FETCH_METHODS_BY_SUBJECT_FAILURE: 'FETCH_METHODS_BY_SUBJECT_FAILURE',
};

export const ModuleActionType = {
  FETCH_MODULES_BY_METHOD: 'FETCH_MODULES_BY_METHOD',
  FETCH_MODULES_BY_METHOD_SUCCESS: 'FETCH_MODULES_BY_METHOD_SUCCESS',
  FETCH_MODULES_BY_METHOD_FAILURE: 'FETCH_MODULES_BY_METHOD_FAILURE',
};

export const DigibookActionType = {
  FETCH_DIGIBOOKS_BY_MODULE: 'FETCH_DIGIBOOKS_BY_MODULE',
  FETCH_DIGIBOOKS_BY_MODULE_SUCCESS: 'FETCH_DIGIBOOKS_BY_MODULE_SUCCESS',
  FETCH_DIGIBOOKS_BY_MODULE_FAILURE: 'FETCH_DIGIBOOKS_BY_MODULE_FAILURE',
  FETCH_DIGIBOOK_BY_ID_SUCCESS: 'FETCH_DIGIBOOK_BY_ID_SUCCESS',
  FETCH_DIGIBOOK_BY_ID_FAILURE: 'FETCH_DIGIBOOK_BY_ID_FAILURE',
  DIGIBOOK_ADDED: 'DIGIBOOK_ADDED',
  DELETE_DIGIBOOK_SUCCESS: 'DELETE_DIGIBOOK_SUCCESS',
  DIGIBOOK_UPDATED: 'DIGIBOOK_UPDATED',
  CLEAR_LAST_ADDED: 'CLEAR_LAST_ADDED',
};

export const ContextActionType = {
  CLEAR_CURRENT_HIERARCHY_METHOD: 'CONTEXT_CLEAR_CURRENT_HIERARCHY_METHOD',
  CLEAR_CURRENT_HIERARCHY_MODULE: 'CONTEXT_CLEAR_CURRENT_HIERARCHY_MODULE',
  CLEAR_CURRENT_HIERARCHY_SUBJECT: 'CONTEXT_CLEAR_CURRENT_HIERARCHY_SUBJECT',
  CLEAR_CURRENT_TOC_NODE: 'CONTEXT_CLEAR_CURRENT_TOC_NODE',
  CLEAR_CURRENT_PUBLISHING_HOUSE: 'CONTEXT_CLEAR_CURRENT_PUBLISHING_HOUSE',
  SET_CURRENT_HIERARCHY_METHOD: 'CONTEXT_SET_CURRENT_HIERARCHY_METHOD',
  SET_CURRENT_HIERARCHY_MODULE: 'CONTEXT_SET_CURRENT_HIERARCHY_MODULE',
  SET_CURRENT_HIERARCHY_SUBJECT: 'CONTEXT_SET_CURRENT_HIERARCHY_SUBJECT',
  SET_CURRENT_TOC_NODE: 'CONTEXT_SET_CURRENT_TOC_NODE',
  SET_CURRENT_PUBLISHING_HOUSE: 'CONTEXT_SET_CURRENT_PUBLISHING_HOUSE',
};

export const SecuredFileActionType = {
  FETCH_FILE_BY_ID: 'FETCH_FILE_BY_ID',
  FETCH_FILE_BY_ID_SUCCESS: 'FETCH_FILE_BY_ID_SUCCESS',
  FETCH_FILE_BY_ID_FAILURE: 'FETCH_FILE_BY_ID_FAILURE',
  UPLOAD_FILE_REQUESTED: 'UPLOAD_FILE_REQUESTED',
  UPLOAD_FILE_STARTED: 'UPLOAD_FILE_STARTED',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILED: 'UPLOAD_FILE_FAILED',
  CLEAR_FILE_BY_ID: 'CLEAR_FILE_BY_ID',
};

export const TableOfContentActionType = {
  FETCH_TOC_BY_MODULE_SUCCESS: 'FETCH_TOC_BY_MODULE_SUCCESS',
  FETCH_TOC_BY_MODULE_FAILURE: 'FETCH_TOC_BY_MODULE_FAILURE',
};

export const CodexEditorActionType = {
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',
  SET_FILE_FOR_LAYER: 'SET_FILE_FOR_LAYER',
  INIT_LINK_AREAS: 'INIT_LINK_AREAS',
  ADD_LINK_AREA: 'ADD_LINK_AREA',
  EDIT_LINK_AREA: 'EDIT_LINK_AREA',
  EDIT_LINK_AREA_SHAPE: 'EDIT_LINK_AREA_SHAPE',
  DELETE_LINK_AREA: 'DELETE_LINK_AREA',
  EDITOR_SAVE_SUCCESS: 'EDITOR_SAVE_SUCCESS',
  ON_PREVIOUS_PAGE: 'ON_PREVIOUS_PAGE',
  ON_NEXT_PAGE: 'ON_NEXT_PAGE',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  SET_SCALE: 'SET_SCALE',
  SET_VIEWPORT: 'SET_VIEWPORT',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_NODE_LEVEL: 'SET_NODE_LEVEL',
  SET_OBJECT_MOVING: 'SET_OBJECT_MOVING',
  SET_HIERARCHY_FOR_DIGIBOOK_LINK: 'SET_HIERARCHY_FOR_DIGIBOOK_LINK',
  TOGGLE_IS_DRAWING: 'TOGGLE_IS_DRAWING',
  DISABLE_IS_DRAWING: 'DISABLE_IS_DRAWING',
  SET_CURRENT_NODE_FOR_MEDIA_CONTEXT: 'SET_CURRENT_NODE_FOR_MEDIA_CONTEXT',
  CLEAR_CURRENT_NODE_FOR_MEDIA_CONTEXT: 'CLEAR_CURRENT_NODE_FOR_MEDIA_CONTEXT',
  INIT_ANSWER_SETS: 'INIT_ANSWER_SETS',
  ADD_ANSWER_SET: 'ADD_ANSWER_SET',
  REMOVE_ANSWER_SET: 'REMOVE_ANSWER_SET',
  EDIT_ANSWER_SET: 'EDIT_ANSWER_SET',
  SET_SELECTED_ANSWER_SET: 'SET_SELECTED_ANSWER_SET',
  CLEAR_SELECTED_ANSWER_SET: 'CLEAR_SELECTED_ANSWER_SET',
  ADD_ANSWER_TO_SET: 'ADD_ANSWER_TO_SET',
  REMOVE_ANSWER_FROM_SET: 'REMOVE_ANSWER_FROM_SET',
  SET_SELECTED_ANSWER: 'SET_SELECTED_ANSWER',
  CLEAR_SELECTED_ANSWER: 'CLEAR_SELECTED_ANSWER',
  INIT_MANUAL_MAPPING: 'INIT_MANUAL_MAPPING',
  UPDATE_ANSWER_SET_ANSWERS: 'UPDATE_ANSWER_SET_ANSWERS',
  ADD_MANUAL_MAPPING: 'ADD_MANUAL_MAPPING',
  SAVE_MANUAL_MAPPING: 'SAVE_MANUAL_MAPPING',
  DELETE_MANUAL_MAPPING: 'DELETE_MANUAL_MAPPING',
};

export const NotificationActionType = {
  SHOW: 'NOTIFICATION_SHOW',
  DISMISS: 'NOTIFICATION_DISMISS',
  MAINTENANCE: 'NOTIFICATION_MAINTENANCE',
};

export const MediaLinkActionType = {
  FETCH_MEDIA_LINK_STARTED: 'FETCH_MEDIA_LINK_STARTED',
  FETCH_MEDIA_LINK_SUCCESS: 'FETCH_MEDIA_LINK_SUCCESS',
  FETCH_MEDIA_LINK_FAILED: 'FETCH_MEDIA_LINK_FAILED',
  FETCH_MEDIA_CONTENT_TYPE_STARTED: 'FETCH_MEDIA_CONTENT_TYPE_STARTED',
  FETCH_MEDIA_CONTENT_TYPE_SUCCESS: 'FETCH_MEDIA_CONTENT_TYPE_SUCCESS',
  FETCH_MEDIA_CONTENT_TYPE_FAILED: 'FETCH_MEDIA_CONTENT_TYPE_FAILED',
  FETCH_MEDIALINKS_FOR_LINKAREA_STARTED: 'FETCH_MEDIALINKS_FOR_LINKAREA_STARTED',
  FETCH_MEDIALINKS_FOR_LINKAREA_SUCCESS: 'FETCH_MEDIALINKS_FOR_LINKAREA_SUCCESS',
  FETCH_MEDIALINKS_FOR_LINKAREA_FAILED: 'FETCH_MEDIALINKS_FOR_LINKAREA_FAILED',
};
