import React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(({ theme }) => ({
  drop: {
    height: '1rem',
    width: '100%',
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  dropping: {
    height: '10rem',
    backgroundColor: theme.palette.primary[100],
  },
}));

type DropZoneProps = {
  isOver: boolean;
  'data-testid'?: string;
};

function DropZone({
  innerRef,
  isOver,
  'data-testid': dataTestId,
}: DropZoneProps & { innerRef: React.ForwardedRef<HTMLDivElement> }) {
  const { classes, cx } = useStyles();

  return (
    <div data-testid={dataTestId} className={cx({ [classes.drop]: true, [classes.dropping]: isOver })} ref={innerRef} />
  );
}

export default React.forwardRef<HTMLDivElement, DropZoneProps>((props, ref) => <DropZone {...props} innerRef={ref} />);
