import { fabric } from 'fabric';
import { green } from '@mui/material/colors';

import answerSetMiddle from '!!raw-loader!../../../../../../assets/images/answer-set-icon.svg'; // eslint-disable-line import/no-unresolved, import/no-webpack-loader-syntax
import answerSetNext from '!!raw-loader!../../../../../../assets/images/answer-set-next.svg'; // eslint-disable-line import/no-unresolved, import/no-webpack-loader-syntax
import answerSetPrev from '!!raw-loader!../../../../../../assets/images/answer-set-prev.svg'; // eslint-disable-line import/no-unresolved, import/no-webpack-loader-syntax
import FabricService from './fabric-service';

const disableControls = {
  selectable: true,
  hasBorders: false,
  hasControls: false,
  hasRotatingPoint: false,
};

export const ANSWER_SET_META = {
  MARKER: 'marker',
  ICON: 'icon',
  ARROWS: 'arrows',
};

export const MARKER_RADIUS_OFFSET = 2;
export default class AnswerSetService extends FabricService {
  constructor(id) {
    super(id);
    this.MARKER_RADIUS = 6.5;
    this.MARKER_FILL = 'rgb(50,205,50)';
    this.RECTANGLE_FILL = 'rgba(50,205,50, 0.15)';
  }

  calcMarkerPositionForSvg(groupedSvg, width) {
    return {
      left:
        groupedSvg.left + groupedSvg.getScaledWidth() + 1.5 * this.MARKER_RADIUS > width
          ? groupedSvg.left - this.MARKER_RADIUS * 2
          : groupedSvg.left + groupedSvg.getScaledWidth(),
      top: groupedSvg.top + groupedSvg.getScaledHeight(),
    };
  }

  adjustMarkerForSvg(changedAnswerSetShape, marker, width) {
    marker.set({
      ...this.calcMarkerPositionForSvg(changedAnswerSetShape, width),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  adjustNavigationForSvg(changedAnswerSetShape, group) {
    group.set({
      left: changedAnswerSetShape.left + changedAnswerSetShape.getScaledWidth() / 2 - group.getScaledWidth() / 2,
      top: changedAnswerSetShape.top + changedAnswerSetShape.getScaledHeight() / 2 - group.getScaledHeight() / 2,
    });
  }

  drawAnswerSets(
    answerSets,
    setMoving,
    commitAnswerSetChanges,
    handleSetSelection,
    isSinglePage,
    currentId,
    zoomFactor,
  ) {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects().filter(x => x.layer === 'set'));

    const arrowPadding = 5 / zoomFactor;

    const scaleX = isSinglePage ? this.fabricCanvas.width * 2 : this.fabricCanvas.width;

    const scaleY = this.fabricCanvas.height;

    const denormalizedShapes = answerSets.map(answerSet => {
      const newShape = AnswerSetService.getAbsoluteCoordinatesForShape(answerSet.shape, scaleX, scaleY);
      return {
        setId: answerSet.id,
        ...newShape,
      };
    });

    denormalizedShapes.forEach(async (shape, index) => {
      const [middle, next, prev] = await Promise.all([
        FabricService.cacheAndGroupSVG(answerSetMiddle),
        FabricService.cacheAndGroupSVG(answerSetNext),
        FabricService.cacheAndGroupSVG(answerSetPrev),
      ]);

      const { width } = this.fabricCanvas;

      const { top, left, size, setId } = shape;

      middle
        .scaleToWidth(size)
        .set({
          left,
          top,
          size,
          hasControls: false,
          hasBorders: false,
          hoverCursor: 'grab',
          layer: 'set',
          meta: ANSWER_SET_META.ICON,
        })
        .setCoords();

      next.scaleToHeight(middle.getScaledHeight() - arrowPadding).set({
        left: left + middle.getScaledWidth() / 2,
        top: top + arrowPadding / 2,
        hoverCursor: 'pointer',
        ...disableControls,
      });

      prev.scaleToHeight(middle.getScaledHeight() - arrowPadding).set({
        left: left - prev.getScaledWidth() + middle.getScaledWidth() / 2,
        top: top + arrowPadding / 2,
        hoverCursor: 'pointer',
        ...disableControls,
      });

      const markerCircle = new fabric.Circle({
        radius: this.MARKER_RADIUS,
        fill: green[900],
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      });

      const markerText = new fabric.Text(`${index + 1}`, {
        fill: '#fff',
        fontFamily: 'arial',
        fontSize: 10,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      });

      const marker = new fabric.Group([markerCircle, markerText]);
      this.adjustMarkerForSvg(middle, marker, width);

      const navigationArrows = new fabric.Group([prev, next]);
      this.adjustNavigationForSvg(middle, navigationArrows);

      middle.on({
        mousedown: () => setMoving(true),
        mouseup: () => {
          handleSetSelection(setId);
          setMoving(false);
        },
        moving: e => {
          const { target } = e.transform;

          this.adjustNavigationForSvg(target, navigationArrows);
          this.adjustMarkerForSvg(target, marker, width);
        },
        modified: e =>
          commitAnswerSetChanges(
            AnswerSetService.getRelativeCoordinatesForShape(e.transform.target, scaleX, scaleY),
            index,
          ),
      });

      marker.set({
        layer: 'set',
        objectCaching: false,
        selectable: false,
        evented: false,
        draggable: false,
        hasControls: false,
        hasRotatingPoint: false,
        meta: ANSWER_SET_META.MARKER,
      });

      navigationArrows.set({
        layer: 'set',
        objectCaching: false,
        selectable: false,
        evented: false,
        draggable: false,
        hasControls: false,
        hasRotatingPoint: false,
        opacity: setId === currentId ? 1 : 0.5,
        meta: ANSWER_SET_META.ARROWS,
      });

      if (setId === currentId) {
        [middle, navigationArrows].forEach(obj => {
          obj.set(
            'shadow',
            new fabric.Shadow({
              color: 'rgba(0, 77, 98, 0.5)',
              offsetX: 0,
              offsetY: 2.5,
              blur: 15,
            }),
          );
        });
      }

      this.fabricCanvas.add(navigationArrows, middle, marker);
    });
  }

  drawAnswers(answers, setMoving, commitAnswerChanges, handleSelection, isSinglePage, selectedId) {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects().filter(x => x.layer === 'answers'));

    const scaleX = isSinglePage ? this.fabricCanvas.width * 2 : this.fabricCanvas.width;

    const scaleY = this.fabricCanvas.height;

    const flattenedAnswers = answers.flatMap(a => (a.members ? a.members : a));

    const denormalizedAnswers = flattenedAnswers.map(shape =>
      FabricService.getAbsoluteCoordinatesForRectangle(shape, scaleX, scaleY),
    );

    const answerObjects = this.createEventedRectanglesWithMarkers(
      denormalizedAnswers,
      setMoving,
      commitAnswerChanges,
      scaleX,
      scaleY,
      MARKER_RADIUS_OFFSET,
    );

    const rectangles = answerObjects.filter(obj => obj.type === 'rect');
    const badges = answerObjects.filter(obj => obj.type !== 'rect');

    answerObjects.forEach(obj => {
      obj.set({
        layer: 'answers',
      });
    });

    rectangles.forEach(rect => {
      rect.on({
        mouseup: () => handleSelection(rect.get('id')),
      });
    });

    this.fabricCanvas.add(...rectangles, ...badges);

    if (selectedId !== undefined) {
      const newActive = rectangles.find(rect => rect.id === selectedId);

      if (newActive) {
        this.fabricCanvas.setActiveObject(newActive);
      }
    }

    if (this.isDrawingArea) {
      this.freezeObjects();
    }
  }

  static getAbsoluteCoordinatesForShape(shape, scaleX, scaleY) {
    return {
      top: shape.top * scaleY,
      left: shape.left * scaleX,
      size: shape.size * scaleX,
    };
  }

  static getRelativeCoordinatesForShape(shape, scaleX, scaleY) {
    return {
      top: shape.top / scaleY,
      left: shape.left / scaleX,
      size: shape.size / scaleX,
    };
  }
}
