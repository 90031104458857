/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Select } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { fetchPublishingHouses as fetchPublishingHousesActionCreator } from '../../actions/publishing-house';

import { getPublishingHousesByGroup } from '../../selectors/publishing-house';

const styles = {
  option: {
    color: 'black',
  },
};

export class PublishingHouseSelect extends React.Component {
  componentDidMount() {
    const { fetchPublishingHouses } = this.props;
    fetchPublishingHouses();
  }

  onPublishingHouseChange(publishingHouseId) {
    const { setPublishingHouseId, clearPublishingHouseId } = this.props;

    if (publishingHouseId === '') return clearPublishingHouseId();

    return setPublishingHouseId(publishingHouseId);
  }

  render() {
    const { classes, className, publishingHouseId, publishingHousesByGroup } = this.props;

    return (
      <Select
        variant="standard"
        className={className}
        name="publishingHouseId"
        native
        value={publishingHouseId}
        onChange={e => this.onPublishingHouseChange(e.target.value)}
      >
        <option value="" />
        {publishingHousesByGroup.map(group => [
          <optgroup className={classes.option} label={group.name} key={group.name}>
            {group.publishingHouses.map(ph => (
              <option value={ph.id} key={ph.id}>
                {ph.name}
              </option>
            ))}
          </optgroup>,
        ])}
      </Select>
    );
  }
}

PublishingHouseSelect.propTypes = {
  className: PropTypes.string,
  clearPublishingHouseId: PropTypes.func,
  fetchPublishingHouses: PropTypes.func.isRequired,
  publishingHouseId: PropTypes.string,
  setPublishingHouseId: PropTypes.func,
  publishingHousesByGroup: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      publishingHouses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
  classes: PropTypes.shape({
    option: PropTypes.string,
  }).isRequired,
};

PublishingHouseSelect.defaultProps = {
  className: undefined,
  publishingHouseId: undefined,
  clearPublishingHouseId: undefined,
  setPublishingHouseId: undefined,
  publishingHousesByGroup: [],
};

const mapStateToProps = state => ({
  publishingHousesByGroup: getPublishingHousesByGroup(state),
});

const mapDispatchToProps = {
  fetchPublishingHouses: fetchPublishingHousesActionCreator,
};

export const ConnectedPublishingHouseSelect = connect(mapStateToProps, mapDispatchToProps)(PublishingHouseSelect);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PublishingHouseSelect));
