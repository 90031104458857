import { NotificationActionType } from '../action-types';
import NotificationType from '../constants/notification-type';

const show = ({ id = Date.now(), message, title, variant }) => ({
  type: NotificationActionType.SHOW,
  payload: {
    id,
    message,
    title,
    variant,
  },
});

export const information = (message, title, id) => dispatch =>
  dispatch(
    show({
      id,
      message,
      title,
      variant: NotificationType.INFORMATION,
    }),
  );

export const warning = (message, title, id) => dispatch =>
  dispatch(
    show({
      id,
      message,
      title,
      variant: NotificationType.WARNING,
    }),
  );

export const success = (message, title, id) => dispatch =>
  dispatch(
    show({
      id,
      message,
      title,
      variant: NotificationType.SUCCESS,
    }),
  );

export const error = (message, title, id) => dispatch =>
  dispatch(
    show({
      id,
      message,
      title,
      variant: NotificationType.ERROR,
    }),
  );

export const maintenance = () => dispatch => dispatch({ type: NotificationActionType.MAINTENANCE });

export const dismiss = id => dispatch =>
  dispatch({
    type: NotificationActionType.DISMISS,
    payload: {
      id,
    },
  });
