import React from 'react';
import { number, object, func } from 'prop-types';
import { TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  textField: {
    width: 60,
    margin: 'auto',
    '& input': {
      textAlign: 'center',
      padding: '8px 8px',
    },
  },
});

export class PageNumberInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.currentPage,
    };
  }

  componentDidUpdate(prevProps) {
    const { currentPage } = this.props;
    // eslint-disable-next-line react/no-did-update-set-state
    if (prevProps.currentPage !== currentPage) this.setState({ value: currentPage });
  }

  render() {
    const { classes, onValueChanged, totalPages } = this.props;
    const { value: v } = this.state;
    return (
      <TextField
        className={classes.textField}
        value={v}
        margin="normal"
        variant="outlined"
        type="number"
        inputProps={{
          min: '0',
          max: `${totalPages + 1}`,
        }}
        onChange={e => {
          // set value in local state
          const { value } = e.target;
          this.setState({ value });
        }}
        onKeyDown={e => {
          // set value in redux
          const { value } = this.state;
          if (e.key === 'Enter' || e.keyCode === 13) onValueChanged(Math.floor(Number(value)));
        }}
      />
    );
  }
}

PageNumberInput.propTypes = {
  classes: object,
  currentPage: number.isRequired,
  totalPages: number.isRequired,
  onValueChanged: func.isRequired,
};

PageNumberInput.defaultProps = {
  classes: {},
};

export default withStyles(styles)(PageNumberInput);
