/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Select } from '@mui/material';

import { fetchModulesByMethod } from '../../actions/module';
import getModulesByMethod from '../../selectors/module';

export class ModuleSelect extends React.Component {
  componentDidMount() {
    const { fetchModulesBy, methodId } = this.props;
    fetchModulesBy(methodId);
  }

  componentDidUpdate(prevProps) {
    const { methodId: prevMethodId } = prevProps;
    const { methodId, fetchModulesBy } = this.props;

    if (prevMethodId !== methodId) fetchModulesBy(methodId);
  }

  render() {
    const {
      inputProps: { id, name, className },
      modules,
      onChange = () => {},
      value,
    } = this.props;

    return (
      <Select
        variant="standard"
        className={className}
        id={id}
        name={name}
        native
        onChange={e => onChange(e.target.value)}
        value={value}
      >
        <option value="" />
        {modules.map(module => (
          <option value={module.id} key={`method-${module.id}`}>
            {module.name}
          </option>
        ))}
      </Select>
    );
  }
}

ModuleSelect.propTypes = {
  // Real props
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
  }),
  methodId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,

  // Connected props
  fetchModulesBy: PropTypes.func.isRequired,
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

ModuleSelect.defaultProps = {
  inputProps: {},
  onChange: undefined,
  modules: [],
  value: undefined,
};

const mapStateToProps = (state, props) => ({
  modules: getModulesByMethod(state, props),
});

const mapDispatchToProps = {
  fetchModulesBy: fetchModulesByMethod,
};

export const ConnectedModuleSelect = connect(mapStateToProps, mapDispatchToProps)(ModuleSelect);

export default ConnectedModuleSelect;
