import { createSelector } from 'reselect';
import { getCurrentPublishingHouseId } from './context';

const StudioGroupMap = new Map([
  ['VLAANDEREN', ['SO', 'VO']],
  ['WALLONIË', ['ES']],
  ['BASIS', ['BAO']],
]);

const getPublishingHouseIds = state => state.publishingHouse.ids;
const getPublishingHouseEntities = state => state.publishingHouse.entities;

export const getCurrentPublishingHouse = createSelector(
  getPublishingHouseEntities,
  getCurrentPublishingHouseId,
  (entities, id) => entities?.[id],
);

export const getPublishingHousesInOrder = createSelector(
  getPublishingHouseIds,
  getPublishingHouseEntities,
  (ids, entities) => {
    if (!ids || !entities) return [];

    return ids.map(id => entities[id]).filter(ph => !!ph);
  },
);

export const getPublishingHousesByGroup = createSelector(getPublishingHousesInOrder, publishingHouses =>
  Array.from(StudioGroupMap.keys()).reduce((groups, group) => {
    const houses = (publishingHouses || []).filter(h => StudioGroupMap.get(group).includes(h.group));

    if (houses.length) groups.push({ name: group, publishingHouses: houses });
    return groups;
  }, []),
);
