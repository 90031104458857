import get from 'get-value';
import { createSelector } from 'reselect';
import { getRoles } from './authentication';
import Role from '../constants/roles';

const getEntities = state => state.digibook.entities;

export const getLastAdded = state => get(state, ['digibook', 'lastAdded']);

const getDigibookIdsByModule = (state, { moduleId }) => get(state, ['digibook', 'byModule', moduleId, 'ids']);

export const getDigibookById = (state, digibookId) => get(state, ['digibook', 'entities', digibookId]);

export const editDigibookEnabled = (state, { appPublishingState }) => {
  const roles = getRoles(state);

  if (roles.includes(Role.PRODUCTION) || roles.includes(Role.SYSADMIN)) return true;

  return roles.includes(Role.EXTERNAL) && (!appPublishingState || appPublishingState === 'draft');
};

const getDigibooksByModule = createSelector(getDigibookIdsByModule, getEntities, (ids, entities) => {
  if (!ids || !entities) return [];
  return ids.map(id => entities[id]).filter(item => !!item);
});

export default getDigibooksByModule;
