import get from 'get-value';
import { createSelector } from 'reselect';

const getEntities = state => state.subject.entities;

const getSubjectIdsByPublishingHouse = (state, { publishingHouseId }) =>
  get(state, ['subject', 'byPublishingHouse', publishingHouseId, 'ids']);

const getSubjectsByPublishingHouse = createSelector(getSubjectIdsByPublishingHouse, getEntities, (ids, entities) => {
  if (!ids || !entities) return [];
  return ids.map(id => entities[id]).filter(item => !!item);
});

export default getSubjectsByPublishingHouse;
