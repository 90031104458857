export function forEachNodeRecursive(nodes, fn, parentNodes = []) {
  if (nodes) {
    nodes.forEach(node => {
      const keepTraversing = fn(node, parentNodes);
      if (keepTraversing) forEachNodeRecursive(node.nodes, fn, [...parentNodes, node]);
    });
  }
}

export function findNodeById(toc, nodeId) {
  if (!toc) return undefined;
  let nodeInfo;

  forEachNodeRecursive(toc.nodes, n => {
    if (n.id === nodeId) {
      nodeInfo = n;
      return false;
    }

    return true;
  });

  return nodeInfo;
}
