import React from 'react';
import { object, number, bool, shape, string, oneOfType } from 'prop-types';
import { Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';
import Page from './page';

const useStyles = makeStyles(() => ({
  pdf: {
    display: 'flex',
  },
}));

export function PageWrapper(props) {
  const {
    mainPdf,
    page,
    rightPage,
    scale,
    viewMode,
    transparent,
    coverPdf,
    backCoverPdf,
    totalPages,
    coordinates,
    manualPage,
  } = props;

  const classes = useStyles();

  let style;

  if (transparent) style = { position: 'absolute', top: 0, left: 0 };

  if (coordinates) style = { position: 'absolute', ...coordinates };

  const backCoverPage = totalPages + 1;

  return (
    <div className={classes.pdf} style={style}>
      {
        // cover
        coverPdf && page === 0 && (
          <Page page={1} scale={scale} pdf={coverPdf} transparent={transparent} setViewportOnRender={!manualPage} />
        )
      }
      {page === 0 && !coverPdf && !transparent && (
        <Typography className={classes.notFound} variant="h5">
          No cover uploaded...
        </Typography>
      )}
      {
        // cover sheet
        page === 1 && (
          <Page page={1} scale={scale} pdf={mainPdf} transparent={transparent} setViewportOnRender={!manualPage} />
        )
      }
      {
        // left page
        page > 1 && page < backCoverPage && (
          <Page page={page} scale={scale} pdf={mainPdf} transparent={transparent} setViewportOnRender={!manualPage} />
        )
      }
      {
        // right page
        (page > 1 || rightPage !== undefined) &&
          rightPage !== null &&
          page < totalPages &&
          page < backCoverPage &&
          viewMode === 2 && (
            <Page
              page={rightPage || page + 1}
              scale={scale}
              pdf={mainPdf}
              transparent={transparent}
              setViewportOnRender={!manualPage}
            />
          )
      }
      {
        // back cover
        backCoverPdf && page === backCoverPage && (
          <Page page={1} scale={scale} pdf={backCoverPdf} transparent={transparent} setViewportOnRender={!manualPage} />
        )
      }
      {page === totalPages + 1 && !transparent && !backCoverPdf && (
        <Typography className={classes.notFound} variant="h5">
          No backcover uploaded...
        </Typography>
      )}
    </div>
  );
}

PageWrapper.propTypes = {
  mainPdf: object.isRequired,
  coverPdf: object,
  backCoverPdf: object,
  page: number,
  rightPage: number,
  scale: number,
  viewMode: number,
  totalPages: number,
  transparent: bool,
  coordinates: shape({
    top: oneOfType([string, number]),
    left: oneOfType([string, number]),
  }),
  manualPage: bool,
};

PageWrapper.defaultProps = {
  page: 1,
  rightPage: undefined,
  scale: 1,
  viewMode: 2,
  totalPages: 2,
  transparent: false,
  coverPdf: undefined,
  backCoverPdf: undefined,
  coordinates: undefined,
  manualPage: false,
};

export default PageWrapper;
