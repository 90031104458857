import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { IconButton } from '@mui/material';

import { Save as SaveIcon } from '@mui/icons-material';
import { isAnyUploading } from '../../../selectors/secured-file';

export function RemoteSubmitButton({ isUploading }) {
  const { submitForm } = useFormikContext();

  return (
    <IconButton color="inherit" onClick={() => submitForm()} size="large" disabled={isUploading}>
      <SaveIcon />
    </IconButton>
  );
}

RemoteSubmitButton.propTypes = {
  isUploading: PropTypes.bool,
};

RemoteSubmitButton.defaultProps = {
  isUploading: false,
};

const mapStateToProps = (state, { name }) => ({
  isUploading: isAnyUploading(state, name),
});

const ConnectedRemoteSubmitButton = connect(mapStateToProps)(RemoteSubmitButton);

export default ConnectedRemoteSubmitButton;
