import get from 'get-value';
import { createSelector } from 'reselect';
import { forEachNodeRecursive } from '../utils/table-of-content-utils';
import TOCConstants from '../constants/table-of-content';

export function getTocByModuleId(state, moduleId) {
  return get(state, ['tableOfContent', 'byModule', moduleId]);
}

export const getTocNodes = createSelector(getTocByModuleId, toc => {
  let tocNodes;
  if (toc) {
    tocNodes = [];
    tocNodes.push({
      name: 'Algemeen',
      id: TOCConstants.GENERAL_NODE_ID,
      level: 0,
    });

    forEachNodeRecursive(toc.nodes, (node, parents) => {
      tocNodes.push({
        name: node.name,
        id: node.id,
        level: parents.length,
      });

      return true;
    });
  }

  return tocNodes;
});

export const getTocNodesWithPageMapping = (state, moduleId) => {
  const tocNodes = getTocNodes(state, moduleId);
  const pageRanges = get(state, ['codexEditor', 'initial', 'pageRanges']);
  if (!tocNodes || !pageRanges) return [];

  return tocNodes.map(node => {
    const pageRangeForNode = pageRanges.find(range => range.nodeId === node.id);
    if (!pageRangeForNode) return node;

    return {
      ...node,
      from: pageRangeForNode.from,
      to: pageRangeForNode.to,
    };
  });
};

export const getNodeNamesByIds = (state, moduleId, nodeIds) => {
  const toc = getTocByModuleId(state, moduleId);
  const nodeNames = [];
  if (toc && nodeIds) {
    const allFound = () => nodeNames.length === nodeIds.length;

    if (nodeIds.includes(TOCConstants.GENERAL_NODE_ID)) {
      nodeNames.push('Algemeen');
    }

    forEachNodeRecursive(toc.nodes, node => {
      if (nodeIds.includes(node.id)) {
        nodeNames.push(node.name);
      }

      return !allFound();
    });
  }

  return nodeNames;
};
