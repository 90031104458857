import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import productionalStateMap from '../constants/productional-state';

const useStyles = makeStyles(() => ({
  pill: {
    padding: `${useTheme().spacing()} ${useTheme().spacing(2)}`,
    borderRadius: 5,
    textAlign: 'center',
    color: 'white',
    width: useTheme().typography.pxToRem(175),
    display: 'inline-block',
    fontSize: useTheme().typography.pxToRem(12),
  },
  red: {
    background: 'red',
  },
  orange: {
    background: '#f2a500',
  },
  green: {
    background: '#07dd00',
  },
}));

export function ProductionalState(props) {
  const { productionalState } = props;
  const classes = useStyles();

  const productionalStateDefinition = productionalStateMap[productionalState];

  return productionalStateDefinition ? (
    <span className={classNames(classes.pill, classes[productionalStateDefinition.color])}>
      {productionalStateDefinition.label}
    </span>
  ) : null;
}

ProductionalState.propTypes = {
  productionalState: PropTypes.number.isRequired,
};

export default ProductionalState;
