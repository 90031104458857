import React, { PropsWithChildren } from 'react';

import { AppBar, Toolbar, Typography } from '@mui/material';

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import { tss } from 'tss-react/mui';
import LinkIconButton from '../buttons/link-icon-button';

const useStyles = tss.create(() => ({
  action: {
    display: 'flex',
    flexPosition: 'right',
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    marginTop: '64px',
  },
  goBack: {
    marginRight: 16,
  },
}));

type Props = PropsWithChildren<{
  title?: string;
  previousPage?: string;
  actionElement?: JSX.Element;
}>;

export function DetailView({ title, children, previousPage, actionElement }: Props) {
  const { classes } = useStyles();

  return (
    <div>
      <AppBar>
        <Toolbar>
          <LinkIconButton to={previousPage} buttonProps={{ color: 'inherit', className: classes.goBack }}>
            <ArrowBackIcon />
          </LinkIconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            {title}
          </Typography>

          {actionElement && (
            <div data-testid="action" className={classes.action}>
              {actionElement}
            </div>
          )}
        </Toolbar>
      </AppBar>
      <main className={classes.root}>{children}</main>
    </div>
  );
}

export default DetailView;
