import * as Yup from 'yup';

const ignoreEmptyStringNumber = Yup.number().transform(value => (Number.isNaN(value) ? undefined : value));

export default maxPages =>
  Yup.object().shape({
    name: Yup.string().required('Required'),
    productionalState: Yup.number().required('Required'),
    bookLayer: Yup.string().nullable(true),
    answerLayer: Yup.string().nullable(true),
    disableAnswerLayerBlend: Yup.boolean(),
    solutionsIncluded: Yup.boolean(),
    pageRanges: Yup.array().of(
      Yup.object().shape({
        from: ignoreEmptyStringNumber
          .min(0, '"From" should be greater than or equal to 0')
          .max(maxPages, '"From" should be smaller than or equal to total amount of book layer pages'),
        to: ignoreEmptyStringNumber
          .min(0, '"Pages to" should be greater than or equal to 0')
          .when('from', (other, schema) =>
            other
              ? schema
                  .min(other, '"From" has to be smaller than "to"')
                  .required('"Pages to" is required when "from" is specified')
              : schema,
          )
          .max(maxPages, '"Pages to" should be smaller than or equal to total amount of book layer pages'),
        keepMediaVisibleTo: ignoreEmptyStringNumber
          .min(0, '"Media to" should be greater than or equal to 0')
          .when('from', (other, schema) =>
            other
              ? schema
                  .min(other, '"From" has to be smaller than "to"')
                  .required('"Media to" is required when "from" is specified')
              : schema,
          )
          .max(maxPages, '"Media to" should be smaller than or equal to total amount of book layer pages'),
        manualFrom: ignoreEmptyStringNumber
          .min(0, '"Manual from" should be greater than or equal to 0')
          .max(maxPages, '"Manual from" should be smaller than or equal to total amount of book layer pages'),
      }),
    ),
    enableManual: Yup.boolean(),
  });
