/* eslint-disable @typescript-eslint/no-unused-vars */
export type ExtractParams<T extends string> = `/${T}` extends `${infer Before}:${infer Param}/${infer Rest}`
  ? Param | ExtractParams<`/${Rest}`>
  : `${T}` extends `${infer Path}:${infer Param}`
    ? Param
    : never;

export function buildRequestPath<PathTemplate extends string>(
  pathTemplate: PathTemplate,
  params: Record<ExtractParams<PathTemplate>, string>,
) {
  return Object.entries<string>(params).reduce<string>(
    (prev, [param, value]) => prev.replace(`:${param}`, value),
    pathTemplate,
  );
}
