import { fabric } from 'fabric';

export default class FabricService {
  constructor(id) {
    this.isDrawingArea = false;
    this.fabricCanvas = new fabric.Canvas(id, {
      uniScaleTransform: true,
    });
    this.RECTANGLE_FILL = '#FFF';
    this.MARKER_FILL = '#FFF';
    this.MARKER_RADIUS = 10;
    this.BORDER_WIDTH = 2;
  }

  initialize(width, height) {
    this.setDimensions(width, height);
  }

  destroy() {
    this.fabricCanvas.dispose();
  }

  freezeObjects() {
    this.fabricCanvas.forEachObject(obj => {
      obj.set({
        selectable: false,
        evented: false,
        draggable: false,
        hasControls: false,
        hasRotatingPoint: false,
        hoverCursor: 'pointer',
      });
    });
  }

  unFreezeObjects() {
    this.fabricCanvas.forEachObject(obj => {
      obj.set({
        selectable: true,
        evented: true,
        draggable: true,
        hasControls: true,
        hasRotatingPoint: true,
        hoverCursor: 'crosshair',
      });
    });
  }

  setIsDrawingArea(isDrawing) {
    if (isDrawing) {
      this.fabricCanvas.set({
        defaultCursor: 'crosshair',
      });
    } else {
      this.fabricCanvas.set({
        defaultCursor: 'default',
      });
    }
    this.isDrawingArea = isDrawing;
  }

  setDimensions(width, height) {
    this.fabricCanvas.setDimensions({
      width,
      height,
    });
  }

  addSelectionListeners(onAreaDrawn) {
    this.fabricCanvas.on('mouse:down', ({ e }) => {
      if (this.isDrawingArea) {
        const { x, y } = this.fabricCanvas.getPointer(e);
        this.dragStartPoint = new fabric.Point(Math.round(x), Math.round(y));
      }
    });

    this.fabricCanvas.on('mouse:up', ({ e }) => {
      if (this.isDrawingArea) {
        const { x, y } = this.fabricCanvas.getPointer(e);
        const end = new fabric.Point(Math.round(x), Math.round(y));

        const start = this.dragStartPoint;
        const top = Math.min(end.y, start.y);
        const left = Math.min(end.x, start.x);
        const offsetTop = Math.max(end.y, start.y);
        const offsetLeft = Math.max(end.x, start.x);

        const height = offsetTop - top;
        const width = offsetLeft - left;

        const shape = {
          top: top / this.fabricCanvas.height,
          left: left / this.fabricCanvas.width,
          width: width / this.fabricCanvas.width,
          height: height / this.fabricCanvas.height,
          angle: 0,
        };

        onAreaDrawn(shape);
      }
      this.dragStartPoint = undefined;
    });
  }

  createEventedRectanglesWithMarkers(shapes, setMoving, onAreaMoved, scaleX, scaleY, markerRadiusOffset = 0) {
    return shapes.reduce((objects, area, index) => {
      const areaToDraw = new fabric.Rect({
        ...area,
        fill: this.RECTANGLE_FILL,
        stroke: this.MARKER_FILL,
        strokeWidth: this.BORDER_WIDTH,
        strokeDashArray: [10, 5],
        index,
      });

      const markerCircle = new fabric.Circle({
        radius: this.MARKER_RADIUS + markerRadiusOffset,
        fill: this.MARKER_FILL,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      });

      const markerText = new fabric.Text(`${area.weight || index + 1}`, {
        fill: 'white',
        fontFamily: 'arial',
        fontSize: this.MARKER_RADIUS * 1.5,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      });

      const marker = new fabric.Group([markerCircle, markerText], this.calcMarkerPosition(areaToDraw));
      marker.set({
        objectCaching: false,
        selectable: false,
        evented: false,
        draggable: false,
        hasControls: false,
        hasRotatingPoint: false,
      });

      // https://github.com/fabricjs/fabric.js/wiki/Working-with-events
      areaToDraw.on({
        mousedown: () => setMoving(true),
        mouseup: () => setMoving(false),
        moving: opt => this.adjustMarker(opt.transform.target, marker),
        scaling: opt => this.adjustMarker(opt.transform.target, marker),
        rotating: opt => this.adjustMarker(opt.transform.target, marker),
        modified: opt =>
          onAreaMoved(FabricService.getRelativeCoordinatesForRectangle(opt.target, scaleX, scaleY), index),
      });

      objects.push(areaToDraw, marker);
      return objects;
    }, []);
  }

  static getRelativeCoordinatesForRectangle(area, scaleX, scaleY) {
    return {
      id: area.id,
      weight: area.weight,
      top: area.top / scaleY,
      left: area.left / scaleX,
      width: (area.width * area.scaleX) / scaleX, // we do not use the getScaledWidth/getScaledHeight because they take the border sizes into account. (which we dont want)
      height: (area.height * area.scaleY) / scaleY,
      angle: area.angle,
    };
  }

  static getAbsoluteCoordinatesForRectangle(area, scaleX, scaleY) {
    return {
      id: area.id,
      weight: area.weight,
      top: area.top * scaleY,
      left: area.left * scaleX,
      width: area.width * scaleX,
      height: area.height * scaleY,
      angle: area.angle,
    };
  }

  adjustMarker(changedLinkAreaShape, marker) {
    marker.set({
      ...this.calcMarkerPosition(changedLinkAreaShape),
    });
  }

  calcMarkerPosition = area => {
    // if enough space on the left of the box, draw it there, otherwise draw it on the right of the box
    const markerLeft =
      area.left < 2.5 * this.MARKER_RADIUS
        ? area.left + area.getScaledWidth() + this.MARKER_RADIUS
        : area.left - 3 * this.MARKER_RADIUS;
    const markerTop = area.top;
    return { top: markerTop, left: markerLeft };
  };

  removeAllObjects = () => {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects());
  };

  static normalizeShapeForSinglePage = shape => ({
    ...shape,
    left: shape.left / 2,
    width: shape.width / 2,
  });

  static cacheAndGroupSVG(imageUrl) {
    return new Promise((resolve, reject) => {
      fabric.loadSVGFromString(imageUrl, (objects, options) => {
        if (!objects) reject(new Error('Invalid path'));
        const groupedSvg = fabric.util.groupSVGElements(objects, options);

        resolve(groupedSvg);
      });
    });
  }
}
