import PublishingStates from '../constants/medialink-publishing-state';
import MediaLinkReviewState from '../constants/medialink-review-state';

type Keys = keyof typeof MediaLinkReviewState;
type MediaLinkReviewStateValue = (typeof MediaLinkReviewState)[Keys];

export const getPublishingStateForMediaLink = (reviewState: MediaLinkReviewStateValue, published: boolean) => {
  if (published) return PublishingStates.PUBLISHED;
  if (reviewState === MediaLinkReviewState.NOT_IN_REVIEW) return PublishingStates.UNPUBLISHED;
  return PublishingStates.PUBLISHED_FOR_REVIEW;
};
