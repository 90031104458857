import React from 'react';
import { arrayOf, string } from 'prop-types';
import { connect } from 'react-redux';

import { getNodeNamesByIds } from '../../../../../selectors/table-of-content';
import { getCurrentHierarchyModuleId } from '../../../../../selectors/context';

export function MediaLinkHierarchy({ hierarchyNames }) {
  return <>{hierarchyNames && hierarchyNames.join(', ')}</>;
}

const mapStateToProps = (state, ownProps) => ({
  hierarchyNames: getNodeNamesByIds(state, getCurrentHierarchyModuleId(state), ownProps.hierarchy),
});

MediaLinkHierarchy.propTypes = {
  hierarchy: arrayOf(string).isRequired, // eslint-disable-line react/no-unused-prop-types
  hierarchyNames: arrayOf(string).isRequired,
};

export default connect(mapStateToProps)(MediaLinkHierarchy);
