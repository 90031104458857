import pdfjs from 'pdfjs-dist/webpack';
import axios from 'axios';
import * as localForage from 'localforage';

async function storeFile(pdf, securedFileId) {
  const storedSize = await localForage.length();

  if (storedSize > 10) {
    const firstItem = await localForage.key(0);

    localForage.removeItem(firstItem);
  }

  await localForage.setItem(securedFileId, pdf.data);
}

const fetch = async (securedFileId, downloadUrl, onDownloadProgress) => {
  let pdf;

  pdf = await localForage.getItem(securedFileId);

  if (!pdf) {
    pdf = await axios.get(downloadUrl, {
      responseType: 'arraybuffer',
      onDownloadProgress,
    });

    try {
      await storeFile(pdf, securedFileId);
    } catch (e) {
      console.error('The loaded file could not be stored', e);
    }
  }

  return pdfjs.getDocument(pdf).promise;
};

export default {
  fetch,
};
