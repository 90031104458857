import { MethodActionType } from '../action-types';

export default function methodReducer(state = {}, action = {}) {
  switch (action && action.type) {
    case MethodActionType.FETCH_METHODS_BY_SUBJECT: {
      const { subjectId } = action.payload;
      const { entities, bySubject } = state;

      return {
        entities,
        bySubject: {
          ...bySubject,
          [subjectId]: {
            ...(bySubject || {})[subjectId],
            isFetching: true,
          },
        },
      };
    }
    case MethodActionType.FETCH_METHODS_BY_SUBJECT_SUCCESS: {
      const { subjectId, entities: methods, ids } = action.payload;
      const { entities, bySubject } = state;

      return {
        entities: {
          ...entities,
          ...methods,
        },
        bySubject: {
          ...bySubject,
          [subjectId]: {
            ids,
            lastUpdated: Date.now(),
          },
        },
      };
    }
    case MethodActionType.FETCH_METHODS_BY_SUBJECT_FAILURE: {
      const { subjectId } = action.payload;
      const { entities, bySubject = {} } = state;

      const { isFetching, ...rest } = bySubject[subjectId] || {};

      return {
        entities: {
          ...entities,
        },
        bySubject: {
          ...bySubject,
          [subjectId]: {
            ...rest,
          },
        },
      };
    }
    default:
      return state;
  }
}
