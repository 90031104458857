import React, { useEffect } from 'react';

import { Select } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { fetchTableOfContentFor } from '../../actions/table-of-content';
import { getTocNodes } from '../../selectors/table-of-content';
import { getCurrentTocNodeId } from '../../selectors/context';
import { clearCurrentTocNode, setCurrentTocNode } from '../../actions/context';

type TocNodeSelectProps = {
  moduleId: string;
};

export function TocNodeSelect({ moduleId }: TocNodeSelectProps) {
  const dispatch = useAppDispatch();
  // @ts-ignore
  const tocNodes = useAppSelector(state => getTocNodes(state, moduleId)) || [];
  const tocNodeId = useAppSelector(getCurrentTocNodeId);

  useEffect(() => {
    if (moduleId) {
      // @ts-ignore
      dispatch(fetchTableOfContentFor(moduleId));
    }
  }, [dispatch, moduleId]);

  const onChange = (newTocNodeId: string) =>
    dispatch(newTocNodeId === '' ? clearCurrentTocNode() : setCurrentTocNode(newTocNodeId));

  const generateDashString = (amount: number) => {
    return '\u2800'.repeat(amount);
  };

  return (
    <Select
      variant="standard"
      autoFocus
      id="toc-node-select"
      native
      onChange={e => onChange(e.target.value)}
      value={tocNodeId}
      inputProps={{
        'data-testid': 'toc-node-select',
      }}
    >
      <option value="" aria-label="empty option" />
      {tocNodes?.map(node => (
        <option value={node.id} key={`toc-${node.id}`} data-testid="toc-node-select-options">
          {generateDashString(node.level) + node.name}
        </option>
      ))}
    </Select>
  );
}
