import FabricService from './fabric-service';

export default class LinkAreaService extends FabricService {
  constructor(id) {
    super(id);
    this.RECTANGLE_FILL = 'rgba(240,20,20,0.15)';
    this.MARKER_FILL = 'rgb(240,20,20)';
  }

  drawLinkAreas(linkAreas, setMoving, onAreaMoved, isSinglePage) {
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects());

    const scaleX = isSinglePage ? this.fabricCanvas.width * 2 : this.fabricCanvas.width;

    const scaleY = this.fabricCanvas.height;

    const denormalizedLinkAreas = linkAreas.map(({ shape }) =>
      FabricService.getAbsoluteCoordinatesForRectangle(shape, scaleX, scaleY),
    );

    const objects = this.createEventedRectanglesWithMarkers(
      denormalizedLinkAreas,
      setMoving,
      onAreaMoved,
      scaleX,
      scaleY,
    );

    objects.forEach(obj => {
      if (obj.type === 'rect') {
        this.fabricCanvas.add(obj);
      } else {
        this.fabricCanvas.bringToFront(obj);
      }
    });

    if (this.isDrawingArea) {
      this.freezeObjects();
    }
  }
}
