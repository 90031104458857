import React from 'react';

type Context = {
  versionIdToCopy?: number;
  openCopyDialog: (versionId: number) => void;
  closeCopy: () => void;
};

export const CopyOverMethodsContext = React.createContext<Context>({
  versionIdToCopy: undefined,
  openCopyDialog: () => {}, // no-ops
  closeCopy: () => {}, // no-ops
});
