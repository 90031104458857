import get from 'get-value';

import api from '../services/api';
import { MediaLinkActionType } from '../action-types';
import tableOfContentConstants from '../constants/table-of-content';
import { getCurrentHierarchyModuleId } from '../selectors/context';

/**
 * Create FETCH_MEDIA_LINK_STARTED
 *
 * @param {string} moduleId
 * @param {string} nodeId
 */
const fetchMediaLinksStarted = (moduleId, nodeId) => ({
  type: MediaLinkActionType.FETCH_MEDIA_LINK_STARTED,
  payload: {
    moduleId,
    nodeId,
  },
});

/**
 * Create FETCH_MEDIA_LINK_SUCCESS
 *
 * @param {string} moduleId
 * @param {string} nodeId
 * @param {object[]} medialinks
 */
const fetchMediaLinksSuccess = (moduleId, nodeId, mediaLinks) => ({
  type: MediaLinkActionType.FETCH_MEDIA_LINK_SUCCESS,
  payload: {
    moduleId,
    nodeId,
    mediaLinks,
  },
});

/**
 * Create FETCH_MEDIA_LINK_FAILED
 *
 * @param {string} moduleId
 * @param {string} nodeId
 */
const fetchMediaLinksFailed = (moduleId, nodeId) => ({
  type: MediaLinkActionType.FETCH_MEDIA_LINK_FAILED,
  payload: {
    moduleId,
    nodeId,
  },
});

/**
 * Create FETCH_MEDIA_CONTENT_TYPE_FAILED
 *
 * @param {string} moduleId
 */
const fetchMediaContentTypesForModuleFailed = moduleId => ({
  type: MediaLinkActionType.FETCH_MEDIA_CONTENT_TYPE_FAILED,
  payload: {
    moduleId,
  },
});

/**
 * Create FETCH_MEDIA_CONTENT_TYPE_STARTED
 *
 * @param {string} moduleId
 */
const fetchMediaContentTypesForModuleStarted = moduleId => ({
  type: MediaLinkActionType.FETCH_MEDIA_CONTENT_TYPE_STARTED,
  payload: {
    moduleId,
  },
});

/**
 * Create FETCH_MEDIA_CONTENT_TYPE_SUCCESS,
 *
 * @param {string} moduleId
 * @param {object[]} contentTypes
 */
const fetchMediaContentTypesForModuleSuccess = (moduleId, contentTypes) => ({
  type: MediaLinkActionType.FETCH_MEDIA_CONTENT_TYPE_SUCCESS,
  payload: {
    moduleId,
    contentTypes,
  },
});

/**
 * Create FETCH_MEDIALINKS_FOR_LINKAREA_STARTED
 *
 * @param {string} linkAreaId
 */
const fetchMediaLinksForLinkAreaStarted = linkAreaId => ({
  type: MediaLinkActionType.FETCH_MEDIALINKS_FOR_LINKAREA_STARTED,
  payload: {
    linkAreaId,
  },
});

/**
 * Create FETCH_MEDIALINKS_FOR_LINKAREA_SUCCESS
 *
 * @param {string} linkAreaId
 * @param {object[]} mediaLinks
 */
const fetchMediaLinksForLinkAreaSuccess = (linkAreaId, mediaLinks) => ({
  type: MediaLinkActionType.FETCH_MEDIALINKS_FOR_LINKAREA_SUCCESS,
  payload: {
    linkAreaId,
    mediaLinks,
  },
});

/**
 * Create FETCH_MEDIALINKS_FOR_LINKAREA_FAILED
 *
 * @param {string} linkAreaId
 */
const fetchMediaLinksForLinkAreaFailed = linkAreaId => ({
  type: MediaLinkActionType.FETCH_MEDIALINKS_FOR_LINKAREA_FAILED,
  payload: {
    linkAreaId,
  },
});

export function forceFetchContentTypesForModule(moduleId) {
  return async dispatch => {
    dispatch(fetchMediaContentTypesForModuleStarted(moduleId));
    try {
      const {
        data: { data: contentTypes },
      } = await api.get(`/shell/media-content-types?moduleId=${moduleId}`);

      dispatch(fetchMediaContentTypesForModuleSuccess(moduleId, contentTypes));
    } catch (e) {
      dispatch(fetchMediaContentTypesForModuleFailed(moduleId));
      throw e;
    }
  };
}

export function fetchContentTypesForModule(moduleId) {
  return (dispatch, getState) => {
    const { isFetching, lastUpdated } = get(getState(), ['medialinks', 'contentTypes', 'byModule', moduleId]) || {};

    if (isFetching || lastUpdated) return Promise.resolve();

    return dispatch(forceFetchContentTypesForModule(moduleId));
  };
}

export function forceFetchMediaLinksForNode(moduleId, nodeId) {
  return async dispatch => {
    dispatch(fetchMediaLinksStarted(moduleId, nodeId));

    try {
      const {
        data: { data: mediaLinks },
      } = await api.get(`/shell/modules/${moduleId}/table-of-content/${nodeId}/medialinks`);
      dispatch(fetchMediaLinksSuccess(moduleId, nodeId, mediaLinks));

      await dispatch(fetchContentTypesForModule(moduleId));
    } catch (e) {
      dispatch(fetchMediaLinksFailed(moduleId, nodeId));
      throw e;
    }
  };
}

export function fetchMediaLinksForNode(moduleId, nodeId) {
  return (dispatch, getState) => {
    const id = nodeId === tableOfContentConstants.GENERAL_NODE_ID ? moduleId : nodeId;
    const { isFetching, lastUpdated } = get(getState(), ['medialinks', 'byNode', id]) || {};

    if (isFetching || lastUpdated) return Promise.resolve();

    return dispatch(forceFetchMediaLinksForNode(moduleId, nodeId));
  };
}

export function forceFetchMediaLinksForLinkArea(linkAreaId) {
  return async (dispatch, getState) => {
    dispatch(fetchMediaLinksForLinkAreaStarted(linkAreaId));
    const currentDigibook = get(getState(), ['codexEditor', 'initial', 'id']);
    const module = getCurrentHierarchyModuleId(getState());
    try {
      const {
        data: { data: mediaLinks },
      } = await api.get(`/shell/digibooks/${currentDigibook}/linkAreas/${linkAreaId}/medialinks`);
      dispatch(fetchMediaLinksForLinkAreaSuccess(linkAreaId, mediaLinks));

      await dispatch(fetchContentTypesForModule(module));
    } catch (e) {
      dispatch(fetchMediaLinksForLinkAreaFailed(linkAreaId));
      throw e;
    }
  };
}

export function fetchMediaLinksForLinkArea(linkAreaId) {
  return async (dispatch, getState) => {
    const { isFetching, lastUpdated } = get(getState(), ['medialinks', 'byLinkArea', linkAreaId]) || {};
    if (isFetching || lastUpdated) return;

    await dispatch(forceFetchMediaLinksForLinkArea(linkAreaId));
  };
}
