import React from 'react';
import { ListItem, ListItemIcon, Badge } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { object, func, bool, number } from 'prop-types';

import { green } from '@mui/material/colors';
import AnswerSetIcon from '../../../../../assets/images/answer-set.svg';

const styles = {
  row: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
  },
  svg: {
    width: 20,
    height: 20,
    fill: green[500],
  },
  badge: {
    top: 20,
    border: '2px solid #fff',
    background: green[800],
    color: '#fff',
  },
};

export function DigibookAnswerSet(props) {
  const { handleClick, classes, selected, index } = props;
  return (
    <ListItem onClick={handleClick} selected={selected} divider button>
      <ListItemIcon>
        <Badge badgeContent={index + 1} classes={{ badge: classes.badge }} overlap="rectangular">
          <AnswerSetIcon className={classes.svg} />
        </Badge>
      </ListItemIcon>
    </ListItem>
  );
}

DigibookAnswerSet.propTypes = {
  handleClick: func.isRequired,
  classes: object,
  selected: bool,
  index: number,
};

DigibookAnswerSet.defaultProps = {
  classes: {},
  selected: false,
  index: 0,
};

export default withStyles(styles)(DigibookAnswerSet);
