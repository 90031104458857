import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useContext } from 'react';
import SchoolYearContext from '../../../contexts/school-year-context';
import SchoolYearRow from './school-year-row';

const SchoolYearList = (): JSX.Element => {
  const [editedSchoolYear, setEditedSchoolYear] = React.useState<number | null>(null);
  const { schoolYears } = useContext(SchoolYearContext);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>School year</TableCell>
          <TableCell>Start date</TableCell>
          <TableCell>End date</TableCell>
          <TableCell>Active</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {schoolYears.map(schoolYear => (
          <SchoolYearRow
            key={schoolYear.id}
            schoolYear={schoolYear}
            edited={schoolYear.id === editedSchoolYear}
            onEdit={id => setEditedSchoolYear(id)}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default SchoolYearList;
