import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  Grid,
  FormLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import linkAreaShape from '../../../proptype-shapes/link-area';
import LinkAreaDigibookLinker from './link-area-digibook-linker';
import LinkAreaMedialinksLinker from './linkareaDetails/medialinks/link-area-media-link-linker';
import LinkAreaDisplayOptions from './link-area-display-options';

const useStyles = makeStyles(() => ({
  flex: {
    flex: 1,
  },
  mainContent: {
    marginTop: '64px',
    padding: useTheme().spacing(2),
  },
  textField: {
    display: 'block',
    marginTop: useTheme().spacing(),
    width: '100%',
  },
  formLabel: {
    marginTop: useTheme().spacing(2),
  },
}));

export function RenderedDigibookLinkArea({ handleClose, values, submitForm, setFieldValue, errors, isValid }) {
  const classes = useStyles();
  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close" size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            {`Codex Editor - ${values.name}`}
          </Typography>
          <Button color="inherit" onClick={() => isValid && submitForm() && handleClose()}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.mainContent}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              name="name"
              value={values.name || ''}
              label="Name"
              fullWidth
              required
              onChange={e => setFieldValue(e.target.name, e.target.value)}
              className={classes.textField}
              error={!!errors.name}
            />
            {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}

            <LinkAreaDisplayOptions setFieldValue={setFieldValue} values={values.displayOptions} />

            <FormGroup>
              <FormLabel component="legend" className={classes.formLabel}>
                Target
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue(e.target.name, 'url');
                      setFieldValue('url', '');
                      setFieldValue('mediaLinks', undefined);
                      setFieldValue('digibookLink', undefined);
                    } else {
                      setFieldValue(e.target.name, undefined);
                    }
                  }}
                  checked={values.linkType === 'url'}
                  control={<Checkbox name="linkType" />}
                  label="Url"
                />
                <FormControlLabel
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue(e.target.name, 'digibook');
                      setFieldValue('url', undefined);
                      setFieldValue('mediaLinks', undefined);
                      setFieldValue('digibookLink', {
                        digibook: values.digibook,
                      });
                    } else {
                      setFieldValue(e.target.name, undefined);
                    }
                  }}
                  checked={values.linkType === 'digibook'}
                  control={<Checkbox name="linkType" />}
                  label="Digibook"
                />
                <FormControlLabel
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue(e.target.name, 'medialinks');
                      setFieldValue('url', undefined);
                      setFieldValue('mediaLinks', []);
                      setFieldValue('digibookLink', undefined);
                    } else {
                      setFieldValue(e.target.name, undefined);
                    }
                  }}
                  checked={values.linkType === 'medialinks'}
                  control={<Checkbox name="linkType" />}
                  label="Media Links"
                />
              </FormGroup>
            </FormGroup>
            {values.linkType === 'url' && (
              <TextField
                variant="standard"
                name="url"
                value={values.url || ''}
                label="Url"
                fullWidth
                disabled={!values.linkType}
                error={!!errors.url}
                onChange={e => setFieldValue(e.target.name, e.target.value)}
                className={classes.textField}
              />
            )}
            {errors.url && <FormHelperText error>{errors.url}</FormHelperText>}
            {values.linkType === 'digibook' && (
              <LinkAreaDigibookLinker
                errors={errors.digibookLink}
                digibookLink={values.digibookLink}
                setFieldValue={setFieldValue}
              />
            )}
          </Grid>
          {values.linkType === 'medialinks' && (
            <Grid item xs={12}>
              <LinkAreaMedialinksLinker
                linkAreaId={values.id}
                mediaLinks={values.mediaLinks}
                error={errors.mediaLinks}
                setFieldValue={setFieldValue}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

RenderedDigibookLinkArea.propTypes = {
  handleClose: PropTypes.func.isRequired,
  values: linkAreaShape.isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default RenderedDigibookLinkArea;
