import React, { useContext, useState, useEffect } from 'react';
import { tss } from 'tss-react/mui';

import { useParams } from 'react-router';
import { Alert } from '@mui/material';
import Upload from '../../components/forms/formik/form-upload-field';
import { clearFileById, uploadFileForDigislide } from '../../actions/secured-file';
import { useAppDispatch } from '../../hooks/store';
import TextEditor from './components/text-editor';

import { getSecuredFile, deleteSourceFileOfSlide } from './api';
import { SlideEditorParams } from './utils';
import Preview from './components/preview';
import SlideEditorContext from './context/SlideEditorContext';
import ActiveAreas from './components/active-areas';

const useStyles = tss.create(({ theme }) => ({
  container: {
    padding: '1rem',
    display: 'grid',
    gridTemplateAreas: "'config slide' 'text text'",
    gridTemplateColumns: '1fr 2fr',
    gridTemplateRows: '4fr 1fr',
    height: 'calc(100vh - 64px - 2 * 1rem)',
  },
  config: {
    gridArea: 'config',
    overflowY: 'auto',
  },
  slide: {
    gridArea: 'slide',
    padding: theme.spacing(1),
  },
  text: {
    gridArea: 'text',
  },
}));

function SlideEditor() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const params = useParams<SlideEditorParams>() as Required<SlideEditorParams>;

  const { slideInfo, fetchSlideInfo, updateSlideInfo } = useContext(SlideEditorContext);

  const [form, setForm] = useState({
    slideSourceId: slideInfo?.slideSourceId || '',
    revealSourceId: slideInfo?.revealSourceId || '',
  });

  useEffect(() => {
    setForm({
      slideSourceId: slideInfo?.slideSourceMongoId || '',
      revealSourceId: slideInfo?.revealSourceMongoId || '',
    });
  }, [slideInfo]);

  const handleSetUploadFieldValue = (name: 'slideSourceId' | 'revealSourceId', value: string) => {
    setForm({ ...form, [name]: value });
  };

  function buildUploadHandle(sourceType: 'slide' | 'reveal') {
    const { linkId, slideId, setId, ...paramsToCast } = params;

    const castedParams = {
      ...paramsToCast,
      slideSetId: Number(setId),
      versionId: Number(params.versionId),
    };

    return async (file: File) => {
      if (!slideInfo) return;

      await dispatch(
        uploadFileForDigislide(
          {
            type: 'single',
            linkId: Number(linkId),
            slideId: Number(slideId),
            [sourceType]: file,
          },
          castedParams,
          'slide-editor',
          (id: string) => setForm(f => ({ ...f, [`${sourceType}SourceId`]: id })),
        ),
      );

      // Refetch to retrieve signed url
      fetchSlideInfo();
    };
  }

  const handleDownload = async (sourceType: 'slide' | 'reveal') => {
    const securedFileId = sourceType === 'slide' ? slideInfo?.slideSourceMongoId : slideInfo?.revealSourceMongoId;
    if (!securedFileId) return;
    const { downloadUrl } = await getSecuredFile(securedFileId);
    window.open(downloadUrl, '_blank');
  };

  const clearSourceFile = async (sourceType: 'slide' | 'reveal') => {
    const securedFileToDelete = sourceType === 'slide' ? slideInfo?.slideSourceId : slideInfo?.revealSourceId;
    if (!securedFileToDelete) return;

    await deleteSourceFileOfSlide({ ...params, securedFileId: securedFileToDelete });

    dispatch(clearFileById(sourceType === 'slide' ? slideInfo?.slideSourceMongoId : slideInfo?.revealSourceMongoId));

    fetchSlideInfo();
  };

  const handlePreviewTextChange = (newText: string) => {
    updateSlideInfo({ previewText: newText });
  };

  return (
    <div className={classes.container}>
      <div className={classes.config}>
        <Alert severity="warning">Only upload PDF files with 1 page.</Alert>
        <Upload
          name="slide"
          displayName="slide-source-pdf"
          label="Slide source"
          accept="application/pdf"
          value={form.slideSourceId}
          upload={buildUploadHandle('slide')}
          onClear={clearSourceFile}
          setFieldValue={handleSetUploadFieldValue}
          form="slide-editor"
          onDownload={() => handleDownload('slide')}
        />
        <Upload
          name="reveal"
          displayName="reveal-source-pdf"
          label="Reveal source"
          accept="application/pdf"
          value={form.revealSourceId}
          setFieldValue={handleSetUploadFieldValue}
          upload={buildUploadHandle('reveal')}
          onClear={clearSourceFile}
          form="slide-editor"
          onDownload={() => handleDownload('reveal')}
        />
        <ActiveAreas />
      </div>
      <div className={classes.slide}>
        {slideInfo && <Preview slideUrl={slideInfo.slideSourceUrl} revealUrl={slideInfo.revealSourceUrl} />}
      </div>
      <div className={classes.text} data-testid="text-editor">
        {slideInfo && <TextEditor previewText={slideInfo.previewText} onPreviewTextChange={handlePreviewTextChange} />}
      </div>
    </div>
  );
}

export default SlideEditor;
