import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { tss } from 'tss-react/mui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Button } from '@mui/material';

import { SlideSetVersionType, postSlideGroup } from '../../api/slideSet';

import Group from './components/group';
import useGroupActions from './hooks/use-group-actions';
import { useSlidesetVersionGroups } from './context/slideset-version-groups-context';

const useStyles = tss.create(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box',
    },
    padding: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    padding: '1rem 0',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  groupDropzone: {
    height: '8rem',
    flexGrow: 1,
    border: '1px dashed black',
  },
  groupSlides: {
    paddingLeft: '2rem',
  },
  slide: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 8,
    '& img': {
      width: '12rem',
    },
  },
  textArea: {
    height: '100%',
    width: '100%',
    minWidth: '20rem',
    margin: 0,
    border: '1px solid #ABABAB',
    backgroundColor: '#F0F0F0',
  },
  textContainer: {
    flexGrow: 1,
  },
}));

export default function SlidesetVersionDetail() {
  const { setId, versionId, nodeId, moduleId } = useParams();
  const { groups, fetchGroups } = useSlidesetVersionGroups();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { addSlideToGroup, moveGroupSlideToSingleGroup, reorderGroupSlides, reorderGroups } = useGroupActions({
    fetchData: fetchGroups,
    groups,
  });

  const createGroup = async (type: SlideSetVersionType) => {
    if (!moduleId || !nodeId || !setId || !versionId) return null;

    const { linkId, slideId } = await postSlideGroup(moduleId, nodeId, setId, versionId, type);

    if (slideId) return navigate(`groups/${linkId}/slides/${slideId}`);

    return fetchGroups();
  };

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return (
    <div data-testid="slide-version" className={classes.container}>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={() => createGroup('SINGLE')}>
          Add slide
        </Button>
        <Button variant="contained" color="primary" onClick={() => navigate('import')}>
          Import slides from pdf
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            navigate({
              pathname: 'import',
              search: createSearchParams({ replace: 'true' }).toString(),
            })
          }
        >
          Replace all slides from pdf
        </Button>
        <Button variant="contained" color="primary" onClick={() => createGroup('SEQUENTIAL')}>
          Add sequential slide group
        </Button>
        <Button variant="contained" color="primary" onClick={() => createGroup('NONSEQUENTIAL')}>
          Add non-sequential slide group
        </Button>
      </div>
      <div role="list">
        <DndProvider backend={HTML5Backend}>
          {groups.map((group, groupIndex) => (
            <Group
              key={`group-${group.linkId}`}
              group={group}
              groupIndex={groupIndex}
              addSlideToGroup={addSlideToGroup}
              reorderGroupSlides={reorderGroupSlides}
              reorderGroups={reorderGroups}
              moveGroupSlideToSingleGroup={moveGroupSlideToSingleGroup}
              refetchData={fetchGroups}
            />
          ))}
        </DndProvider>
      </div>
    </div>
  );
}
