import get from 'get-value';
import { createSelector } from 'reselect';

export const getInitialState = state => get(state, ['codexEditor', 'initial']);

export const getPendingState = state => get(state, ['codexEditor', 'isPending']);

export const getActiveTab = state => get(state, ['codexEditor', 'activeTab']);

export const getIsObjectMoving = state => get(state, ['codexEditor', 'isObjectMoving']);

export const getFileForLayer = (state, layer) => get(state, ['codexEditor', 'files', layer]);

export const getLinkAreasForCurrentBook = state => get(state, ['codexEditor', 'linkAreas', 'areas']);

export const getAnswerSetsForCurrentBook = state => get(state, ['codexEditor', 'answerSets', 'sets']);

export const getManualMappingForCurrentBook = state => get(state, ['codexEditor', 'manualMapping']);

export const getIsDrawing = state => get(state, ['codexEditor', 'isDrawing']);

export const getCurrentPage = state => get(state, ['codexEditor', 'pagination', 'currentPage']);

export const getCurrentNodeForMediaContext = state => get(state, ['codexEditor', 'currentNode']);

export const getViewMode = state => get(state, ['codexEditor', 'pagination', 'viewMode']);

export const getTotalPages = state => get(state, ['codexEditor', 'pagination', 'totalPages']);

export const getViewport = state => get(state, ['codexEditor', 'viewport']);

export const getSelectedAnswerSetId = state => get(state, ['codexEditor', 'answerSets', 'currentSet']);

export const getSelectedAnswer = state => get(state, ['codexEditor', 'answerSets', 'currentAnswer']);

export const getCurrentPagesForView = createSelector(
  getViewMode,
  getCurrentPage,
  getTotalPages,
  (amountOfShownPages, currentPage, totalPages) => {
    const isCover = currentPage === 0;
    const isBackCover = currentPage === totalPages + 1;
    const showLastPageInSingle = currentPage === totalPages && totalPages % 2 === 0;

    if (!amountOfShownPages || (!isCover && !currentPage) || !totalPages) return undefined;

    if (isCover || currentPage === 1 || isBackCover || showLastPageInSingle) return [currentPage];

    return amountOfShownPages === 2 ? [currentPage, currentPage + 1] : [currentPage];
  },
);

export const getLinkAreasForCurrentPages = createSelector(
  getLinkAreasForCurrentBook,
  getCurrentPagesForView,
  (linkAreas, currentPages) =>
    (linkAreas && currentPages && linkAreas.filter(area => area.pages.some(page => currentPages.includes(page)))) || [],
);

export const getAnswerSetsForCurrentPages = createSelector(
  getAnswerSetsForCurrentBook,
  getCurrentPagesForView,
  (answerSets, currentPages) => {
    const sets = Object.values(answerSets || {});

    if (sets.length > 0 && currentPages) {
      return sets.filter(set => set.pages.some(page => currentPages.includes(page)));
    }
    return [];
  },
);
