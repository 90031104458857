import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useManualMapping(totalManualPages) {
  const {
    manualMapping = [],
    initial: { totalPages },
  } = useSelector(s => s.codexEditor);

  const { bookPagesToExclude, manualPagesToExclude } = manualMapping.reduce(
    (acc, mapping) => {
      if (mapping.excludeFrom === 'manual') acc.manualPagesToExclude.push(mapping);

      if (mapping.excludeFrom === 'book') acc.bookPagesToExclude.push(mapping);

      return acc;
    },
    { bookPagesToExclude: [], manualPagesToExclude: [] },
  );

  const bookPageNumbers = new Array(totalPages)
    .fill()
    .map((_, i) => i + 1)
    .filter(pageNumber => !bookPagesToExclude.some(range => pageNumber >= range.from && pageNumber <= range.to));

  const manualPageNumbers = new Array(totalManualPages)
    .fill()
    .map((_, i) => i + 1)
    .filter(pageNumber => !manualPagesToExclude.some(range => pageNumber >= range.from && pageNumber <= range.to));

  const mappedPages = useMemo(
    () =>
      bookPageNumbers.reduce(
        (acc, bookPageNumber, index) => ({
          ...acc,
          [bookPageNumber]: manualPageNumbers[index],
        }),
        {},
      ),
    [bookPageNumbers, manualPageNumbers],
  );

  return mappedPages;
}
