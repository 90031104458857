import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { fetchSecuredFileById } from '../../../actions/secured-file';
import { useAppDispatch } from '../../../hooks/store';
import { ActiveArea, getActiveAreas, getSlideInfo, SlideInfo } from '../api';
import { SlideEditorParams } from '../utils';

export type SlideEditorContextValue = {
  slideInfo?: SlideInfo;
  activeAreas: ActiveArea[];
  isDrawing: boolean;
  setIsDrawing: React.Dispatch<React.SetStateAction<boolean>>;
  fetchSlideInfo: () => Promise<void>;
  fetchActiveAreas: () => Promise<void>;
  updateSlideInfo: (newInfo: SlideInfo) => void;
};

export const initialValues: SlideEditorContextValue = {
  activeAreas: [],
  isDrawing: false,
  fetchActiveAreas: async () => {},
  fetchSlideInfo: async () => {},
  setIsDrawing: () => {},
  updateSlideInfo: () => {},
};

const SlideEditorContext = createContext<SlideEditorContextValue>(initialValues);

export default SlideEditorContext;

export const SlideEditorContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams<SlideEditorParams>() as Required<SlideEditorParams>;

  const [activeAreas, setActiveAreas] = useState<ActiveArea[]>([]);
  const [slideInfo, setSlideInfo] = useState<SlideInfo>();
  const [isDrawing, setIsDrawing] = useState(false);

  const fetchActiveAreas = useCallback(async () => {
    const data = await getActiveAreas(params);

    setActiveAreas(data);
  }, [params]);

  const fetchSlideInfo = useCallback(async () => {
    const data = await getSlideInfo(params);

    // Fetch secured file and add to redux.
    await Promise.all(
      [data.slideSourceMongoId, data.revealSourceMongoId].filter(Boolean).map(async id => {
        dispatch(await fetchSecuredFileById(id));
      }),
    );

    setSlideInfo(data);
  }, [dispatch, params]);

  useEffect(() => {
    fetchSlideInfo();
    fetchActiveAreas();
  }, [fetchSlideInfo, fetchActiveAreas]);

  const updateSlideInfo = useCallback((newInfo: SlideInfo) => {
    setSlideInfo(prev => ({ ...prev, ...newInfo }));
  }, []);

  const values = useMemo(
    () => ({
      slideInfo,
      activeAreas,
      fetchActiveAreas,
      fetchSlideInfo,
      isDrawing,
      setIsDrawing,
      updateSlideInfo,
    }),
    [slideInfo, activeAreas, fetchActiveAreas, fetchSlideInfo, isDrawing, updateSlideInfo],
  );

  return <SlideEditorContext.Provider value={values}>{children}</SlideEditorContext.Provider>;
};
