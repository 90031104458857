import get from 'get-value';
import { createSelector } from 'reselect';

const getEntities = state => state.module.entities;

const getModulesIdsByMethod = (state, { methodId }) => get(state, ['module', 'byMethod', methodId, 'ids']);

export const getModuleById = (state, moduleId) => get(state, ['module', 'entities', moduleId]);

const getModulesByMethod = createSelector(getModulesIdsByMethod, getEntities, (ids, entities) => {
  if (!ids || !entities) return [];
  return ids.map(id => entities[id]).filter(item => !!item);
});

export default getModulesByMethod;
