import { ContentCopyOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { CopyParams, CopyTarget, SlideSetVersion, copySlideSetVersion } from '../../../api/slideSetVersion';
import SlideSetVersionContext from '../../../contexts/slideset-version-context';
import { useAppSelector } from '../../../hooks/store';
import { getCurrentHierarchyMethodId } from '../../../selectors/context';

type Params = {
  moduleId: string;
  nodeId: string;
  setId: string;
};

type Props = {
  version: SlideSetVersion;
};

export default function CopyAction({ version }: Props) {
  const params = useParams<Params>();

  const currentMethodId = useAppSelector(getCurrentHierarchyMethodId);

  const { refreshVersions } = useContext(SlideSetVersionContext);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    const { moduleId, nodeId, setId } = params;
    if (!moduleId || !nodeId || !setId) throw new Error('moduleId, nodeId, setId are required');

    const requestParams: CopyParams = {
      moduleId,
      nodeId,
      slideSetId: setId,
      versionId: version.id.toString(),
    };

    const target: CopyTarget = {
      methodId: currentMethodId,
      moduleId,
      nodeId,
    };

    await copySlideSetVersion(requestParams, target);
    refreshVersions();
  };

  return (
    <IconButton id="ContentCopyOutlined" color="primary" size="large" onClick={handleClick}>
      <ContentCopyOutlined titleAccess="Copy" />
    </IconButton>
  );
}
