import get from 'get-value';
import { createSelector } from 'reselect';

const getEntities = state => state.method.entities;

const getMethodIdsBySubject = (state, { subjectId }) => get(state, ['method', 'bySubject', subjectId, 'ids']);

const getMethodsBySubject = createSelector(getMethodIdsBySubject, getEntities, (ids, entities) => {
  if (!ids || !entities) return [];
  return ids.map(id => entities[id]).filter(item => !!item);
});

export default getMethodsBySubject;
