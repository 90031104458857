import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" {...props} ref={ref} />);

class SlidingConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleAction() {
    const { children } = this.props;

    children.props.onClick();
  }

  render() {
    const { open } = this.state;
    const { title, context, disagreeAction, agreeAction, children, isWarning } = this.props;

    return (
      <>
        {React.cloneElement(children, { onClick: () => this.handleOpen() })}
        <Dialog open={open} TransitionComponent={Transition} onClose={() => this.handleClose()}>
          <DialogTitle>{title}</DialogTitle>

          <DialogContent>
            <DialogContentText>{context}</DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button id="disagree" onClick={() => this.handleClose()} color="primary">
              {disagreeAction}
            </Button>

            <Button
              id="agree"
              onClick={() => {
                this.handleAction();
                this.handleClose();
              }}
              color={isWarning ? 'error' : 'primary'}
            >
              {agreeAction}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

SlidingConfirmation.propTypes = {
  title: PropTypes.string,
  context: PropTypes.string,
  disagreeAction: PropTypes.string,
  agreeAction: PropTypes.string,
  isWarning: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SlidingConfirmation.defaultProps = {
  title: 'Confirmation',
  context: '',
  disagreeAction: 'Cancel',
  agreeAction: 'Ok',
  isWarning: false,
};

export default SlidingConfirmation;
