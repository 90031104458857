import { createSelector } from 'reselect';
import moduleType from '../constants/module-type';
import getModulesByMethod from './module';

/* eslint-disable import/prefer-default-export */
const getSuperModulesForModule = moduleId =>
  createSelector(getModulesByMethod, modules =>
    modules.filter(m => m.type === moduleType.SUPER && m.subModules.includes(moduleId)),
  );

export { getSuperModulesForModule };
