import React from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import DigislideRow from './slideset-row';
import { SlideSet } from '../../../../api/slideSet';

type Props = {
  digislides: SlideSet[];
  onDelete: (id: string) => void;
};

const NoDigiSlides = () => {
  return (
    <TableRow>
      <TableCell className="p-no-digibooks" colSpan={5}>
        No digislides found.
      </TableCell>
    </TableRow>
  );
};

const DigislideList = ({ digislides, onDelete }: Props): JSX.Element => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>State</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {digislides && digislides.length === 0 ? (
          <NoDigiSlides />
        ) : (
          digislides?.map(digislide => <DigislideRow key={digislide.id} digislide={digislide} onDelete={onDelete} />)
        )}
      </TableBody>
    </Table>
  );
};

export default DigislideList;
