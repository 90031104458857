import api from '../services/api';
import { TableOfContentActionType } from '../action-types';
import { getTocByModuleId } from '../selectors/table-of-content';

const fetchTableOfContentsForModuleSuccess = (moduleId, nodes) => ({
  type: TableOfContentActionType.FETCH_TOC_BY_MODULE_SUCCESS,
  payload: {
    moduleId,
    nodes,
  },
});

const fetchTableOfContentsForModuleFailed = () => ({
  type: TableOfContentActionType.FETCH_TOC_BY_MODULE_FAILURE,
});

export function forceFetchTableOfContentFor(moduleId) {
  return dispatch =>
    api
      .get(`/shell/modules/${moduleId}/table-of-content/nodes`)
      .then(({ data: { data } }) => {
        dispatch(fetchTableOfContentsForModuleSuccess(moduleId, data));
        return data;
      })
      .catch(err => {
        dispatch(fetchTableOfContentsForModuleFailed());
        throw err;
      });
}

export function fetchTableOfContentFor(moduleId) {
  return (dispatch, getState) => {
    const toc = getTocByModuleId(getState(), moduleId);

    if (!toc) return dispatch(forceFetchTableOfContentFor(moduleId));

    return Promise.resolve();
  };
}
