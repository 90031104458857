/* eslint-disable no-underscore-dangle */
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { tss } from 'tss-react';
import { SlideEditorParams } from './utils';
import {
  ACTIVE_AREA_TYPE,
  getActiveArea,
  putActiveArea,
  ActiveAreaSingle,
  ActiveAreaType,
  AudioMode,
  ActiveAreaPutBody,
} from './api';
import DetailView from '../../components/layouts/detailview';
import { useAppDispatch } from '../../hooks/store';
import WebLink from './components/active-area-types/web-link';
import Embed from './components/active-area-types/embed';
import SlideLink from './components/active-area-types/slide-link';

const useStyles = tss.create(() => ({
  button: {
    color: 'white',
  },
}));

const validationSchema = yup.object({
  name: yup.string().max(100, 'Name should be less than 100 characters').required('Name is required'),
  type: yup.string().required(),
  url: yup
    .string()
    .nullable()
    .when('type', {
      is: ACTIVE_AREA_TYPE.WEBLINK,
      then: yup.string().url('URL is not valid').required('URL is required'),
      else: yup.string().nullable(true),
    }),
  medialinkId: yup
    .string()
    .nullable()
    .when('type', {
      is: ACTIVE_AREA_TYPE.EMBED,
      then: yup.string().required('A medialink is required.'),
      else: yup.string().nullable(true),
    }),
  linkedSlideId: yup
    .string()
    .nullable()
    .when('type', {
      is: ACTIVE_AREA_TYPE.NAVIGATION,
      then: yup.string().required('A linked slide is required.'),
      else: yup.string().nullable(true),
    }),
  startSeconds: yup.number().nullable(),
  endSeconds: yup
    .number()
    .nullable()
    .moreThan(yup.ref('startSeconds'), 'Video end time must be greater than start time'),
});

type FormValues = {
  name: string;
  type: ActiveAreaType;
  url: string | null;
  medialinkId: string;
  linkedSlideId: number | null;
  audioMode: AudioMode | null;
  startSeconds: number | null;
  endSeconds: number | null;
};

const transformActiveArea = (base: ActiveAreaSingle, values: FormValues): ActiveAreaPutBody => {
  const { type, name } = values;

  // Type cast properties since this should be covered by the validation schema
  const activeArea = {
    ...base,
    type,
    name,
    url: type === ACTIVE_AREA_TYPE.WEBLINK ? (values.url as string) : null,
    medialinkId: type === ACTIVE_AREA_TYPE.EMBED ? values.medialinkId : null,
    linkedSlideId: type === ACTIVE_AREA_TYPE.NAVIGATION ? (values.linkedSlideId as number) : null,
    audioMode: type === ACTIVE_AREA_TYPE.EMBED && values.audioMode ? values.audioMode : null,
    startSeconds: type === ACTIVE_AREA_TYPE.EMBED ? (values.startSeconds as number | null) : null,
    endSeconds: type === ACTIVE_AREA_TYPE.EMBED ? (values.endSeconds as number | null) : null,
  };

  delete activeArea.medialink;

  return activeArea;
};

const ActiveAreaEditor = () => {
  const dispatch = useAppDispatch();
  const [activeArea, setActiveArea] = useState<ActiveAreaSingle>();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const params = useParams<SlideEditorParams>() as Required<SlideEditorParams>;

  useEffect(() => {
    const fetchActiveArea = async () => {
      const area = await getActiveArea(params);
      if (area) setActiveArea(area);
    };
    fetchActiveArea();
  }, [params, dispatch]);

  return (
    <Formik
      initialValues={
        {
          name: activeArea?.name || '',
          type: activeArea?.type || ACTIVE_AREA_TYPE.REVEAL,
          url: activeArea?.url || undefined,
          medialinkId: activeArea?.medialink?.id || undefined,
          medialink: activeArea?.medialink || undefined,
          linkedSlideId: activeArea?.linkedSlideId || undefined,
          audioMode: activeArea?.audioMode || undefined,
          startSeconds: activeArea?.startSeconds,
          endSeconds: activeArea?.endSeconds,
        } as FormValues
      }
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async formValues => {
        const transformedValues = transformActiveArea(activeArea as ActiveAreaSingle, formValues);
        await putActiveArea(params, transformedValues);
        navigate('..');
      }}
    >
      {({ errors, values, handleSubmit, handleChange, handleBlur }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <DetailView
              title="Edit Active Area"
              previousPage=".."
              actionElement={
                <Button className={classes.button} variant="text" type="submit">
                  Save
                </Button>
              }
            >
              <Grid container marginLeft="32px" marginTop="96px" width="400px">
                <TextField
                  id="name"
                  required
                  label="Active Area Name"
                  variant="outlined"
                  value={values.name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  fullWidth
                />
              </Grid>
              <Grid container marginLeft="32px" marginTop="16px">
                <FormControl>
                  <FormLabel id="active-area-type">Type:</FormLabel>
                  <RadioGroup
                    aria-labelledby="active-area-type"
                    row
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <FormControlLabel
                      value={ACTIVE_AREA_TYPE.REVEAL}
                      control={<Radio />}
                      label="Reveal"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={ACTIVE_AREA_TYPE.NAVIGATION}
                      control={<Radio />}
                      label="Slide Link"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={ACTIVE_AREA_TYPE.WEBLINK}
                      control={<Radio />}
                      label="Weblink"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={ACTIVE_AREA_TYPE.EMBED}
                      control={<Radio />}
                      label="Embed"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {values.type === ACTIVE_AREA_TYPE.WEBLINK && <WebLink />}
              {values.type === ACTIVE_AREA_TYPE.EMBED && (
                <Embed deletedMedialink={activeArea?.type === ACTIVE_AREA_TYPE.EMBED && !activeArea.medialink} />
              )}
              {values.type === ACTIVE_AREA_TYPE.NAVIGATION && (
                <SlideLink
                  deletedSlide={activeArea?.type === ACTIVE_AREA_TYPE.NAVIGATION && !activeArea.linkedSlideId}
                />
              )}
            </DetailView>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ActiveAreaEditor;
