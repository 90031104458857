import React from 'react';
import { TextField, MenuItem } from '@mui/material';
import { connect } from 'react-redux';

import { object, arrayOf, number, func, string } from 'prop-types';

import { makeStyles } from '@mui/styles';
import { setNodeLevel, setCurrentPage } from '../../../actions/codex-editor';

const useStyles = makeStyles(() => ({
  tocRange: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const nbsp = String.fromCharCode(8194); // non-breaking-space

function setNodeTitleIndentation(node) {
  let title = '';
  for (let i = 0; i < node.level; i += 1) {
    // eslint-disable-line no-plusplus
    title += nbsp;
  }
  title += node.name;
  return title;
}

export function TocNodeSelect(props) {
  const classes = useStyles();
  const { tocNodes, pageRanges, totalPages, viewMode, setLevel, nodeLevel, setPage } = props;

  const tocRanges = pageRanges
    .filter(range => (range.from && range.to) || range.nodeId === 'general')
    .map(range => {
      const tocNode = tocNodes.find(node => node.id === range.nodeId);
      const title = setNodeTitleIndentation(tocNode);
      const jsx = (
        <div className={classes.tocRange}>
          <span>{title}</span>
          <span>{`${nbsp}${range.from} - ${range.to}`}</span>
        </div>
      );

      const tocRange = {
        id: tocNode.id,
        jsx,
        from: range.from,
      };
      return tocRange;
    });

  return (
    <TextField
      name="tocNodeSelect"
      label="Page Ranges"
      id="tocnodeselect"
      variant="outlined"
      fullWidth
      select
      value={nodeLevel}
      onChange={e => {
        const { value } = e.target;
        setLevel(value);

        const page = tocRanges.find(range => range.id === value).from;
        setPage(page, viewMode, totalPages);
      }}
    >
      {tocRanges &&
        tocRanges.map((range, index) => (
          <MenuItem key={`${range.id + index}`} value={range.id}>
            {range.jsx}
          </MenuItem>
        ))}
    </TextField>
  );
}

TocNodeSelect.propTypes = {
  tocNodes: arrayOf(object.isRequired).isRequired,
  pageRanges: arrayOf(object.isRequired).isRequired,
  totalPages: number,
  viewMode: number.isRequired,
  nodeLevel: string.isRequired,
  setLevel: func.isRequired,
  setPage: func.isRequired,
};

TocNodeSelect.defaultProps = {
  totalPages: undefined,
};

const mapStateToProps = state => ({
  nodeLevel: state.codexEditor.nodeLevel || '',
  totalPages: state.codexEditor.pagination.totalPages,
  viewMode: state.codexEditor.pagination.viewMode,
});

const mapDispatchToProps = {
  setLevel: setNodeLevel,
  setPage: setCurrentPage,
};

export const ConnectedTocNodeSelect = connect(mapStateToProps, mapDispatchToProps)(TocNodeSelect);

export default connect(mapStateToProps, mapDispatchToProps)(TocNodeSelect);
