import PropTypes from 'prop-types';

export default PropTypes.shape({
  name: PropTypes.string,
  productionalState: PropTypes.number.isRequired,
  bookLayer: PropTypes.string,
  answerLayer: PropTypes.string,
  cover: PropTypes.string,
  backCover: PropTypes.string,
  manual: PropTypes.string,
  pageRanges: PropTypes.arrayOf(
    PropTypes.shape({
      nodeId: PropTypes.string,
      from: PropTypes.number,
      to: PropTypes.number,
      keepMediaVisibleTo: PropTypes.number,
      manualFrom: PropTypes.number,
    }),
  ),
  disableAnswerLayerBlend: PropTypes.bool,
  solutionsIncluded: PropTypes.bool,
  enableManual: PropTypes.bool,
});
