import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';

function LinkIconButton(props) {
  const { to, children, buttonProps, disabled, target } = props;

  const { children: ignoredChildren, component, variant, href, ...sanitizedButtonProps } = buttonProps;

  return (
    <IconButton
      target={target}
      disabled={disabled}
      to={to}
      component={Link}
      variant="contained"
      size="large"
      {...sanitizedButtonProps}
    >
      {children}
    </IconButton>
  );
}

LinkIconButton.propTypes = {
  disabled: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  buttonProps: PropTypes.object,
  target: PropTypes.string,
};

LinkIconButton.defaultProps = {
  disabled: false,
  to: '',
  buttonProps: {},
  target: '',
};

export default LinkIconButton;
