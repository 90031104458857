import React from 'react';
import PropTypes from 'prop-types';

export function AppPublishingState({ state }) {
  return <span>{state.charAt(0).toUpperCase() + state.slice(1)}</span>;
}

AppPublishingState.propTypes = {
  state: PropTypes.string,
};

AppPublishingState.defaultProps = {
  state: 'Not Linked',
};

export default AppPublishingState;
