import { Inventory } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { SlideSetVersion, putSlideSetVersionState } from '../../../api/slideSetVersion';
import { SlideSetVersionState } from '../../../api/slideSet';
import SlideSetVersionContext from '../../../contexts/slideset-version-context';
import SlidingConfirmation from '../../../components/sliding-confirmation';

type ArchiveProps = {
  version: SlideSetVersion;
};
const ArchiveAction = ({ version }: ArchiveProps) => {
  const { refreshVersions } = useContext(SlideSetVersionContext);
  const isDisabled = version.state !== SlideSetVersionState.PUBLISHED;
  const { nodeId, moduleId, setId } = useParams();

  const handleArchive = async () => {
    if (!moduleId || !nodeId || !setId) return;
    await putSlideSetVersionState(moduleId, nodeId, setId, version.id.toString(), SlideSetVersionState.ARCHIVED);
    refreshVersions();
  };

  return (
    <SlidingConfirmation
      title="Warning: archiving set"
      context="This slide set version will be archived and no slide set will be shown"
      disagreeAction="Cancel"
      agreeAction="Archive"
    >
      <IconButton
        data-testid="archive-button"
        disabled={isDisabled}
        id="archive"
        color="primary"
        size="large"
        onClick={handleArchive}
      >
        <span title="Archive">
          <Inventory />
        </span>
      </IconButton>
    </SlidingConfirmation>
  );
};

export default ArchiveAction;
