import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { Avatar, Card, CardActions, CardContent, CardHeader, Typography, Button } from '@mui/material';

import LockIcon from '@mui/icons-material/LockOutlined';
import withStyles from '@mui/styles/withStyles';

import { logOn } from '../../services/auth-service';

const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {},
  cardHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    color: '#fafafa',
  },
});

export function Login({ classes }) {
  const [params] = useSearchParams();

  const returnUrl = params.get('returnUrl');

  return (
    <Card className={classes.container}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
        }
        className={classes.cardHeader}
        disableTypography
        title={
          <Typography variant="h5" className={classes.title}>
            P BackOffice
          </Typography>
        }
      />
      <CardContent>
        <Typography>Welcome to the backoffice of P</Typography>
        <Typography variant="caption">This application requires you to be authenticated</Typography>
      </CardContent>
      <CardActions>
        <Button id="login" color="primary" onClick={() => logOn(returnUrl)} size="small">
          Login
        </Button>
      </CardActions>
    </Card>
  );
}

Login.defaultProps = {
  classes: {},
};

Login.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Login);
