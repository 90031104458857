import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    backgroundImage: 'url(/assets/images/login-background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  content: {
    minWidth: 300,
    margin: 'auto',
  },
  logo: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
};

export function Splash({ classes, children }) {
  return (
    <div className={classes.container}>
      <img src="./assets/images/logo-pelckmans-footer.svg" height="42" className={classes.logo} alt="Pelckmans" />
      <div className={classes.content}>{children}</div>
    </div>
  );
}

Splash.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Splash.defaultProps = {
  classes: {},
  children: null,
};

export default withStyles(styles)(Splash);
