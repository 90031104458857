import { fabric } from 'fabric';
import FabricService from '../../codex-detail/components/editor/services/fabric-service';
import { ActiveArea, Shape } from '../api';

type FabricObject = fabric.Object & { id: string };

export default class ActiveAreaService extends FabricService {
  private slideWidth: number;

  private slideHeight: number;

  constructor(id: string, slideWidth: number, slideHeight: number) {
    super(id);
    this.RECTANGLE_FILL = 'rgba(240,20,20,0.15)';
    this.MARKER_FILL = 'rgb(240,20,20)';
    this.slideHeight = slideHeight;
    this.slideWidth = slideWidth;
    this.MARKER_RADIUS = 20;
  }

  setMoving = () => {};

  drawActiveAreas(activeAreas: ActiveArea[], onAreaMoved: (area: ActiveArea, index: number) => void) {
    this.removeAllObjects();

    const denormalizedActiveAreas = activeAreas.map(area =>
      FabricService.getAbsoluteCoordinatesForRectangle(area, this.slideWidth, this.slideHeight),
    );

    const objects: fabric.Object[] = this.createEventedRectanglesWithMarkers(
      denormalizedActiveAreas,
      this.setMoving,
      onAreaMoved,
      this.slideWidth,
      this.slideHeight,
    );

    objects.forEach(obj => {
      if (obj.type === 'rect') {
        this.fabricCanvas.add(obj);
      } else {
        this.fabricCanvas.bringToFront(obj);
      }
    });
  }

  registerListeners(onAreaDrawn: (shape: Shape) => Promise<void>) {
    this.fabricCanvas.on('mouse:down', ({ e }) => {
      if (this.isDrawingArea) {
        const { x, y } = this.fabricCanvas.getPointer(e);
        this.dragStartPoint = new fabric.Point(Math.round(x), Math.round(y));
      }
    });

    this.fabricCanvas.on('mouse:up', ({ e }) => {
      if (this.isDrawingArea && this.dragStartPoint) {
        const { x, y } = this.fabricCanvas.getPointer(e);
        const end = new fabric.Point(Math.round(x), Math.round(y));

        const start = this.dragStartPoint;
        const top = Math.min(end.y, start.y);
        const left = Math.min(end.x, start.x);
        const offsetTop = Math.max(end.y, start.y);
        const offsetLeft = Math.max(end.x, start.x);

        const height = offsetTop - top;
        const width = offsetLeft - left;

        const relativeShape = FabricService.getRelativeCoordinatesForRectangle(
          { left, top, width, height, angle: 0, scaleX: 1, scaleY: 1 },
          this.slideWidth,
          this.slideHeight,
        );

        onAreaDrawn(relativeShape);
      }
      this.dragStartPoint = undefined;
    });
  }

  startDrawingMode(onAreaDrawn: (shape: Shape) => Promise<void>) {
    this.setIsDrawingArea(true);
    this.freezeObjects();
    this.registerListeners(onAreaDrawn);
  }

  stopDrawingMode() {
    this.setIsDrawingArea(false);
    this.unFreezeObjects();
    this.fabricCanvas.off('mouse:down');
    this.fabricCanvas.off('mouse:up');
  }

  restoreSelectedArea(object: fabric.Object) {
    const allObjects = this.fabricCanvas.getObjects() as FabricObject[];
    const existingActiveObject = allObjects.find(obj => obj.id === (object as FabricObject).id);

    if (existingActiveObject) this.fabricCanvas.setActiveObject(existingActiveObject as fabric.Object);
  }
}
