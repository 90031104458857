import { Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DigibookRow from './digibook-row';

import getDigibooksByModule from '../../../../selectors/digibook';
import { fetchDigibooksByModule, deleteDigibook } from '../../../../actions/digibook';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(),
    wordBreak: 'break word',
    fontSize: '20px',
  },
});

export class DigibookList extends React.Component {
  componentDidMount() {
    const { moduleId, fetchDigibooksFor } = this.props;

    fetchDigibooksFor(moduleId);
  }

  componentDidUpdate() {
    const { moduleId, fetchDigibooksFor } = this.props;

    fetchDigibooksFor(moduleId);
  }

  render() {
    const { digibooks, classes, removeDigibook } = this.props;

    return (
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>App Publishing State</TableCell>
            <TableCell>Productional state</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {digibooks.length > 0 ? (
            digibooks.map(digibook => <DigibookRow key={digibook.id} digibook={digibook} onDelete={removeDigibook} />)
          ) : (
            <TableRow>
              <TableCell className="p-no-digibooks" colSpan="5">
                No digibooks found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }
}

DigibookList.propTypes = {
  digibooks: PropTypes.array,
  moduleId: PropTypes.string.isRequired,
  fetchDigibooksFor: PropTypes.func.isRequired,
  removeDigibook: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

DigibookList.defaultProps = {
  digibooks: [],
  classes: {},
};

const mapStateToProps = (state, props) => ({
  digibooks: getDigibooksByModule(state, props),
});

const mapDispatchToProps = {
  fetchDigibooksFor: fetchDigibooksByModule,
  removeDigibook: deleteDigibook,
};

export const ConnectedDigibookList = connect(mapStateToProps, mapDispatchToProps)(DigibookList);

export default withStyles(styles)(ConnectedDigibookList);
