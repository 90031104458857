import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { SlideSetVersion, deleteSlideSetVersion } from '../../../api/slideSetVersion';
import { SlideSetVersionState } from '../../../api/slideSet';
import SlideSetVersionContext from '../../../contexts/slideset-version-context';
import SlidingConfirmation from '../../../components/sliding-confirmation';

type DeleteProps = {
  version: SlideSetVersion;
};

const DeleteAction = ({ version }: DeleteProps) => {
  const { refreshVersions } = useContext(SlideSetVersionContext);
  const { nodeId, moduleId, setId } = useParams();

  const isDisabled =
    version.state === SlideSetVersionState.PUBLISHED || version.state === SlideSetVersionState.ARCHIVED;

  const handleDelete = async () => {
    if (!moduleId || !nodeId || !setId) return;
    await deleteSlideSetVersion(moduleId, nodeId, setId, version.id.toString());
    refreshVersions();
  };

  return (
    <SlidingConfirmation
      title="Warning: permanent delete"
      context="This slide set version will be permanently deleted. It cannot be restored."
      disagreeAction="Cancel"
      agreeAction="Delete"
    >
      <IconButton
        data-testid="delete-button"
        onClick={handleDelete}
        disabled={isDisabled}
        id="delete"
        color="secondary"
        size="large"
      >
        <span title="Delete">
          <Delete />
        </span>
      </IconButton>
    </SlidingConfirmation>
  );
};

export default DeleteAction;
