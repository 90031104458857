import { decode } from '../services/jwt';
import Storage from '../services/storage';
import StorageKey from '../services/storage-keys';
import { AuthenticationActionType } from '../action-types';

function initialState() {
  const accessToken = Storage.get(StorageKey.ACCESS_TOKEN);

  return accessToken ? decode(accessToken) : {};
}

export default function authenticationReducer(state = initialState(), action = {}) {
  switch (action && action.type) {
    case AuthenticationActionType.AUTHENTICATE_SUCCEEDED:
    case AuthenticationActionType.REFRESHTOKEN_SUCCEEDED: {
      return {
        ...action.payload,
      };
    }
    case AuthenticationActionType.REFRESHTOKEN_FAILED: {
      return {};
    }
    default:
      return state;
  }
}
