import { Tab, Tabs } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setActiveTab } from '../../../actions/codex-editor';
import Prompt from '../../../components/prompt';
import { getActiveTab } from '../../../selectors/codex-editor';
import { isAnyUploading } from '../../../selectors/secured-file';
import DigibookPropTypes from '../digibook-proptypes';
import DigibookAnswers from './digibook-answers/digibook-answer-set-list';
import DigibookInfo from './digibook-info';
import DigibookLinks from './digibook-links';
import DigibookManual from './digibook-manual';
import DigibookToc from './digibook-toc';

const styles = theme => ({
  mainContent: {
    padding: theme.spacing(1.5),
  },
  tab: {
    minWidth: 50,
  },
});

export class DigibookTabs extends React.PureComponent {
  state = {
    isFormDirty: false,
  };

  static getDerivedStateFromProps(props) {
    return {
      isFormDirty: props.dirty,
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  }

  handleUnload = e => {
    const { isFormDirty } = this.state;
    if (isFormDirty) {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to leave this page? You have unsaved changes left.';
    }
  };

  handleChange = (event, activeTab) => {
    const { setTab } = this.props;
    setTab(activeTab);
  };

  render() {
    const { isFormDirty } = this.state;
    const { activeTab, values: digibook } = this.props;
    const { classes, handleSubmit, isManualUploading, ...restOfProps } = this.props;

    return (
      <>
        <Prompt when={isFormDirty} message="Are you sure you want to leave this page? You have unsaved changes left." />
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          onChange={this.handleChange}
        >
          <Tab label="PDF" className={classes.tab} />
          <Tab label="TOC" className={classes.tab} />
          <Tab label="Links" className={classes.tab} disabled={!digibook.id} />
          <Tab label="Answers" className={classes.tab} disabled={!digibook.id} />
          <Tab
            label="Manual"
            className={classes.tab}
            disabled={!(digibook.id && digibook.manual) || digibook.manualType === 'pop-up-manual' || isManualUploading}
          />
        </Tabs>
        <div className={classes.mainContent}>
          <form onSubmit={handleSubmit}>
            {activeTab === 0 && (
              <div id="pdf">
                <DigibookInfo {...restOfProps} />
              </div>
            )}
            {activeTab === 1 && (
              <div id="toc">
                <DigibookToc {...restOfProps} />
              </div>
            )}
            {activeTab === 2 && (
              <div id="links">
                <DigibookLinks
                  {...restOfProps}
                  module={restOfProps.values.module}
                  pageRanges={restOfProps.values.pageRanges}
                  digibookId={digibook.id}
                />
              </div>
            )}
            {activeTab === 3 && (
              <div id="answers">
                <DigibookAnswers
                  module={restOfProps.values.module}
                  pageRanges={restOfProps.values.pageRanges}
                  digibookId={digibook.id}
                />
              </div>
            )}
            {activeTab === 4 && (
              <div id="manual">
                <DigibookManual />
              </div>
            )}
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  activeTab: Number(getActiveTab(state)),
  isManualUploading: Boolean(isAnyUploading(state, 'digibookmanual')),
});

const mapDispatchToProps = {
  setTab: setActiveTab,
};

DigibookTabs.propTypes = {
  classes: PropTypes.shape({
    mainContent: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  values: DigibookPropTypes.isRequired,
  setTab: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  dirty: PropTypes.bool.isRequired,
  isManualUploading: PropTypes.bool.isRequired,
};

export const ConnectedDigibookTabs = connect(mapStateToProps, mapDispatchToProps)(DigibookTabs);

export default withStyles(styles)(ConnectedDigibookTabs);
