import api from '../services/api';

export enum SlideSetVersionState {
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export type SlideSet = {
  id: string;
  moduleId: number;
  nodeId: number;
  states: SlideSetVersionState[];
  name: string;
};

type SlideSetWithoutStates = Omit<SlideSet, 'states'>;

export const postSlideSet = async (moduleId: string, nodeId: string): Promise<SlideSetWithoutStates> => {
  const { data } = await api.post(`/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets`);
  return data;
};

export const getSlideSet = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
): Promise<SlideSetWithoutStates> => {
  const { data } = await api.get(`/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}`);
  return data;
};

export const getSlideSets = async (moduleId: string, nodeId: string): Promise<SlideSet[]> => {
  const response = await api.get(`/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets`);
  return response.data.data;
};

export const deleteSlideSet = async (moduleId: string, nodeId: string, slideSetId: string) => {
  return api.delete(`/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}`);
};

export type SlideSetVersionType = 'SINGLE' | 'SEQUENTIAL' | 'NONSEQUENTIAL';

export const postSlideGroup = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
  versionId: string,
  type: SlideSetVersionType,
) => {
  const { data } = await api.post<{ linkId: number; groupId: number; slideId?: number }>(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions/${versionId}/groups`,
    { type },
  );

  return data;
};

type Slide = {
  id: number;
  thumbnail?: string;
};

export type SlideGroup = {
  id: number;
  type: SlideSetVersionType;
  slides: Slide[];
  linkId: number;
  previewText: string;
};

export const getSlideGroups = async (moduleId: string, nodeId: string, slideSetId: string, versionId: string) => {
  const { data } = await api.get<{ data: SlideGroup[] }>(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions/${versionId}/groups`,
  );

  return data.data;
};

export const deleteSlideGroup = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
  versionId: string,
  linkId: number,
) => {
  return api.delete(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions/${versionId}/groups/${linkId}`,
  );
};

export const putSlideSetName = async (moduleId: string, nodeId: string, slideSetId: string, name: string) => {
  await api.put(`/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/name`, { name });
};

export const deleteSlide = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
  versionId: string,
  linkId: number,
  slideId: number,
) => {
  return api.delete(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions/${versionId}/groups/${linkId}/slides/${slideId}`,
  );
};

type CopySlideResponse = {
  slideId: number;
  slideSourceId: string;
  revealSourceId: string;
};

export const copySlide = async (
  moduleId: string,
  nodeId: string,
  slideSetId: string,
  versionId: string,
  linkId: string,
  slideId: string,
) => {
  const { data } = await api.post<CopySlideResponse>(
    `/shell/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${slideSetId}/versions/${versionId}/groups/${linkId}/slides/${slideId}/copy`,
  );

  return data;
};
