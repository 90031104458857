import React from 'react';
import PropTypes, { string } from 'prop-types';
import { connect } from 'react-redux';

import { Badge, TableCell, TableRow, IconButton, Dialog } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Launch, Edit, Visibility, Delete } from '@mui/icons-material';

import { deleteLinkArea, editLinkArea } from '../../../actions/codex-editor';
import LinkAreaDetail from './digibook-link-area-detail';

import linkAreaShape from '../../../proptype-shapes/link-area';

const styles = {
  badge: {
    top: 20,
    border: '2px solid #fff',
  },
};

export class LinkArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpened: false,
    };
  }

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  render() {
    const { digibookId, linkArea, index, classes, removeLinkArea, commitLinkAreaChanges } = this.props;
    const { dialogOpened } = this.state;

    return (
      <>
        <Dialog fullScreen open={dialogOpened}>
          <LinkAreaDetail
            index={index}
            editLinkArea={commitLinkAreaChanges}
            handleClose={this.handleClose}
            linkArea={linkArea}
            digibookId={digibookId}
          />
        </Dialog>
        <TableRow>
          <TableCell padding="none">
            <Badge color="secondary" badgeContent={index + 1} classes={{ badge: classes.badge }} overlap="rectangular">
              <Launch />
            </Badge>
          </TableCell>
          <TableCell>{linkArea.name}</TableCell>
          <TableCell padding="none">
            <IconButton color="primary" onClick={() => this.setState({ dialogOpened: true })} size="large">
              <Edit />
            </IconButton>
          </TableCell>
          <TableCell padding="none">
            <IconButton color="primary" size="large">
              <Visibility />
            </IconButton>
          </TableCell>
          <TableCell padding="none">
            <IconButton
              color="secondary"
              className="delete"
              onClick={() => removeLinkArea(digibookId, linkArea)}
              size="large"
            >
              <Delete />
            </IconButton>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

LinkArea.propTypes = {
  digibookId: string.isRequired,
  linkArea: linkAreaShape.isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.object,
  removeLinkArea: PropTypes.func.isRequired,
  commitLinkAreaChanges: PropTypes.func.isRequired,
};

LinkArea.defaultProps = {
  classes: {},
};

const mapDispatchToProps = {
  removeLinkArea: deleteLinkArea,
  commitLinkAreaChanges: editLinkArea,
};

export const ConnectedLinkArea = connect(undefined, mapDispatchToProps)(LinkArea);

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(LinkArea));
