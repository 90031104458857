import { Cancel, Edit, Save } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { addDays, endOfDay, format, parseISO, startOfDay, subDays } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { SchoolYear, patchSchoolYear } from '../../../api/school-year';
import SlidingConfirmation from '../../../components/sliding-confirmation';
import SchoolYearContext from '../../../contexts/school-year-context';

type Props = {
  schoolYear: SchoolYear;
  edited: boolean;
  onEdit: (id: number | null) => void;
};

const formatDate = (date: string) => format(parseISO(date), 'dd-MM-yyyy');
const formatYear = (date: string) => format(parseISO(date), 'yyyy');
const calendarFormat = (date: string) => format(parseISO(date), 'yyyy-MM-dd');

const isDateInRangeOrFuture = (schoolYear: SchoolYear) => {
  const now = new Date();
  const startDate = new Date(schoolYear.startDate);
  const endDate = new Date(schoolYear.endDate);

  if (now >= startDate && now <= endDate) return true;
  if (now < startDate) return true;

  return false;
};

const SchoolYearRow = ({ schoolYear, edited, onEdit }: Props) => {
  const { refreshSchoolYears, schoolYears } = useContext(SchoolYearContext);
  const [editedEndDate, setEditedEndDate] = useState(schoolYear.endDate);
  const [editedStartDate, setEditedStartDate] = useState(schoolYear.startDate);

  useEffect(() => {
    setEditedStartDate(schoolYear.startDate);
    setEditedEndDate(schoolYear.endDate);
  }, [schoolYear]);

  const handleUpdate = async () => {
    await patchSchoolYear({
      id: schoolYear.id!,
      startDate: startOfDay(new Date(editedStartDate)).toISOString(),
      endDate: endOfDay(new Date(editedEndDate)).toISOString(),
    });
    onEdit(null);
    await refreshSchoolYears();
  };

  const handleCancel = () => {
    setEditedStartDate(schoolYear.startDate);
    setEditedEndDate(schoolYear.endDate);
    onEdit(null);
  };

  const renderConfirmation = (context: string) => {
    return (
      <>
        <SlidingConfirmation
          context={context}
          title="Are you sure you want to save these changes?"
          disagreeAction="No"
          agreeAction="Save changes"
        >
          <IconButton color="primary" onClick={handleUpdate}>
            <Save />
          </IconButton>
        </SlidingConfirmation>
        <IconButton color="error" onClick={handleCancel}>
          <Cancel />
        </IconButton>
      </>
    );
  };

  const renderEditActions = () => {
    const schoolYearIndex = schoolYears.findIndex(x => x.id === schoolYear.id);
    const nextSchoolYear = schoolYears[schoolYearIndex - 1];
    const previousSchoolYear = schoolYears[schoolYearIndex + 1];

    if (!schoolYear.isActive && editedStartDate !== schoolYear.startDate && previousSchoolYear) {
      const context = `Changing the start date of schoolyear ${formatYear(schoolYear.startDate)}-${formatYear(
        schoolYear.endDate,
      )} to date ${formatDate(editedStartDate)} will automatically change the end date of schoolyear ${formatYear(
        previousSchoolYear.startDate,
      )}-${formatYear(previousSchoolYear.endDate)} to date ${formatDate(
        subDays(new Date(editedStartDate), 1).toISOString(),
      )}.`;

      return renderConfirmation(context);
    }

    if (schoolYear.isActive && editedEndDate !== schoolYear.endDate && nextSchoolYear) {
      const context = `Changing the end date of schoolyear ${formatYear(schoolYear.startDate)}-${formatYear(
        schoolYear.endDate,
      )} to date ${formatDate(editedEndDate)} will automatically change the start date of schoolyear ${formatYear(
        nextSchoolYear.startDate,
      )}-${formatYear(nextSchoolYear.endDate)} to date ${formatDate(
        addDays(new Date(editedEndDate), 1).toISOString(),
      )}.`;

      return renderConfirmation(context);
    }

    return (
      <>
        <IconButton color="primary" onClick={handleUpdate}>
          <Save />
        </IconButton>
        <IconButton color="error" onClick={handleCancel}>
          <Cancel />
        </IconButton>
      </>
    );
  };

  return (
    <TableRow data-testid={`school-year-row-${schoolYear.id}`}>
      <TableCell>{`${formatYear(schoolYear.startDate)}-${formatYear(schoolYear.endDate)}`}</TableCell>
      <TableCell>
        {edited && !schoolYear.isActive ? (
          <input
            type="date"
            data-testid="start-date-input"
            onChange={e => setEditedStartDate(e.target.value || schoolYear.startDate)}
            value={calendarFormat(editedStartDate)}
            min={calendarFormat(addDays(new Date(), 1).toISOString())}
          />
        ) : (
          formatDate(schoolYear.startDate)
        )}
      </TableCell>
      <TableCell>
        {edited ? (
          <input
            type="date"
            onChange={e => setEditedEndDate(e.target.value || schoolYear.endDate)}
            data-testid="end-date-input"
            value={calendarFormat(editedEndDate)}
            min={calendarFormat(addDays(parseISO(editedStartDate), 1).toISOString())}
          />
        ) : (
          formatDate(schoolYear.endDate)
        )}
      </TableCell>
      <TableCell>{schoolYear.isActive ? 'Yes' : 'No'}</TableCell>
      <TableCell>
        {edited ? (
          renderEditActions()
        ) : (
          <IconButton
            color="primary"
            disabled={!isDateInRangeOrFuture(schoolYear)}
            onClick={() => onEdit(schoolYear.id)}
          >
            <Edit />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default SchoolYearRow;
