import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikContext } from 'formik';

import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Editor from './components/editor';
import DetailView from '../../components/layouts/detailview';
import digibookValidationSchema from './digibook-form-validator';

import { getModuleById } from '../../selectors/module';
import { getCurrentHierarchyModuleId } from '../../selectors/context';
import { getLastAdded } from '../../selectors/digibook';
import { resetEditorState, initializeEditorStateFor } from '../../actions/codex-editor';
import { getInitialState, getPendingState, getTotalPages } from '../../selectors/codex-editor';
import AnswerCatchAllDropZone from './components/digibook-answers/digibook-answer-catch-all-dropzone';

import { saveDigibook } from '../../actions/digibook';
import DigibookTabs from './components/digibook-tabs';
import RemoteSubmitButton from '../../components/forms/formik/form-remote-submit';

const useStyles = makeStyles(theme => ({
  editorContainer: {
    position: 'relative',
  },
  formContainer: {
    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 64px)',
      overflowY: 'scroll',
    },
  },
}));

export default function DigibookDetail() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const digibookId = id !== 'new' ? id : undefined;
  const module = useSelector(state => getModuleById(state, getCurrentHierarchyModuleId(state)));
  const isPending = useSelector(getPendingState);
  const lastAdded = useSelector(getLastAdded);
  const initialValues = useSelector(getInitialState);
  const totalPages = useSelector(getTotalPages);

  useEffect(() => {
    dispatch(initializeEditorStateFor(digibookId));

    return () => {
      dispatch(resetEditorState());
    };
  }, [digibookId, dispatch]);

  if (!module && !isPending) return <Navigate to="/codex" />;
  if (lastAdded && !digibookId) return <Navigate to={`/codex/${lastAdded}`} />;

  return (
    <DndProvider backend={HTML5Backend}>
      <Formik
        initialValues={initialValues}
        onSubmit={db => dispatch(saveDigibook(db))}
        validationSchema={digibookValidationSchema(totalPages + 1)}
        enableReinitialize
      >
        <DetailView
          title="Digibook Editor"
          previousPage="/codex"
          submitFormName="digibook"
          actionElement={<RemoteSubmitButton name="digibook" />}
        >
          <AnswerCatchAllDropZone digibookId={id}>
            <FormikContext.Consumer>
              {formikProps => (
                <Grid container>
                  <Grid item xs={12} md={4} className={classes.formContainer}>
                    {Object.keys(formikProps.values).length > 0 && <DigibookTabs module={module} {...formikProps} />}
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.editorContainer}>
                    <Editor digibookId={id} manualType={formikProps.values.manualType} />
                  </Grid>
                </Grid>
              )}
            </FormikContext.Consumer>
          </AnswerCatchAllDropZone>
        </DetailView>
      </Formik>
    </DndProvider>
  );
}
