import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { IconButton, SnackbarContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
} from '@mui/icons-material';

import { green, amber } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import NotificationType from '../../constants/notification-type';

const useStyles = makeStyles(() => ({
  [NotificationType.SUCCESS]: {
    backgroundColor: green[600],
  },
  [NotificationType.ERROR]: {
    backgroundColor: useTheme().palette.error.dark,
  },
  [NotificationType.INFORMATION]: {
    backgroundColor: useTheme().palette.primary.dark,
  },
  [NotificationType.WARNING]: {
    backgroundColor: amber[700],
  },
  messageRoot: {
    alignItems: 'center',
    display: 'flex',
  },
  message: {
    color: useTheme().palette.common.white,
  },
  variantIcon: {
    opacity: 0.9,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },
}));

const variantIcon = {
  [NotificationType.SUCCESS]: CheckCircleIcon,
  [NotificationType.WARNING]: WarningIcon,
  [NotificationType.ERROR]: ErrorIcon,
  [NotificationType.INFORMATION]: InfoIcon,
};

export const Notification = React.forwardRef(({ className, message, onClose, title, variant }, ref) => {
  const classes = useStyles();
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classnames(classes[variant], className)}
      message={
        <div className={classes.messageRoot}>
          <Icon fontSize="small" className={classes.variantIcon} />
          <div>
            {title && (
              <Typography variant="body1" className={classes.message}>
                {title}
              </Typography>
            )}
            <Typography variant="body2" className={classes.message}>
              {message}
            </Typography>
          </div>
        </div>
      }
      action={
        <IconButton
          key="close"
          id="notification__action--close"
          aria-label="Close"
          color="inherit"
          onClick={() => onClose && onClose()}
          size="large"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      ref={ref}
    />
  );
});

Notification.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  variant: PropTypes.oneOf([
    NotificationType.ERROR,
    NotificationType.INFORMATION,
    NotificationType.SUCCESS,
    NotificationType.WARNING,
  ]),
};

Notification.defaultProps = {
  className: undefined,
  onClose: undefined,
  title: undefined,
  variant: NotificationType.INFORMATION,
};

export default Notification;
