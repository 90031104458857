import jwt from 'jwt-decode';

export function decode(token) {
  return jwt(token);
}

export function isExpired(token, tresholdInSeconds = 0) {
  const payload = decode(token);

  const currentTime = new Date().getTime() / 1000 + tresholdInSeconds;

  return currentTime > payload.exp;
}
