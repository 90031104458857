import update from 'immutability-helper';

import { CodexEditorActionType } from '../action-types';
import { updateWeights } from '../utils/update-dnd-weights';

export const defaultState = {
  isPending: true,
  pagination: {
    currentPage: undefined,
    totalPages: undefined,
    viewMode: 2,
  },
  scale: 1,
  viewport: {
    height: undefined,
    width: undefined,
  },
  linkAreas: {},
  answerSets: {
    sets: {},
  },
  manualMapping: [],
  initial: {},
  files: {},
  activeTab: 0,
  isObjectMoving: false,
  isDrawing: false,
};

export default function codexEditorReducer(state = defaultState, action = {}) {
  switch (action && action.type) {
    case CodexEditorActionType.RESET_INITIAL_STATE: {
      return update(state, {
        $set: defaultState,
      });
    }
    case CodexEditorActionType.SET_INITIAL_STATE: {
      const { initialState } = action.payload;

      return update(state, {
        $merge: {
          initial: initialState,
          isPending: false,
        },
      });
    }
    case CodexEditorActionType.SET_FILE_FOR_LAYER: {
      const { layer, file } = action.payload;
      return update(state, {
        files: {
          $merge: {
            [layer]: file,
          },
        },
      });
    }
    case CodexEditorActionType.SET_CURRENT_PAGE: {
      const { page } = action.payload;
      return update(state, {
        answerSets: {
          $unset: ['currentAnswer', 'currentSet'],
        },
        isDrawing: {
          $set: false,
        },
        pagination: {
          $merge: {
            currentPage: page,
          },
        },
      });
    }
    case CodexEditorActionType.SET_TOTAL_PAGES: {
      const { totalPages } = action.payload;
      return update(state, {
        pagination: {
          $merge: {
            totalPages,
          },
        },
      });
    }
    case CodexEditorActionType.SET_SCALE: {
      const { scale } = action.payload;
      return update(state, {
        $merge: {
          scale,
        },
      });
    }
    case CodexEditorActionType.INIT_LINK_AREAS: {
      const { linkAreas } = action.payload;

      return {
        ...state,
        linkAreas: {
          areas: linkAreas || [],
        },
      };
    }
    case CodexEditorActionType.ADD_LINK_AREA: {
      const linkArea = action.payload;
      return update(state, {
        linkAreas: {
          areas: (areas = []) => update(areas, { $push: [linkArea] }),
        },
      });
    }
    case CodexEditorActionType.DELETE_LINK_AREA: {
      const { linkAreas } = state;
      const { linkArea } = action.payload;

      return update(state, {
        linkAreas: {
          $merge: {
            areas: linkAreas.areas.filter(x => x !== linkArea),
          },
        },
      });
    }
    case CodexEditorActionType.EDIT_LINK_AREA: {
      const { linkAreas } = state;
      const { linkArea, index } = action.payload;

      const linkAreasForCurrentSpread = linkAreas.areas.filter(area =>
        area.pages.some(page => linkArea.pages.includes(page)),
      );
      const targetLinkArea = linkAreas.areas ? linkAreasForCurrentSpread[index] : null;

      return update(state, {
        linkAreas: {
          $merge: {
            areas: linkAreas.areas.map(x => (x === targetLinkArea ? linkArea : x)),
          },
        },
      });
    }
    case CodexEditorActionType.EDIT_LINK_AREA_SHAPE: {
      const { linkAreas } = state;
      const { shape, index, pages } = action.payload;

      const linkAreasForCurrentSpread = linkAreas.areas.filter(area => area.pages.some(page => pages.includes(page)));
      const targetLinkArea = linkAreas.areas ? linkAreasForCurrentSpread[index] : null;

      const copy = { ...targetLinkArea };
      copy.shape = shape;

      return update(state, {
        linkAreas: {
          $merge: {
            areas: linkAreas.areas.map(x => (x === targetLinkArea ? copy : x)),
          },
        },
      });
    }
    case CodexEditorActionType.EDITOR_SAVE_SUCCESS: {
      const { initialState } = action.payload;
      return update(state, {
        initial: { $set: initialState },
      });
    }
    case CodexEditorActionType.SET_VIEWPORT: {
      const { viewport } = action.payload;
      return update(state, {
        viewport: { $set: viewport },
      });
    }
    case CodexEditorActionType.SET_ACTIVE_TAB: {
      const { activeTab } = action.payload;
      return update(state, {
        activeTab: { $set: activeTab },
      });
    }
    case CodexEditorActionType.SET_NODE_LEVEL: {
      const { nodeId } = action.payload;
      return update(state, {
        nodeLevel: { $set: nodeId },
      });
    }
    case CodexEditorActionType.SET_OBJECT_MOVING: {
      const { isObjectMoving } = action.payload;
      return update(state, {
        isObjectMoving: { $set: isObjectMoving },
      });
    }
    case CodexEditorActionType.SET_HIERARCHY_FOR_DIGIBOOK_LINK: {
      return update(state, {
        digibookLinkContext: { $set: action.payload },
      });
    }
    case CodexEditorActionType.TOGGLE_IS_DRAWING: {
      return update(state, {
        isDrawing: drawingMode => !drawingMode,
      });
    }
    case CodexEditorActionType.DISABLE_IS_DRAWING: {
      return update(state, {
        isDrawing: { $set: false },
      });
    }
    case CodexEditorActionType.SET_CURRENT_NODE_FOR_MEDIA_CONTEXT: {
      const { nodeId } = action.payload;

      return update(state, {
        currentNode: { $set: nodeId },
      });
    }
    case CodexEditorActionType.CLEAR_CURRENT_NODE_FOR_MEDIA_CONTEXT: {
      return update(state, {
        $unset: ['currentNode'],
      });
    }
    case CodexEditorActionType.INIT_ANSWER_SETS: {
      const { answerSets } = action.payload;

      const answerSetMap = (answerSets || []).reduce(
        (map, answerSet) => ({
          ...map,
          [answerSet.id]: {
            ...answerSet,
            answers: updateWeights(answerSet.answers),
          },
        }),
        {},
      );

      return update(state, {
        answerSets: {
          $set: {
            sets: answerSetMap,
          },
        },
      });
    }
    case CodexEditorActionType.ADD_ANSWER_SET: {
      const answerSet = action.payload;

      return update(state, {
        answerSets: {
          $unset: ['currentAnswer'],
          currentSet: { $set: answerSet.id },
          sets: sets =>
            update(sets, {
              $merge: {
                [answerSet.id]: answerSet,
              },
            }),
        },
      });
    }
    case CodexEditorActionType.EDIT_ANSWER_SET: {
      const { set } = action.payload;

      return update(state, {
        answerSets: {
          sets: sets =>
            update(sets, {
              $merge: {
                [set.id]: set,
              },
            }),
        },
      });
    }
    case CodexEditorActionType.SET_SELECTED_ANSWER_SET: {
      const { id } = action.payload;

      return update(state, {
        answerSets: {
          $merge: {
            currentSet: id,
          },
          $unset: ['currentAnswer'],
        },
      });
    }
    case CodexEditorActionType.SET_SELECTED_ANSWER: {
      const { id } = action.payload;

      return update(state, {
        answerSets: {
          $merge: {
            currentAnswer: id,
          },
        },
      });
    }
    case CodexEditorActionType.CLEAR_SELECTED_ANSWER: {
      return update(state, {
        answerSets: {
          $unset: ['currentAnswer'],
        },
      });
    }
    case CodexEditorActionType.CLEAR_SELECTED_ANSWER_SET: {
      return update(state, {
        answerSets: {
          $unset: ['currentSet', 'currentAnswer'],
        },
      });
    }
    case CodexEditorActionType.UPDATE_ANSWER_SET_ANSWERS: {
      const { set, selectedAnswer } = action.payload;

      return update(state, {
        answerSets: {
          currentAnswer: { $set: selectedAnswer },
          sets: sets =>
            update(sets, {
              $merge: {
                [set.id]: {
                  ...set,
                  answers: updateWeights(set.answers),
                },
              },
            }),
        },
      });
    }
    case CodexEditorActionType.REMOVE_ANSWER_FROM_SET: {
      const { set } = action.payload;

      return update(state, {
        answerSets: {
          $unset: ['currentAnswer'],
          sets: sets =>
            update(sets, {
              $merge: {
                [set.id]: {
                  ...set,
                  answers: updateWeights(set.answers),
                },
              },
            }),
        },
      });
    }
    case CodexEditorActionType.REMOVE_ANSWER_SET: {
      const { id } = action.payload;

      return update(state, {
        answerSets: {
          $unset: ['currentSet', 'currentAnswer'],
          sets: {
            $unset: [id],
          },
        },
      });
    }
    case CodexEditorActionType.INIT_MANUAL_MAPPING: {
      const { manualMapping } = action.payload;

      return {
        ...state,
        manualMapping: manualMapping || [],
      };
    }
    case CodexEditorActionType.ADD_MANUAL_MAPPING: {
      const manualMapping = action.payload;

      return update(state, {
        manualMapping: {
          $push: [manualMapping],
        },
      });
    }
    case CodexEditorActionType.SAVE_MANUAL_MAPPING: {
      const { manualMapping, index } = action.payload;

      return update(state, {
        manualMapping: {
          [index]: { $set: manualMapping },
        },
      });
    }
    case CodexEditorActionType.DELETE_MANUAL_MAPPING: {
      const { index } = action.payload;
      return update(state, {
        manualMapping: {
          $splice: [[index, 1]],
        },
      });
    }
    default:
      return state;
  }
}
