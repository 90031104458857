import { normalize, schema } from 'normalizr';
import api from '../services/api';

import { PublishingHouseActionType } from '../action-types';

const fetchPublishingHousesRequested = () => ({
  type: PublishingHouseActionType.FETCH_PUBLISHINGHOUSES,
});

const fetchPublishingHousesSuccess = (entities, ids) => ({
  type: PublishingHouseActionType.FETCH_PUBLISHINGHOUSES_SUCCESS,
  payload: {
    entities,
    ids,
  },
});

const fetchPublishingHousesFailed = () => ({
  type: PublishingHouseActionType.FETCH_PUBLISHINGHOUSES_FAILURE,
});

/**
 * Fetch the publishingHouses.
 *
 * @export
 */
export function forceFetchPublishingHouses() {
  return dispatch => {
    dispatch(fetchPublishingHousesRequested());

    return api
      .get('/shell/publishinghouses', {
        params: {
          orderBy: 'rank',
        },
      })
      .then(({ data: { data } }) => {
        const {
          entities: { publishingHouses },
          result,
        } = normalize(data, [new schema.Entity('publishingHouses')]);

        dispatch(fetchPublishingHousesSuccess(publishingHouses, result));
      })
      .catch(err => {
        dispatch(fetchPublishingHousesFailed());
        throw err;
      });
  };
}

/**
 * Fetch publishingHouses if not present in state
 *
 * @export
 */
export function fetchPublishingHouses() {
  return (dispatch, getState) => {
    const { publishingHouse } = getState();

    if (publishingHouse.isFetching || publishingHouse.lastUpdated) return Promise.resolve();

    return dispatch(forceFetchPublishingHouses());
  };
}
