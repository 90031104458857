import { normalize, schema } from 'normalizr';
import get from 'get-value';
import { observableDiff, applyChange } from 'deep-diff';

import api from '../services/api';

import { DigibookActionType } from '../action-types';
import { success } from './notification';

import { fetchSecuredFileById } from './secured-file';
import { fetchTableOfContentFor } from './table-of-content';
import { getInitialState } from '../selectors/codex-editor';
import { codexEditorSaved } from './codex-editor';
import { getTocNodes } from '../selectors/table-of-content';

/**
 * Create FETCH_DIGIBOOKS_BY_MODULE
 *
 * @param {string} moduleId
 */
const fetchDigibooksByModuleRequested = moduleId => ({
  type: DigibookActionType.FETCH_DIGIBOOKS_BY_MODULE,
  payload: {
    moduleId,
  },
});

/**
 *  Create FETCH_DIGIBOOKS_BY_MODULE_SUCCESS
 *
 * @param {string} moduleId
 * @param {object} entities
 * @param {string[]} ids
 */
const fetchDigibooksByModuleSuccess = (moduleId, entities, ids) => ({
  type: DigibookActionType.FETCH_DIGIBOOKS_BY_MODULE_SUCCESS,
  payload: {
    moduleId,
    entities,
    ids,
  },
});

/**
 * Create FETCH_DIGIBOOKS_BY_MODULE_FAILURE
 *
 * @param {string} moduleId
 */
const fetchDigibooksByModuleFailed = moduleId => ({
  type: DigibookActionType.FETCH_DIGIBOOKS_BY_MODULE_FAILURE,
  payload: {
    moduleId,
  },
});

/**
 * Create FETCH_DIGIBOOK_BY_ID_SUCCESS
 *
 * @param {string} digibook
 */
const fetchDigibookByIdSuccess = digibook => ({
  type: DigibookActionType.FETCH_DIGIBOOK_BY_ID_SUCCESS,
  payload: {
    digibook,
  },
});

/**
 * Create FETCH_DIGIBOOK_BY_ID_FAILURE
 *
 * @param {string} digibookId
 */
const fetchDigibookByIdFailure = digibookId => ({
  type: DigibookActionType.FETCH_DIGIBOOK_BY_ID_FAILURE,
  payload: {
    digibookId,
  },
});

/**
 * Create DIGIBOOK_ADDED
 *
 * @param {string} digibook
 */
const digibookAdded = digibook => ({
  type: DigibookActionType.DIGIBOOK_ADDED,
  payload: digibook,
});

/**
 * Create DIGIBOOK_UPDATED
 *
 * @param {string} digibook
 */
const digibookUpdated = digibook => ({
  type: DigibookActionType.DIGIBOOK_UPDATED,
  payload: digibook,
});

export function clearLastAdded() {
  return {
    type: DigibookActionType.CLEAR_LAST_ADDED,
  };
}

/**
 * Fetch the digibookfor the specified id.
 *
 * @export
 * @param {string} digibookId
 */
export function forceFetchDigibookById(digibookId) {
  return dispatch =>
    api
      .get(`/shell/digibooks/${digibookId}`)
      .then(({ data }) => {
        dispatch(fetchDigibookByIdSuccess(data));
        return data;
      })
      .then(data =>
        Promise.all([
          data,
          data.bookLayer && dispatch(fetchSecuredFileById(data.bookLayer)),
          data.answerLayer && dispatch(fetchSecuredFileById(data.answerLayer)),
          data.cover && dispatch(fetchSecuredFileById(data.cover)),
          data.backCover && dispatch(fetchSecuredFileById(data.backCover)),
          data.manual && dispatch(fetchSecuredFileById(data.manual)),
        ]),
      )
      .then(([data]) => data)
      .catch(err => {
        dispatch(fetchDigibookByIdFailure(digibookId));
        throw err;
      });
}

/**
 * Fetch the digibookfor the specified id.
 *
 * @export
 * @param {string} digibookId
 */
export function fetchDigibookById(digibookId) {
  return (dispatch, getState) => {
    const digibook = get(getState(), ['digibook', 'entities', digibookId]);
    if (!digibook) return dispatch(forceFetchDigibookById(digibookId));
    return Promise.resolve();
  };
}

/**
 * Fetch the digibooks for the specified module.
 *
 * @export
 * @param {string} moduleId
 */
export function forceFetchDigibooksByModule(moduleId) {
  return dispatch => {
    dispatch(fetchDigibooksByModuleRequested(moduleId));

    return api
      .get('/shell/digibooks', {
        params: {
          moduleId,
        },
      })
      .then(({ data: { data } }) => {
        const {
          entities: { digibooks: entities },
          result: ids,
        } = normalize(data, [new schema.Entity('digibooks')]);

        dispatch(fetchDigibooksByModuleSuccess(moduleId, entities, ids));
      })
      .then(() => {
        dispatch(fetchTableOfContentFor(moduleId));
      })
      .catch(err => {
        dispatch(fetchDigibooksByModuleFailed(moduleId));
        throw err;
      });
  };
}

/**
 * Fetch the digibooks for the specified module only if not already present in state.
 *
 * @export
 * @param {string} moduleId
 */
export function fetchDigibooksByModule(moduleId) {
  return (dispatch, getState) => {
    const moduleData = get(getState(), ['digibook', 'byModule', moduleId]) || {};

    if (moduleData.isFetching || moduleData.lastUpdated) return Promise.resolve();

    return dispatch(forceFetchDigibooksByModule(moduleId));
  };
}

/**
 *  Create DELETE_DIGIBOOK_SUCCESS
 *
 * @param {string} id
 */
const deleteDigibookSuccess = id => ({
  type: DigibookActionType.DELETE_DIGIBOOK_SUCCESS,
  payload: {
    id,
  },
});

/**
 * Delete the specified digibook.
 *
 * @export
 * @param {string} id
 */
export function deleteDigibook(id) {
  return dispatch => api.delete(`/shell/digibooks/${id}`).then(() => dispatch(deleteDigibookSuccess(id)));
}

/**
 * Save the given digibook
 *
 * @export
 * @param {object} digibook
 */
export function addDigibook(digibook) {
  return dispatch =>
    api
      .post('/shell/digibooks', digibook)
      .then(({ data }) => {
        dispatch(digibookAdded(data));
        dispatch(success('Digibook saved'));
        return data;
      })
      .then(data => {
        dispatch(clearLastAdded());
        return data;
      });
}

/**
 * update the given digibook
 *
 * @export
 * @param {object} digibook
 */
export function updateDigibook(digibook) {
  const { id, ...rest } = digibook;
  return dispatch =>
    api
      .patch(`/shell/digibooks/${id}`, {
        ...rest,
      })
      .then(({ data }) => {
        dispatch(digibookUpdated(data));
        dispatch(success('Digibook updated'));
        return data;
      });
}

export function getChangesForDigibook(digibook, state) {
  const initialValues = getInitialState(state);
  const changes = { id: initialValues.id };
  observableDiff(initialValues, digibook, d => {
    if (d.path[0] === 'pageRanges') {
      if (!changes.pageRanges) {
        changes.pageRanges = digibook.pageRanges.filter(
          range => range.from >= 0 && range.to >= 0 && range.keepMediaVisibleTo >= 0,
        );
      }
    } else if (d.path[0] === 'manualMargins') {
      changes.manualMargins = digibook.manualMargins;
    } else applyChange(changes, undefined, d);
  });

  return changes;
}

/**
 * save the given digibook
 *
 * @export
 * @param {object} digibook
 */
export function saveDigibook(digibook) {
  return (dispatch, getState) => {
    const state = getState();

    if (!digibook.id) {
      return dispatch(
        addDigibook({
          ...digibook,
          pageRanges: (digibook.pageRanges || []).filter(
            range => range.from >= 0 && range.to >= 0 && range.keepMediaVisibleTo >= 0,
          ),
        }),
      );
    }

    const changes = getChangesForDigibook(digibook, state);

    return (
      Object.keys(changes).length > 1 &&
      dispatch(updateDigibook(changes)).then(savedBook => {
        const { linkAreas: omitLinkAreas, answerSets: omitAnswerSets, ...initialState } = savedBook;

        const allNodes = getTocNodes(state, initialState.module).map(({ id }) => ({ nodeId: id }));

        const { pageRanges } = initialState;

        if (pageRanges && pageRanges.length > 0) {
          initialState.pageRanges = allNodes.map(
            node => pageRanges.find(range => range.nodeId === node.nodeId) || node,
          );
        } else {
          initialState.pageRanges = allNodes;
        }

        return dispatch(codexEditorSaved(initialState));
      })
    );
  };
}
