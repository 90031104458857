import get from 'get-value';

export const getSecuredFileById = (state, id) => get(state, ['securedFile', 'entities', id]);
export const getLoadingForField = (state, form, field) => get(state, ['securedFile', 'uploading', form + field]);

export const isAnyUploading = (state, form) => {
  const uploadingState = get(state, ['securedFile', 'uploading']);

  return (
    uploadingState &&
    Object.keys(uploadingState).filter(key => key.startsWith(form) && uploadingState[key] === true).length > 0
  );
};

export default getSecuredFileById;
