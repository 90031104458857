import React from 'react';
import { object, number, arrayOf, func, bool, string } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import withTheme from '@mui/styles/withTheme';
import { connect } from 'react-redux';

import {
  getLinkAreasForCurrentPages,
  getCurrentPagesForView,
  getIsDrawing,
  getViewport,
} from '../../../../selectors/codex-editor';
import {
  setObjectMoving,
  editLinkAreaShape,
  addLinkAreaForCurrentPages,
  disableIsDrawing,
} from '../../../../actions/codex-editor';

import linkAreaShape from '../../../../proptype-shapes/link-area';

import LinkAreaService from './services/link-area-service';
import { getCanvasDimensions } from './utils';

const styles = () => ({
  linkAreaLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export class LinkAreaLayer extends React.PureComponent {
  componentDidMount() {
    const { theme, viewport, currentPages } = this.props;
    const { width, height } = getCanvasDimensions({ viewport, currentPages });
    this.linkAreaService = new LinkAreaService('link-area-canvas', theme);
    this.linkAreaService.initialize(width, height);
    this.linkAreaService.addSelectionListeners(this.onAreaDrawn);
    this.renderLinkAreas();
  }

  componentDidUpdate(prevProps) {
    const { isDrawing, viewport, currentPages } = this.props;

    if (isDrawing !== prevProps.isDrawing) this.linkAreaService.setIsDrawingArea(isDrawing);

    const { width, height } = getCanvasDimensions({ viewport, currentPages });
    this.linkAreaService.setDimensions(width, height);
    this.renderLinkAreas();
  }

  componentWillUnmount() {
    this.linkAreaService.destroy();
  }

  onAreaMoved = (shape, index) => {
    const { currentPages, commitLinkAreaChanges, linkAreas, digibookId } = this.props;
    const linkArea = linkAreas[index];
    commitLinkAreaChanges(
      digibookId,
      {
        ...linkArea,
        shape,
      },
      currentPages,
      index,
    );
  };

  onAreaDrawn = shape => {
    const { isDrawing, addLinkArea, disableDrawing, currentPages, digibookId } = this.props;

    if (isDrawing) {
      const linkArea = {
        shape,
        pages: currentPages,
        name: 'New link area',
      };

      if (currentPages.length === 1) {
        linkArea.shape = LinkAreaService.normalizeShapeForSinglePage(linkArea.shape);
      }

      addLinkArea(digibookId, linkArea);
      disableDrawing();
    }
  };

  setObjectMoving = isMoving => {
    const { setMoving, isDrawing } = this.props;

    if (!isDrawing) {
      setMoving(isMoving);
    }
  };

  renderLinkAreas = () => {
    const { linkAreas, currentPages } = this.props;

    const isSinglePageMode = currentPages.length === 1;

    this.linkAreaService.drawLinkAreas(linkAreas, this.setObjectMoving, this.onAreaMoved, isSinglePageMode);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.linkAreaLayer}>
        <canvas id="link-area-canvas" />
      </div>
    );
  }
}

LinkAreaLayer.propTypes = {
  digibookId: string.isRequired,
  classes: object,
  viewport: object,
  linkAreas: arrayOf(linkAreaShape).isRequired,
  currentPages: arrayOf(number),
  theme: object.isRequired,
  setMoving: func.isRequired,
  commitLinkAreaChanges: func.isRequired,
  isDrawing: bool.isRequired,
  addLinkArea: func.isRequired,
  disableDrawing: func.isRequired,
};

LinkAreaLayer.defaultProps = {
  classes: {},
  currentPages: [],
  viewport: {
    width: 0,
    height: 0,
  },
};

const mapStateToProps = state => ({
  linkAreas: getLinkAreasForCurrentPages(state),
  currentPages: getCurrentPagesForView(state),
  viewport: getViewport(state),
  isDrawing: getIsDrawing(state),
});

const mapDispatchToProps = {
  setMoving: setObjectMoving,
  addLinkArea: addLinkAreaForCurrentPages,
  commitLinkAreaChanges: editLinkAreaShape,
  disableDrawing: disableIsDrawing,
};

export const ConnectedLinkAreaLayer = connect(mapStateToProps, mapDispatchToProps)(LinkAreaLayer);

export default withTheme(withStyles(styles)(ConnectedLinkAreaLayer));
