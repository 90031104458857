import update from 'immutability-helper';
import { MediaLinkActionType } from '../action-types';
import tableOfContentConstants from '../constants/table-of-content';

export const defaultState = {
  entities: {},
  byNode: {},
  byLinkArea: {},
  contentTypes: {
    byModule: {},
    entities: {},
  },
};

export default function mediaLinkReducer(state = defaultState, action = {}) {
  switch (action && action.type) {
    case MediaLinkActionType.FETCH_MEDIA_LINK_STARTED: {
      const { moduleId, nodeId } = action.payload;

      const id = nodeId === tableOfContentConstants.GENERAL_NODE_ID ? moduleId : nodeId;
      return update(state, {
        byNode: {
          [id]: {
            $set: { isFetching: true },
          },
        },
      });
    }
    case MediaLinkActionType.FETCH_MEDIA_LINK_SUCCESS: {
      const { moduleId, nodeId, mediaLinks } = action.payload;

      const id = nodeId === tableOfContentConstants.GENERAL_NODE_ID ? moduleId : nodeId;

      const addedEntities = mediaLinks.reduce(
        (mediaLinkMap, mediaLink) => ({
          ...mediaLinkMap,
          [mediaLink.id]: mediaLink,
        }),
        {},
      );

      const newNode = {
        [id]: {
          lastUpdated: Date.now(),
          isFetching: false,
          ids: mediaLinks.map(ml => ml.id),
        },
      };

      return update(state, {
        byNode: { $merge: newNode },
        entities: { $merge: addedEntities },
      });
    }
    case MediaLinkActionType.FETCH_MEDIA_LINK_FAILED: {
      const { moduleId, nodeId } = action.payload;

      const id = nodeId === tableOfContentConstants.GENERAL_NODE_ID ? moduleId : nodeId;

      const nodeFetchFailed = {
        [id]: {
          isFetching: false,
        },
      };

      return update(state, {
        byNode: {
          $merge: nodeFetchFailed,
        },
      });
    }
    case MediaLinkActionType.FETCH_MEDIA_CONTENT_TYPE_STARTED: {
      const { moduleId } = action.payload;
      const newModuleState = {
        [moduleId]: { isFetching: true },
      };

      return update(state, {
        contentTypes: {
          byModule: {
            $merge: newModuleState,
          },
        },
      });
    }
    case MediaLinkActionType.FETCH_MEDIA_CONTENT_TYPE_FAILED: {
      const { moduleId } = action.payload;
      const newModuleState = {
        [moduleId]: { isFetching: false },
      };

      return update(state, {
        contentTypes: {
          byModule: {
            $merge: newModuleState,
          },
        },
      });
    }
    case MediaLinkActionType.FETCH_MEDIA_CONTENT_TYPE_SUCCESS: {
      const { contentTypes, moduleId } = action.payload;
      const entitiesMap = contentTypes.reduce(
        (map, contentType) => ({
          ...map,
          [contentType.id]: contentType,
        }),
        {},
      );

      const newModule = {
        [moduleId]: {
          isFetching: false,
          lastUpdated: Date.now(),
          ids: contentTypes.map(ct => ct.id),
        },
      };

      const newContentTypes = update(state.contentTypes, {
        byModule: { $merge: newModule },
        entities: { $merge: entitiesMap },
      });

      return update(state, {
        contentTypes: { $set: newContentTypes },
      });
    }
    case MediaLinkActionType.FETCH_MEDIALINKS_FOR_LINKAREA_STARTED: {
      const { linkAreaId } = action.payload;
      return update(state, {
        byLinkArea: {
          $merge: { [linkAreaId]: { isFetching: true } },
        },
      });
    }
    case MediaLinkActionType.FETCH_MEDIALINKS_FOR_LINKAREA_SUCCESS: {
      const { mediaLinks, linkAreaId } = action.payload;

      if (!mediaLinks) return state;

      const entities = mediaLinks.reduce(
        (mediaLinkMap, mediaLink) => ({
          ...mediaLinkMap,
          [mediaLink.id]: mediaLink,
        }),
        {},
      );

      return update(state, {
        byLinkArea: {
          $merge: {
            [linkAreaId]: {
              isFetching: false,
              lastUpdated: Date.now(),
              ids: mediaLinks.map(ml => ml.id),
            },
          },
        },
        entities: { $merge: entities },
      });
    }
    case MediaLinkActionType.FETCH_MEDIALINKS_FOR_LINKAREA_FAILED: {
      const { linkAreaId } = action.payload;
      return update(state, {
        byLinkArea: {
          $merge: { [linkAreaId]: { isFetching: false } },
        },
      });
    }
    default:
      return state;
  }
}
