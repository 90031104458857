import { func, number, shape, string } from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TableRow } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import { deleteManualMapping, saveManualMapping, updateManualMapping } from '../../../../actions/codex-editor';
import MappingCells from './mapping-cells';

const manualMappingValidationSchema = Yup.object().shape({
  excludeFrom: Yup.string().required(),
  from: Yup.number().required().integer(),
  to: Yup.number()
    .required()
    .integer()
    .when('from', (other, schema) => (other ? schema.min(other, 'From has to be smaller than to').required() : schema)),
});

export default function MappingRow({ index, mapping, onError, onClearError }) {
  const dispatch = useDispatch();

  const { id: digibookId } = useParams();

  const handleSave = async values => {
    if (values.id) {
      dispatch(updateManualMapping(digibookId, values, index));
    } else {
      const { tempId, ...mappingValues } = values;
      dispatch(saveManualMapping(digibookId, mappingValues, index));
    }
  };

  const handleDelete = useCallback(async () => {
    await dispatch(deleteManualMapping(digibookId, mapping, index));
    if (onClearError) onClearError(mapping.id || mapping.tempId);
  }, [mapping, onClearError, index, digibookId, dispatch]);

  const handleError = useCallback(
    error => {
      if (onError) onError(error, mapping.id || mapping.tempId);
    },
    [mapping, onError],
  );

  return (
    <TableRow>
      <Formik
        initialValues={mapping}
        enableReinitialize
        validateOnMount
        validationSchema={manualMappingValidationSchema}
        onSubmit={handleSave}
      >
        <MappingCells onError={handleError} onDelete={handleDelete} />
      </Formik>
    </TableRow>
  );
}

MappingRow.propTypes = {
  index: number.isRequired,
  mapping: shape({
    id: string,
    excludeFrom: string.isRequired,
    from: number,
    to: number,
  }).isRequired,
  onError: func,
  onClearError: func,
};

MappingRow.defaultProps = {
  onError: undefined,
  onClearError: undefined,
};
