// The translations should be kept in sync with the translations on each frontend app.
export const defaultTranslations = {
  'TITLE--SO': 'Tijdelijk niet beschikbaar',
  'TITLE--ES': 'Temporairement indisponible',
  'TITLE--BAO': 'Tijdelijk niet beschikbaar',
  'TITLE--VO': 'Tijdelijk niet beschikbaar',
  'MESSAGE--SO':
    'Door een technisch probleem is Pelckmans Portaal momenteel tijdelijk niet beschikbaar.' +
    ' We stellen alles in het werk om dit probleem zo snel mogelijk op te lossen. <br/> Probeer het later opnieuw.',
  'MESSAGE--ES':
    "En raison d'un problème technique, Pelckmans Portail est actuellement temporairement indisponible." +
    ' Nous mettons tout en œuvre pour résoudre ce problème dans les plus brefs délais. <br/> Veuillez réessayer plus tard',
  'MESSAGE--BAO':
    'Door een technisch probleem is de onlineleeromgeving momenteel tijdelijk niet beschikbaar.' +
    ' We stellen alles in het werk om dit probleem zo snel mogelijk op te lossen. <br/> Probeer het later opnieuw.',
  'MESSAGE--VO':
    'Door een technisch probleem is de onlineleeromgeving momenteel tijdelijk niet beschikbaar.' +
    ' We stellen alles in het werk om dit probleem zo snel mogelijk op te lossen. <br/> Probeer het later opnieuw.',
};
