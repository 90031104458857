import axios from 'axios';

import api from '../services/api';
import { SecuredFileActionType } from '../action-types';
import { getSecuredFileById } from '../selectors/secured-file';
import { setFileForLayer } from './codex-editor';
import { buildRequestPath } from '../api/utils';
import { deleteSourceFileOfSlide } from '../modules/slide-editor/api';

const fetchSecuredFileSuccess = file => ({
  type: SecuredFileActionType.FETCH_FILE_BY_ID_SUCCESS,
  payload: {
    file,
  },
});

const fetchSecuredFileFailed = id => ({
  type: SecuredFileActionType.FETCH_FILE_BY_ID_FAILURE,
  payload: {
    id,
  },
});

const uploadFileRequested = (form, field) => ({
  type: SecuredFileActionType.UPLOAD_FILE_REQUESTED,
  payload: {
    form,
    field,
  },
});

const uploadFileSuccess = (form, field) => ({
  type: SecuredFileActionType.UPLOAD_FILE_SUCCESS,
  payload: {
    form,
    field,
  },
});

const uploadFileStarted = (file, source) => ({
  type: SecuredFileActionType.UPLOAD_FILE_STARTED,
  payload: {
    file,
    source,
  },
});

export const uploadFileFailed = (id, form, field) => ({
  type: SecuredFileActionType.UPLOAD_FILE_FAILED,
  payload: {
    id,
    form,
    field,
  },
});

export const clearFileById = id => ({
  type: SecuredFileActionType.CLEAR_FILE_BY_ID,
  payload: {
    id,
  },
});

export function forceFetchSecuredFileById(securedFileId) {
  return dispatch =>
    api
      .get(`/shell/secured-files/${securedFileId}`)
      .then(({ data }) => {
        dispatch(fetchSecuredFileSuccess(data));
        return data;
      })
      .catch(err => {
        dispatch(fetchSecuredFileFailed(securedFileId));
        throw err;
      });
}

export function fetchSecuredFileById(securedFileId) {
  return (dispatch, getState) => {
    const file = getSecuredFileById(getState(), securedFileId);
    if (!file || !file.urlExpiration || file.urlExpiration <= Date.now())
      return dispatch(forceFetchSecuredFileById(securedFileId));

    return Promise.resolve();
  };
}

function uploadFileToS3(dispatch, data, file) {
  const source = axios.CancelToken.source();
  const options = {
    headers: {
      'Content-Type': data.contentType,
    },
    cancelToken: source.token,
  };

  dispatch(uploadFileStarted(data, source));

  return axios.put(data.uploadUrl, file, options);
}

export function uploadFileForDigibook(layer, file, moduleId, setValue) {
  return dispatch => {
    const form = 'digibook';
    const { name } = file;
    dispatch(uploadFileRequested(form, layer));
    return api
      .post('/shell/digibooks/files', { moduleId, fileName: name })
      .then(({ data }) => {
        setValue(layer, data.id);
        dispatch(setFileForLayer(layer, data.id));
        return uploadFileToS3(dispatch, data, file)
          .then(() => data)
          .catch(err => {
            if (axios.isCancel(err)) {
              dispatch(uploadFileFailed(data.id, form, layer));
            }
            throw err;
          });
      })
      .then(data => {
        dispatch(uploadFileSuccess(form, layer));

        return dispatch(fetchSecuredFileById(data.id)).then(() => data);
      })
      .catch(err => {
        if (!axios.isCancel(err)) throw err;
      });
  };
}

const START_SECURED_FILE_CONVERSION_SLIDE =
  '/shell/modules/:moduleId/table-of-content/:nodeId/slide-sets/:slideSetId/versions/:versionId/secured-files/:securedFileId/convert';

function startSlideConversion(params, sourceTypes, replace) {
  const requestPath = buildRequestPath(START_SECURED_FILE_CONVERSION_SLIDE, params);

  return api.post(requestPath, { sourceTypes, replace });
}

const SECURED_FILE_SLIDE_POST =
  '/shell/modules/:moduleId/table-of-content/:nodeId/slide-sets/:slideSetId/versions/:versionId/file';

/**
 * @param {import('./secured-file-types').UploadSourceForSlide} fileInfo
 *
 * @param {Object} params
 * @param {string} params.moduleId
 * @param {string} params.nodeId
 * @param {number} params.slideSetId
 * @param {number} params.versionId
 *
 * @param {'slide-editor' | 'slides-importer'} form
 */
export function uploadFileForDigislide(fileInfo, params, form, setValue) {
  return async dispatch => {
    const { type } = fileInfo;

    if (type === 'single') {
      dispatch(uploadFileRequested(form, fileInfo.slide ? 'slide' : 'reveal'));
    } else {
      if (!fileInfo.slide && !fileInfo.reveal) throw new Error('cannot upload file without slide and reveal');
      if (fileInfo.slide) dispatch(uploadFileRequested(form, 'slide'));
      if (fileInfo.reveal) dispatch(uploadFileRequested(form, 'reveal'));
    }

    const pathPostSecuredFileSlide = buildRequestPath(SECURED_FILE_SLIDE_POST, params);

    const sourceTypes = Object.keys(fileInfo).filter(key => ['reveal', 'slide'].includes(key));

    const body = {
      fileName: fileInfo.slide?.name || fileInfo.reveal?.name,
      uploadType: type,
      sourceTypes,
    };

    if (type === 'single') {
      body.linkId = fileInfo.linkId;
      body.slideId = fileInfo.slideId;
    }

    let newSecuredFile;

    return api
      .post(pathPostSecuredFileSlide, body)
      .then(({ data }) => {
        newSecuredFile = data;
        if (setValue) setValue(newSecuredFile.id);

        const filesToUpload = sourceTypes.map(sourceType => ({
          sourceType,
          id: newSecuredFile.mongoId,
          uploadUrl: newSecuredFile[`${sourceType}UploadUrl`],
          contentType: newSecuredFile.contentType,
          file: fileInfo[sourceType],
        }));

        return Promise.all(
          filesToUpload.map(sourceFileInfo => {
            const { file, sourceType, ...sf } = sourceFileInfo;

            return uploadFileToS3(dispatch, sf, file)
              .then(() => newSecuredFile)
              .catch(async err => {
                if (type === 'single') {
                  await deleteSourceFileOfSlide({
                    moduleId: params.moduleId,
                    nodeId: params.nodeId,
                    slideSetId: params.slideSetId,
                    versionId: params.versionId,
                    linkId: fileInfo.linkId,
                    slideId: fileInfo.slideId,
                    securedFileId: newSecuredFile.id,
                  });
                }

                throw err;
              });
          }),
        );
      })
      .then(() => {
        const { linkId, slideId, ...conversionParams } = params;

        return startSlideConversion(
          { ...conversionParams, securedFileId: newSecuredFile.id },
          sourceTypes,
          fileInfo.replace || false,
        )
          .then(async () => {
            if (type === 'single') {
              await dispatch(fetchSecuredFileById(newSecuredFile.mongoId));
            }

            sourceTypes.forEach(sourceType => dispatch(uploadFileSuccess(form, sourceType)));
          })
          .then(() => ({ id: newSecuredFile.mongoId, contentType: newSecuredFile.contentType }));
      })
      .catch(e => {
        if (newSecuredFile)
          dispatch(uploadFileFailed(newSecuredFile.mongoId, form, fileInfo.slide ? 'slide' : 'reveal'));
        if (!axios.isCancel(e)) throw e;
      });
  };
}

export default {
  uploadFileForDigibook,
  fetchSecuredFileById,
  forceFetchSecuredFileById,
};
