import JoditEditor, { IJoditEditorProps } from 'jodit-react';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';

import '../../../../scss/text-editor.scss';
import { updatePreviewText } from '../api';
import { SlideEditorParams } from '../utils';

// all default buttons can be found on https://xdsoft.net/jodit/play.html?currentTab=Buttons
const joditOptions = ['bold', 'strikethrough', 'underline', 'italic', 'eraser', '|', 'ul', 'ol', '|', 'undo', 'redo'];

type Props = {
  previewText: string;
  onPreviewTextChange: (newText: string) => void;
};

export default function TextEditor({ previewText, onPreviewTextChange }: Props) {
  const editor = useRef(null);
  const params = useParams() as Required<SlideEditorParams>;

  const config = useMemo<IJoditEditorProps['config']>(
    () => ({
      readonly: false, // https://xdsoft.net/jodit/docs/,
      placeholder: 'Start typing',
      buttons: [...joditOptions],
      height: '100%',
      width: '100%',
      statusbar: false,
      toolbarAdaptive: false,
      allowResizeX: false,
      allowResizeY: false,
      askBeforePasteHTML: false,
      controls: {
        ul: { list: undefined },
        ol: { list: undefined },
      },
      defaultActionOnPaste: 'insert_only_text',
    }),
    [],
  );

  const save = useMemo(
    () => debounce((newContent: string) => updatePreviewText({ ...params, previewText: newContent }), 1000),
    [params],
  );

  const update = newContent => {
    onPreviewTextChange(newContent);
    save(newContent);
  };

  useEffect(() => {
    return () => {
      save.flush();
    };
  }, [params, save]);

  return <JoditEditor ref={editor} value={previewText} config={config} onChange={update} />;
}
