import { AuthenticationActionType } from '../action-types';

export const authenticateSucceeded = payload => ({
  type: AuthenticationActionType.AUTHENTICATE_SUCCEEDED,
  payload,
});

export const refreshTokenSucceeded = payload => ({
  type: AuthenticationActionType.REFRESHTOKEN_SUCCEEDED,
  payload,
});

export const refreshTokenFailed = () => ({
  type: AuthenticationActionType.REFRESHTOKEN_FAILED,
});
