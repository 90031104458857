import { combineReducers } from 'redux';
import authentication from './authentication';
import context from './context';
import method from './method';
import module from './module';
import publishingHouse from './publishing-house';
import subject from './subject';
import digibook from './digibook';
import notification from './notification';
import securedFile from './secured-file';
import tableOfContent from './table-of-content';
import codexEditor from './codex-editor';
import medialinks from './medialinks';

export default combineReducers({
  authentication,
  context,
  digibook,
  method,
  module,
  notification,
  publishingHouse,
  subject,
  securedFile,
  tableOfContent,
  codexEditor,
  medialinks,
});
