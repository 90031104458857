import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Card, CardActions, CardContent, CardHeader, Typography, Button } from '@mui/material';

import { Link } from 'react-router-dom';

const styles = theme => ({
  container: {
    width: '40vw',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    color: '#fafafa',
  },
  action: {
    textDecoration: 'none',
  },
});

export function NotFound({ classes }) {
  return (
    <Card className={classes.container}>
      <CardHeader
        className={classes.cardHeader}
        disableTypography
        title={
          <Typography variant="h5" className={classes.title}>
            Page not found :(
          </Typography>
        }
      />
      <CardContent>
        <Typography>Maybe the page you are looking for has been removed, or you typed in the wrong URL</Typography>
      </CardContent>
      <CardActions>
        <Link to="/" className={classes.action}>
          <Button color="primary" size="small">
            Go to homepage
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}

NotFound.propTypes = {
  classes: PropTypes.object,
};

NotFound.defaultProps = {
  classes: {},
};

export default withStyles(styles)(NotFound);
