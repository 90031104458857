import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel, FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FieldArray } from 'formik';

const useStyles = makeStyles(() => ({
  formLabel: {
    marginTop: useTheme().spacing(2),
  },
}));

function LinkAreaDisplayOptions({ setFieldValue, values = {} }) {
  const classes = useStyles();
  const displayOptionsList = [{ value: 'highlighted', label: 'Highlighted' }];

  return (
    <FormGroup>
      <FormLabel component="legend" className={classes.formLabel}>
        Display options
      </FormLabel>
      <FieldArray name="displayOptions">
        <>
          {displayOptionsList.map(opt => (
            <FormControlLabel
              key={opt.value}
              onChange={e => {
                if (e.target.checked) {
                  setFieldValue(e.target.name, {
                    ...values,
                    [opt.value]: true,
                  });
                } else {
                  setFieldValue(e.target.name, {
                    ...values,
                    [opt.value]: false,
                  });
                }
              }}
              checked={values[opt.value]}
              control={<Checkbox name="displayOptions" data-testid={`${opt.value}-checkbox`} />}
              label={opt.label}
            />
          ))}
        </>
      </FieldArray>
    </FormGroup>
  );
}

LinkAreaDisplayOptions.propTypes = {
  values: PropTypes.shape({
    highlighted: PropTypes.bool,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default LinkAreaDisplayOptions;
