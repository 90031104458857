import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

function LinkButton(props) {
  const { to, children, buttonProps } = props;

  const { children: ignoredChildren, component, variant, href, ...sanitizedButtonProps } = buttonProps;

  return (
    <Button component={Link} to={to} variant="contained" {...sanitizedButtonProps}>
      {children}
    </Button>
  );
}

LinkButton.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  buttonProps: PropTypes.object,
};

LinkButton.defaultProps = {
  to: '',
  buttonProps: {},
};

export default LinkButton;
