import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { AUDIO_MODE } from '../../api';
import { ActiveAreaForm } from '../../utils';

const AudioEmbed = () => {
  const { values, handleBlur, handleChange } = useFormikContext<ActiveAreaForm>();

  return (
    <FormControl>
      <FormLabel id="active-area-audioMode">Audio embed mode:</FormLabel>
      <RadioGroup
        aria-labelledby="active-area-audioMode"
        row
        name="audioMode"
        value={values.audioMode || AUDIO_MODE.CLASSIC}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        <FormControlLabel value={AUDIO_MODE.CLASSIC} control={<Radio />} label="Classic" labelPlacement="end" />
        <FormControlLabel value={AUDIO_MODE.SINGLE} control={<Radio />} label="Single button" labelPlacement="end" />
      </RadioGroup>
    </FormControl>
  );
};

export default AudioEmbed;
