import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SlideGroup, getSlideGroups } from '../../../api/slideSet';
import { getSlideSetVersion } from '../../../api/slideSetVersion';

export const SlidesetVersionGroupsContext = createContext<{
  groups: SlideGroup[];
  fetchGroups: () => Promise<SlideGroup[]>;
}>({
  groups: [],
  fetchGroups: async () => [],
});

export function SlidesetVersionGroupsContextProvider({ children }) {
  const [groups, setGroups] = useState<SlideGroup[]>([]);
  const { setId, versionId, nodeId, moduleId } = useParams();
  const navigate = useNavigate();

  const validateRights = useCallback(async () => {
    if (!moduleId || !nodeId || !setId || !versionId) return;
    try {
      const { state } = await getSlideSetVersion(moduleId, nodeId, setId, versionId);
      if (state === 'ARCHIVED' || state === 'PUBLISHED') navigate('/digislides');
    } catch (e) {
      navigate('/digislides');
    }
  }, [moduleId, nodeId, setId, versionId, navigate]);

  const fetchGroups = useCallback(async () => {
    if (!moduleId || !nodeId || !setId || !versionId) {
      throw new Error('Can not use SlidesetVersionDetail outside of module');
    }
    try {
      const slideGroups = await getSlideGroups(moduleId, nodeId, setId, versionId);

      setGroups(slideGroups);

      return slideGroups;
    } catch (e) {
      navigate('/digislides');
      return [];
    }
  }, [moduleId, nodeId, setId, versionId, navigate]);

  useEffect(() => {
    validateRights();
  }, [validateRights]);

  const contextValue = useMemo(() => ({ groups, fetchGroups }), [groups, fetchGroups]);

  return <SlidesetVersionGroupsContext.Provider value={contextValue}>{children}</SlidesetVersionGroupsContext.Provider>;
}

export function useSlidesetVersionGroups() {
  return useContext(SlidesetVersionGroupsContext);
}
