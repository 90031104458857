import { createTheme } from '@mui/material/styles';
import { blue, red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    drawerWidth: number;
  }
  interface ThemeOptions {
    drawerWidth: number;
  }
}

export default createTheme({
  drawerWidth: 240,
  palette: {
    primary: blue,
    secondary: red,
  },
});
