import React, { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import { getCurrentPagesForView, getViewport } from '../../../../selectors/codex-editor';
import ManualMarginsService from './services/manual-margins-service';
import { setObjectMoving } from '../../../../actions/codex-editor';
import { getCanvasDimensions } from './utils';

const useStyles = makeStyles(() => ({
  manualLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export default function ManualMarginsLayer() {
  const classes = useStyles();
  const manualMarkerService = useRef();
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();

  const viewport = useSelector(getViewport);
  const currentPages = useSelector(getCurrentPagesForView);

  useEffect(() => {
    manualMarkerService.current = new ManualMarginsService('manual-margins-canvas');
  }, []);

  useEffect(() => {
    const { width, height } = getCanvasDimensions({ viewport, currentPages });

    manualMarkerService.current.initialize(width, height);
  }, [viewport, currentPages]);

  useEffect(() => {
    if (values.manualMargins) {
      manualMarkerService.current.renderManualMargins(
        values.manualMargins,
        currentPages.length === 1,
        isMoving => dispatch(setObjectMoving(isMoving)),
        m => setFieldValue('manualMargins', m),
      );
    }
  }, [dispatch, currentPages, values?.manualMargins, setFieldValue, viewport]);

  return (
    <div className={classes.manualLayer}>
      <canvas id="manual-margins-canvas" data-testid="manual-margins-canvas" />
    </div>
  );
}
