import React, { useEffect, useState } from 'react';
import { tss } from 'tss-react/mui';
import { useSearchParams } from 'react-router-dom';

import { Alert, Button, CircularProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch } from '../../hooks/store';
import DetailView from '../../components/layouts/detailview';
import UploadFormControl from './components/uploader';
import { UploadMultiSourceForSlide } from '../../actions/secured-file-types';
import { uploadFileForDigislide } from '../../actions/secured-file';
import { getSlideSetVersion } from '../../api/slideSetVersion';
import { useSlidesetVersionGroups } from '../slideset-version-detail/context/slideset-version-groups-context';

type Params = {
  moduleId: string;
  nodeId: string;
  setId: string;
  versionId: string;
};

const useStyles = tss.create(() => ({
  main: {
    padding: '1rem',
  },
  formContainer: {
    maxWidth: '300px',
  },
  progress: {
    marginRight: '1rem',
  },
}));

type FormValues = {
  slidesSource?: File;
  revealsSource?: File;
};

function SlidesImporter() {
  const { fetchGroups } = useSlidesetVersionGroups();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams<Params>() as Required<Params>;
  const [searchParams] = useSearchParams();

  const replace = searchParams.get('replace') === 'true' || false;

  const [formValues, setFormValues] = useState<FormValues>({});

  const [isImporting, setIsImporting] = useState<boolean>(false);

  const handleFileSelected = (name: string, file: File) => {
    setFormValues({ ...formValues, [name]: file });
  };

  const [intervalCode, setIntervalCode] = useState<number | undefined>(undefined);

  function pollForImportStatus() {
    const interval = window.setInterval(async () => {
      const { isImporting: isConverting } = await getSlideSetVersion(
        params.moduleId,
        params.nodeId,
        params.setId,
        params.versionId,
      );

      if (!isConverting) {
        clearInterval(intervalCode);
        setIntervalCode(undefined);
        fetchGroups();
        navigate('..');
      }
    }, 5000);

    setIntervalCode(interval);
  }

  useEffect(() => {
    if (intervalCode) return () => clearInterval(intervalCode);

    return undefined;
  }, [intervalCode]);

  const startImport = async () => {
    try {
      if (isImporting) return;
      setIsImporting(true);

      const uploadSourceInfo: UploadMultiSourceForSlide = {
        type: 'multi',
        replace,
      };

      if (formValues.slidesSource) uploadSourceInfo.slide = formValues.slidesSource;
      if (formValues.revealsSource) uploadSourceInfo.reveal = formValues.revealsSource;

      const { setId, versionId, ...rest } = params;

      await dispatch(
        uploadFileForDigislide(
          uploadSourceInfo,
          {
            ...rest,
            slideSetId: Number(setId),
            versionId: Number(versionId),
          },
          'slides-importer',
          undefined,
        ),
      );

      pollForImportStatus();
    } catch (e) {
      setIsImporting(false);
    }
  };

  const clearFile = (name: string) => {
    if (isImporting) return;

    setFormValues({ ...formValues, [name]: undefined });
  };

  const disableImport = (!formValues.revealsSource && !formValues.slidesSource) || isImporting;

  return (
    <DetailView
      title={replace ? 'Replace all slides from PDF' : 'Import Slides from PDF'}
      previousPage=".."
      actionElement={
        <Button variant="text" onClick={startImport} disabled={disableImport}>
          <Typography color="white">IMPORT</Typography>
        </Button>
      }
    >
      <div className={classes.main}>
        {replace && (
          <Alert severity="warning">
            This action will update the existing slides. Slide source files may be overwritten.
          </Alert>
        )}
        {isImporting && (
          <Alert severity="warning">
            <CircularProgress className={classes.progress} size={20} />
            Importing slides. Do not leave this page until the import is complete.
          </Alert>
        )}
        <div className={classes.formContainer}>
          <UploadFormControl
            id="import-slides-source"
            displayName="slidesSource"
            label="Slides source"
            value={formValues.slidesSource}
            fileSelected={handleFileSelected}
            onClear={clearFile}
            isUploading={isImporting}
          />
          <UploadFormControl
            id="import-reveal-source"
            displayName="revealsSource"
            label="Reveal source"
            value={formValues.revealsSource}
            fileSelected={handleFileSelected}
            onClear={clearFile}
            isUploading={isImporting}
          />
        </div>
      </div>
    </DetailView>
  );
}

export default SlidesImporter;
