import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { object, arrayOf, func, number, string, bool } from 'prop-types';

import {
  getAnswerSetsForCurrentPages,
  getCurrentPagesForView,
  getViewport,
  getSelectedAnswerSetId,
  getIsDrawing,
  getSelectedAnswer,
} from '../../../../selectors/codex-editor';
import {
  setObjectMoving,
  editAndSaveAnswerSet,
  addAnswerToSet,
  setSelectedAnswer,
  setSelectedAnswerSet,
} from '../../../../actions/codex-editor';

import AnswerSetService from './services/answer-set-service';
import { updateWeights } from '../../../../utils/update-dnd-weights';
import { getCanvasDimensions } from './utils';

const styles = () => ({
  answerSetLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export class AnswerSetLayer extends React.PureComponent {
  componentDidMount() {
    const { viewport, currentPages } = this.props;
    const { width, height } = getCanvasDimensions({ viewport, currentPages });

    this.answerSetService = new AnswerSetService('answer-set-canvas');
    this.answerSetService.initialize(width, height);
    this.answerSetService.addSelectionListeners(this.onAreaDrawn);
    this.renderAnswerSets();
  }

  componentDidUpdate(prevProps) {
    const { isDrawing, viewport, currentPages } = this.props;

    if (isDrawing !== prevProps.isDrawing) this.answerSetService.setIsDrawingArea(isDrawing);

    const { width, height } = getCanvasDimensions({ viewport, currentPages });

    this.answerSetService.setDimensions(width, height);

    this.renderAnswerSets();
  }

  componentWillUnmount() {
    this.answerSetService.destroy();
  }

  renderAnswerSets = () => {
    const {
      answerSets,
      currentPages,
      selectedAnswerSetId,
      selectedAnswerId,
      setSelectedAnswerId,
      setSelectedSetId,
      scale,
    } = this.props;

    const isSinglePage = currentPages.length === 1;
    const currentSet = answerSets.find(set => set.id === selectedAnswerSetId);

    const answers = (currentSet && currentSet.answers) || [];

    this.answerSetService.drawAnswerSets(
      answerSets,
      this.setObjectMoving,
      this.commitSetChanges,
      setSelectedSetId,
      isSinglePage,
      selectedAnswerSetId,
      scale,
    );
    this.answerSetService.drawAnswers(
      answers,
      this.setObjectMoving,
      this.commitAnswerChanges,
      setSelectedAnswerId,
      isSinglePage,
      selectedAnswerId,
    );
  };

  onAreaDrawn = shape => {
    const { selectedAnswerSetId, addAnswerForSet, currentPages, digibookId } = this.props;
    const normalizedShape = currentPages.length === 1 ? AnswerSetService.normalizeShapeForSinglePage(shape) : shape;
    addAnswerForSet(digibookId, normalizedShape, selectedAnswerSetId);
  };

  setObjectMoving = isMoving => {
    const { setMoving } = this.props;

    setMoving(isMoving);
  };

  commitSetChanges = (shape, index) => {
    const { commitAnswerSetChanges, answerSets, digibookId } = this.props;
    const set = answerSets[index];
    const updated = {
      ...set,
      shape,
    };

    commitAnswerSetChanges(digibookId, updated);
  };

  commitAnswerChanges = shape => {
    const { selectedAnswerSetId, answerSets, commitAnswerSetChanges, digibookId } = this.props;

    const set = answerSets.find(x => x.id === selectedAnswerSetId);

    const nextAnswers = set.answers.map(a => {
      if (a.id === shape.id) {
        return { ...a, ...shape };
      }
      if (a.members) {
        return {
          ...a,
          members: a.members.map(m => {
            if (m.id === shape.id) {
              return { ...m, ...shape };
            }
            return m;
          }),
        };
      }
      return a;
    });

    commitAnswerSetChanges(digibookId, { ...set, answers: updateWeights(nextAnswers) });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.answerSetLayer}>
        <canvas id="answer-set-canvas" />
      </div>
    );
  }
}

AnswerSetLayer.propTypes = {
  digibookId: string.isRequired,
  classes: object,
  viewport: object,
  answerSets: arrayOf(object),
  setMoving: func.isRequired,
  commitAnswerSetChanges: func.isRequired,
  currentPages: arrayOf(number),
  selectedAnswerSetId: string,
  selectedAnswerId: string,
  setSelectedAnswerId: func.isRequired,
  isDrawing: bool,
  addAnswerForSet: func.isRequired,
  setSelectedSetId: func.isRequired,
  scale: number.isRequired,
};

AnswerSetLayer.defaultProps = {
  classes: {},
  viewport: {
    width: 0,
    height: 0,
  },
  answerSets: [],
  currentPages: [],
  selectedAnswerSetId: undefined,
  selectedAnswerId: undefined,
  isDrawing: false,
};

const mapStateToProps = state => ({
  viewport: getViewport(state),
  currentPages: getCurrentPagesForView(state),
  answerSets: getAnswerSetsForCurrentPages(state),
  selectedAnswerSetId: getSelectedAnswerSetId(state),
  selectedAnswerId: getSelectedAnswer(state),
  isDrawing: getIsDrawing(state),
  scale: state.codexEditor.scale,
});

const mapDispatchToProps = {
  setMoving: setObjectMoving,
  commitAnswerSetChanges: editAndSaveAnswerSet,
  addAnswerForSet: addAnswerToSet,
  setSelectedAnswerId: setSelectedAnswer,
  setSelectedSetId: setSelectedAnswerSet,
};

export const ConnectedAnswerSetLayer = connect(mapStateToProps, mapDispatchToProps)(AnswerSetLayer);
export default withStyles(styles)(ConnectedAnswerSetLayer);
