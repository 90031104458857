import React from 'react';
import { tss } from 'tss-react/mui';
import { useDrag, useDrop } from 'react-dnd';

import { SlideGroup } from '../../../api/slideSet';

import { DND_TYPES } from '../drag-and-drop-utils';
import DropZone from './drop-zone';
import SlideDisplay from './slide-display';

type GroupSlideProps = {
  group: SlideGroup;
  slide: SlideGroup['slides'][number];
  indexString: string;
  onMove: (groupId: number, newSlideOrder: SlideGroup['slides'][number][]) => void;
  refetchData: () => void;
};

const useStyles = tss.create(() => ({
  groupSlides: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: '2rem',
  },
}));

type Item = { group: SlideGroup; slide: SlideGroup['slides'][number] };

export default function GroupSlide({ group, slide, indexString, onMove, refetchData }: GroupSlideProps) {
  const { classes } = useStyles();

  const type = DND_TYPES.GROUP_SLIDE;
  const [{ isDragging }, dragRef, previewRef] = useDrag(
    () => ({
      type,
      item: { group, slide, type },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [group, slide],
  );

  const accept = [DND_TYPES.GROUP_SLIDE];

  function move(array, from, to, on = 1) {
    const newArray = array.slice();
    newArray.splice(to, 0, ...newArray.splice(from, on));

    return newArray;
  }

  const [{ isOverTop }, dropTop] = useDrop<Item, unknown, { isOverTop: boolean }>(
    () => ({
      accept,
      collect: monitor => ({
        isOverTop: monitor.isOver() && monitor.getItem().group.id === group.id,
      }),
      drop: item => {
        if (item.group !== group) return;

        const originalIndex = group.slides.findIndex(s => s.id === item.slide.id);
        let targetIndex = group.slides.findIndex(s => s.id === slide.id);

        if (originalIndex < targetIndex) {
          targetIndex -= 1;
        }

        if (originalIndex === targetIndex) return;

        onMove(group.id, move(group.slides, originalIndex, targetIndex, 1));
      },
    }),
    [group, slide],
  );

  const [{ isOverBottom }, dropBottom] = useDrop<Item, unknown, { isOverBottom: boolean }>(() => {
    return {
      accept,
      collect: monitor => ({
        isOverBottom: monitor.isOver() && monitor.getItem().group.id === group.id,
      }),
      drop: item => {
        if (item.group.id !== group.id) return;

        const originalIndex = group.slides.findIndex(s => s.id === item.slide.id);
        let targetIndex = group.slides.findIndex(s => s.id === slide.id);

        if (originalIndex > targetIndex) {
          targetIndex += 1;
        }

        if (originalIndex === targetIndex) return;

        onMove(group.id, move(group.slides, originalIndex, targetIndex, 1));
      },
    };
  }, [group, slide]);

  const needsTopDropzone = group.slides.findIndex(s => s.id === slide.id) === 0;

  return (
    <div data-testid="group-slide" className={classes.groupSlides} ref={previewRef}>
      {needsTopDropzone && (
        <DropZone isOver={isOverTop} ref={dropTop} data-testid={`drop-zone-${group.id}-${slide.id}-top`} />
      )}
      <SlideDisplay
        refetchData={refetchData}
        group={group}
        slide={slide}
        indexString={indexString}
        isDragging={isDragging}
        ref={dragRef}
      />
      <DropZone isOver={isOverBottom} ref={dropBottom} data-testid={`drop-zone-${group.id}-${slide.id}-bottom`} />
    </div>
  );
}
