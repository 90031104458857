import { DragIndicator } from '@mui/icons-material';
import React from 'react';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
  drag: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
}));

function DragIcon({ dragRef }) {
  const { classes } = useStyles();

  return (
    <div ref={dragRef} className={classes.drag}>
      <DragIndicator data-testid="drag-indicator" />
    </div>
  );
}

export default React.forwardRef((props, ref) => <DragIcon {...props} dragRef={ref} />);
