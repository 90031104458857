/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Select } from '@mui/material';
import { getSuperModulesForModule } from '../../selectors/super-module';

export class SuperModuleSelect extends Component {
  componentDidMount() {
    this.setSuperModule();
  }

  componentDidUpdate(prevProps) {
    const { moduleId } = this.props;
    if (moduleId !== prevProps.moduleId) {
      this.setSuperModule();
    }
  }

  setSuperModule() {
    const { superModules, onChange, moduleId } = this.props;
    if (superModules.length === 0) {
      onChange(moduleId);
    }
    if (superModules.length === 1) {
      onChange(superModules[0].id);
    }
  }

  render() {
    const {
      inputProps: { id, name, className },
      onChange,
      superModules,
      value,
    } = this.props;

    return (
      <Select
        variant="standard"
        className={className}
        id={id}
        name={name}
        native
        onChange={e => onChange(e.target.value)}
        value={value}
        disabled={superModules.length <= 1}
      >
        <option value="" />
        {superModules.map(superModule => (
          <option value={superModule.id} key={superModule.id}>
            {superModule.name}
          </option>
        ))}
      </Select>
    );
  }
}

SuperModuleSelect.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
  }),
  onChange: PropTypes.func,
  moduleId: PropTypes.string,
  value: PropTypes.string,
  superModules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

SuperModuleSelect.defaultProps = {
  inputProps: {},
  onChange: () => {},
  superModules: [],
  value: undefined,
  moduleId: undefined,
};

const mapStateToProps = (state, { moduleId, methodId }) => ({
  superModules: getSuperModulesForModule(moduleId)(state, { methodId }),
});

export default connect(mapStateToProps)(SuperModuleSelect);
