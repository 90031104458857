import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl } from '@mui/material';

import MediaLinkTable from './media-link-table';
import TocSelector from './media-link-toc-selector';

import { getCurrentNodeForMediaContext } from '../../../../../selectors/codex-editor';
import { getCurrentHierarchyModuleId } from '../../../../../selectors/context';
import { getMediaLinksForNodeId } from '../../../../../selectors/medialinks';
import { getTocNodes, getTocNodesWithPageMapping } from '../../../../../selectors/table-of-content';

import { setCurrentNodeForMediaContext } from '../../../../../actions/codex-editor';
import { fetchMediaLinksForNode } from '../../../../../actions/medialinks';

import tableOfContentConstants from '../../../../../constants/table-of-content';
import { hasSupportedEmbedType, isMiniSite } from '../../../../../utils/fileType';

function filterMediaLinks(mediaLink) {
  if (!mediaLink.published && mediaLink.reviewState === 0) return false;

  const allowedFileTypes = ['audio/', 'video/'];

  const mimeType = mediaLink?.file?.s3file?.mimeType;

  if (mimeType) return allowedFileTypes.some(type => mimeType.startsWith(type));

  return isMiniSite(mediaLink) || hasSupportedEmbedType(mediaLink);
}

export class AddMedialinkModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMedialinkIds: [],
    };
  }

  componentDidMount() {
    const { fetchMediaLinks, moduleId, currentNode } = this.props;

    if (currentNode) {
      fetchMediaLinks(moduleId, currentNode);
    }
  }

  handleTocChange = e => {
    const { onNodeChanged, moduleId, fetchMediaLinks, currentNode } = this.props;
    const newNode = e.target.value;

    if (newNode !== currentNode) {
      onNodeChanged(newNode);
      this.setState({ selectedMedialinkIds: [] });
      if (newNode) {
        fetchMediaLinks(moduleId, newNode);
      }
    }
  };

  handleSelectAllClick = event => {
    const { mediaLinksForNode } = this.props;
    if (event.target.checked) {
      this.setState({ selectedMedialinkIds: mediaLinksForNode.map(n => n.id) });
      return;
    }
    this.setState({ selectedMedialinkIds: [] });
  };

  handleMedialinkSelect = (event, id) => {
    const { selectedMedialinkIds } = this.state;
    const { multipleSelection } = this.props;

    if (!multipleSelection) return this.setState({ selectedMedialinkIds: [id] });

    const selectedIndex = selectedMedialinkIds.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedMedialinkIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedMedialinkIds.slice(1));
    } else if (selectedIndex === selectedMedialinkIds.length - 1) {
      newSelected = newSelected.concat(selectedMedialinkIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedMedialinkIds.slice(0, selectedIndex),
        selectedMedialinkIds.slice(selectedIndex + 1),
      );
    }

    return this.setState({ selectedMedialinkIds: newSelected });
  };

  isSelected = id => {
    const { selectedMedialinkIds } = this.state;
    return selectedMedialinkIds.indexOf(id) !== -1;
  };

  render() {
    const { handleClose, handleSave, mediaLinksForNode, tocNodes, medialinks, currentNode, multipleSelection } =
      this.props;
    const { selectedMedialinkIds } = this.state;
    return (
      <Dialog open onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">Add Media Link</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" fullWidth>
            <TocSelector currentNode={currentNode} tocNodes={tocNodes} handleChange={this.handleTocChange} />
          </FormControl>
          <MediaLinkTable
            mediaLinks={mediaLinksForNode.filter(ml => !medialinks.includes(ml.id))}
            handleSelection={this.handleMedialinkSelect}
            allSelected={selectedMedialinkIds.length > 0 && selectedMedialinkIds.length === mediaLinksForNode.length}
            handleSelectAll={this.handleSelectAllClick}
            isSelected={this.isSelected}
            multipleSelect={multipleSelection}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSave(selectedMedialinkIds)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddMedialinkModal.propTypes = {
  medialinks: arrayOf(string),
  mediaLinksForNode: arrayOf(
    shape({
      id: string,
      name: string,
      publishingState: string,
      contentType: string,
      hierarchy: arrayOf(string),
    }),
  ),
  tocNodes: arrayOf(
    shape({
      id: string,
      level: number,
      name: string,
    }),
  ),
  fetchMediaLinks: func.isRequired,
  handleClose: func.isRequired,
  handleSave: func.isRequired,
  onNodeChanged: func.isRequired,
  moduleId: string.isRequired,
  currentNode: string,
  multipleSelection: bool,
};

AddMedialinkModal.defaultProps = {
  medialinks: [],
  mediaLinksForNode: [],
  tocNodes: [],
  currentNode: '',
  multipleSelection: true,
};

const mapStateToProps = (state, props) => {
  const { pageMapping, filterMediaLinksForActiveArea } = props;
  const moduleId = getCurrentHierarchyModuleId(state);
  const currentNode = getCurrentNodeForMediaContext(state);
  const nodeId = currentNode && currentNode === tableOfContentConstants.GENERAL_NODE_ID ? moduleId : currentNode;
  const tocNodes = pageMapping ? getTocNodesWithPageMapping(state, moduleId) : getTocNodes(state, moduleId);
  const mediaLinks = getMediaLinksForNodeId(state, nodeId);
  const mediaLinksForNode = filterMediaLinksForActiveArea ? mediaLinks.filter(filterMediaLinks) : mediaLinks;

  return {
    mediaLinksForNode,
    moduleId,
    tocNodes,
    currentNode,
  };
};

const mapDispatchToProps = {
  fetchMediaLinks: fetchMediaLinksForNode,
  onNodeChanged: setCurrentNodeForMediaContext,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMedialinkModal);
