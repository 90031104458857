import React, { useEffect } from 'react';
import { func, bool, number } from 'prop-types';
import { Card, Checkbox, FormControlLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import Pagination from './pagination';
import Zoom from './zoom';
import { getActiveTab } from '../../../../../selectors/codex-editor';

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: '#ECECEC',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 15px',
    fontSize: '13px',
    zIndex: 5,
    marginBottom: 10,
  },
}));

export default function Toolbar({
  toggleAnswer,
  answerDisabled,
  setScale,
  scale,
  currentPage,
  setPage,
  totalPages,
  viewMode,
  zoomToFit,
  toggleManual,
  manualDisabled,
  answerToggled,
  manualToggled,
  toggleBook,
  bookToggled,
}) {
  const classes = useStyles();

  const activeTab = useSelector(getActiveTab);

  useEffect(() => {
    if (manualDisabled) toggleManual(false);
  }, [manualDisabled, toggleManual]);

  return (
    <Card className={classes.toolbar}>
      {activeTab !== 4 && (
        <div style={{ userSelect: 'none', margin: '0 8px' }}>
          <FormControlLabel
            onChange={e => {
              toggleAnswer(e.target.checked);
            }}
            control={<Checkbox color="primary" disabled={answerDisabled} checked={answerToggled} />}
            label="show answer layer"
          />
        </div>
      )}
      {activeTab === 0 && (
        <div style={{ userSelect: 'none', margin: '0 8px' }}>
          <FormControlLabel
            onChange={e => {
              toggleManual(e.target.checked);
            }}
            control={<Checkbox color="primary" disabled={manualDisabled} checked={manualToggled} />}
            label="show manual layer"
          />
        </div>
      )}
      {activeTab === 4 && (
        <div style={{ userSelect: 'none', margin: '0 8px' }}>
          <FormControlLabel
            onChange={e => {
              toggleBook(e.target.checked);
            }}
            control={<Checkbox color="primary" checked={bookToggled} />}
            label="show book"
          />
        </div>
      )}
      <Pagination currentPage={currentPage} setPage={setPage} totalPages={totalPages} viewMode={viewMode} />
      <Zoom scale={scale} setScale={setScale} zoomToFit={zoomToFit} />
    </Card>
  );
}

Toolbar.propTypes = {
  toggleAnswer: func.isRequired,
  answerDisabled: bool.isRequired,
  setScale: func.isRequired,
  scale: number.isRequired,
  currentPage: number.isRequired,
  setPage: func.isRequired,
  zoomToFit: func.isRequired,
  totalPages: number.isRequired,
  viewMode: number.isRequired,
  toggleManual: func.isRequired,
  manualDisabled: bool.isRequired,
  answerToggled: bool.isRequired,
  manualToggled: bool.isRequired,
  toggleBook: func.isRequired,
  bookToggled: bool.isRequired,
};
