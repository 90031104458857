import React, { ComponentProps, useContext } from 'react';
import { IconButton } from '@mui/material';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import CopyToSlideSetPopper from './CopyToSlideSetPopper';
import { SlideSetVersion, copySlideSetVersion } from '../../../api/slideSetVersion';
import { CopyOverMethodsContext } from '../context/CopyOverMethodsContext';
import SlideSetVersionContext from '../../../contexts/slideset-version-context';

type Params = {
  moduleId: string;
  nodeId: string;
  setId: string;
};

type Props = {
  version: SlideSetVersion;
};

export default function CopyToNewSlideSetAction({ version }: Props) {
  const { openCopyDialog, versionIdToCopy, closeCopy } = useContext(CopyOverMethodsContext);
  const { refreshVersions } = useContext(SlideSetVersionContext);
  const [buttonElement, setButtonElement] = React.useState<HTMLButtonElement | undefined>(undefined);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    setButtonElement(e.currentTarget);
    openCopyDialog(version.id);
  };

  const params = useParams<Params>();
  const navigate = useNavigate();

  const handleConfirm: ComponentProps<typeof CopyToSlideSetPopper>['onConfirm'] = async target => {
    const { setId, moduleId, nodeId } = params;
    if (!setId || !moduleId || !nodeId) return;

    const requestParams = {
      moduleId,
      nodeId,
      slideSetId: setId,
      versionId: version.id.toString(),
    };

    const { slideSetId } = await copySlideSetVersion(requestParams, target);

    const isInOtherSlideSet = nodeId !== target.nodeId || (target.nodeId === 'general' && moduleId !== target.moduleId);

    if (isInOtherSlideSet) {
      navigate(`/modules/${target.moduleId}/table-of-content/${target.nodeId}/slide-sets/${slideSetId}`);
    } else {
      closeCopy();
      refreshVersions();
    }
  };

  const handleDialogClose = () => {
    setButtonElement(undefined);
    closeCopy();
  };

  return (
    <>
      <IconButton color="primary" size="large" onClick={handleClick}>
        <FolderCopyOutlinedIcon titleAccess="Copy to new slide set" />
      </IconButton>
      {buttonElement && (
        <CopyToSlideSetPopper
          isOpen={version.id === versionIdToCopy}
          anchorEl={buttonElement}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
      )}
    </>
  );
}
