import api from '../services/api';

export type SchoolYear = {
  id: number;
  startDate: string;
  endDate: string;
  studioGroup: 'ES' | 'SO' | 'VO' | 'BAO';
  isActive: boolean;
};

export const getSchoolYears = async (publishingHouse: string): Promise<SchoolYear[]> => {
  const { data } = await api.get(`/studio-groups/${publishingHouse}/school-years`);
  return data;
};

export const patchSchoolYear = async ({
  id,
  startDate,
  endDate,
}: {
  id: number;
  startDate: string;
  endDate: string;
}): Promise<void> => {
  const { data } = await api.patch(`/shell/studio-groups/school-years/${id}`, {
    startDate,
    endDate,
  });
  return data;
};
