import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { tss } from 'tss-react/mui';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { useAppSelector } from '../../hooks/store';
import { getCurrentPublishingHouse } from '../../selectors/publishing-house';
import { MaintenanceMessage, fetchMaintenanceMessage, patchMaintenanceMessage } from './api';
import { defaultTranslations } from './default-translations';

const useStyles = tss.create(({ theme }) => ({
  root: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const initialValues = {
  isActive: false,
  title: '',
  message: '',
};

type CustomValues = {
  isActive: boolean;
  title: string;
  message: string;
};

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  message: Yup.string().required('Message is required'),
});

const MaintenanceMode = () => {
  const { classes } = useStyles();
  const publishingHouse = useAppSelector<{ group: string }>(getCurrentPublishingHouse);
  const studioGroup = publishingHouse?.group;
  const [customValues, setCustomValues] = useState<CustomValues>(initialValues);

  useEffect(() => {
    if (!publishingHouse?.group) return;

    fetchMaintenanceMessage(publishingHouse.group).then(data => {
      setCustomValues({
        isActive: Boolean(data?.isActive),
        title: data?.title || '',
        message: data?.message || '',
      });
    });
  }, [publishingHouse]); // use publishingHouse instead of studioGroup because Lager onderwijs en kleuteronderwijs have the same studioGroup

  const save = useMemo(
    () =>
      debounce((data: Partial<MaintenanceMessage>) => {
        if (studioGroup) {
          return patchMaintenanceMessage(studioGroup, data);
        }

        return Promise.resolve();
      }, 1000),
    [studioGroup],
  );

  useEffect(() => {
    return () => {
      save.flush();
    };
  }, [save]);

  const formik = useFormik({
    initialValues: {
      title: customValues.title,
      message: customValues.message,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      return patchMaintenanceMessage(studioGroup, values);
    },
  });

  return (
    <div className={classes.root}>
      {!studioGroup ? (
        <Grid container maxWidth="600px" gap={2}>
          <h2>Please select a studio group first</h2>
        </Grid>
      ) : (
        <>
          <Grid container maxWidth="600px" gap={2}>
            <TextField
              id="defaultTitle"
              disabled
              label="Default Title"
              variant="outlined"
              value={defaultTranslations[`TITLE--${studioGroup}`]}
              fullWidth
            />
            <TextField
              id="defaultMessage"
              disabled
              label="Default Message"
              variant="outlined"
              value={defaultTranslations[`MESSAGE--${studioGroup}`]}
              fullWidth
              multiline
            />
          </Grid>
          <Grid container>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      save({ isActive: e.target.checked });
                      setCustomValues({ ...customValues, isActive: e.target.checked });
                    }}
                    checked={customValues.isActive}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Use custom"
              />
            </FormGroup>
          </Grid>
        </>
      )}
      {customValues.isActive && (
        <form onSubmit={formik.handleSubmit}>
          <Grid container maxWidth="600px" gap={2}>
            <TextField
              id="title"
              name="title"
              label="Custom Title"
              variant="outlined"
              fullWidth
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
            />
            <TextField
              id="message"
              name="message"
              label="Custom Message"
              variant="outlined"
              fullWidth
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.message && Boolean(formik.errors.message)}
            />
            <Button variant="text" type="submit">
              Save
            </Button>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default MaintenanceMode;
