type RequiredFields<T extends Record<string, any>, K extends keyof T> = T & Required<Pick<T, K>>;

const webLinkTargets = {
  MODAL: 'modal',
  NEW_WINDOW: 'new-window',
  SAME_WINDOW: 'same-window',
} as const;

type User = {
  id: string;
  firstName: string;
  lastName: string;
};

type ContentType = {
  id: string;
  name: string;
  category: string;
  icon?: string;
  iconClassName?: string;
  printable: boolean;
  isExercise: boolean;
  imagePreview: boolean;
  faqTutorial?: string;
};

enum MedialinkReviewState {
  NOT_IN_REVIEW = 0,
  IN_REVIEW = 1,
  ACCEPTED = 2,
  DECLINED = 3,
}

type MedialinkWordlist = { id: string };

type MedialinkFile = {
  s3file: {
    id: string;
    originalName: string;
    size: number;
    uploadDate: string;
    mimeType: string;
    preview?: string;
  };
  linkedMedia?: string;
  description?: string;
  forceDownload: boolean;
};

type MedialinkMiniSite = typeof webLinkTargets;

type MedialinkMiniDialog = { id: string };

type MedialinkEduhint = {
  id: string;
  showScore: boolean;
};

type MedialinkEdumatic = {
  id: string;
  showScore: boolean;
};

type ExternalMedia = {
  type: 'weblink' | 'geogebra' | 'h5p' | 'youtube';
  embedId?: string;
  href?: string;
  width?: number;
  height?: number;
  geogebra?: object;
  start?: number;
  end?: number;
};

type TeacherAssignment = {
  id?: string;
  from: string;
  to: string;
  repeatable: boolean;
};

type StudentAssignmentBase = {
  from: string;
  to: string;
  repeatable: boolean;
  current: boolean;
  user: User;
};

type PlannedStudentAssignment = StudentAssignmentBase & { id: undefined };
type OnGoingStudentAssignment = StudentAssignmentBase & { id: string };
type StudentAssignment = PlannedStudentAssignment | OnGoingStudentAssignment;
type Assignment = TeacherAssignment | StudentAssignment;

type Medialink = {
  id: string;
  kind:
    | 'exercise-edumatic'
    | 'exercise-eduhint'
    | 'word-list'
    | 'file'
    | 'mini-site'
    | 'mini-dialog'
    | 'external-media';
  moduleId: string;
  name: string;
  group: string;
  reviewState: MedialinkReviewState;
  contentType: ContentType;
  exerciseCategories?: {
    id: string;
    name: string;
  }[];
  hierarchy: Node[];
  amountOfItems?: number;
  score?: {
    amountOfItems: number;
    maximum: number;
    achievable: number;
    achieved: number;
  };
  updatedAt: string;
  shares: {
    id: string;
    user?: User;
  }[];
  assignments: Assignment[];
  wordList?: MedialinkWordlist;
  file?: MedialinkFile;
  externalMedia?: ExternalMedia;
  edumaticExercise?: MedialinkEdumatic;
  eduhintExercise?: MedialinkEduhint;
  miniDialog?: MedialinkMiniDialog;
  miniSite?: MedialinkMiniSite;
  jwplayer?: { id: string; ready: boolean };
};

type MiniSiteMedialink = RequiredFields<
  Omit<
    Medialink,
    'wordList' | 'file' | 'externalMedia' | 'edumaticExercise' | 'eduhintExercise' | 'miniDialog' | 'kind'
  >,
  'miniSite'
> & { kind: 'mini-site' };

export const isMiniSite = (medialink: Medialink): medialink is MiniSiteMedialink => {
  return medialink.kind === 'mini-site';
};

export const hasSupportedEmbedType = (medialink: Medialink) => {
  const type = medialink.externalMedia?.type;
  return type === 'weblink' || type === 'geogebra' || type === 'youtube';
};
