import get from 'get-value';
import { normalize, schema } from 'normalizr';
import api from '../services/api';

import { SubjectActionType } from '../action-types';

const fetchSubjectsByPublishingHouseRequested = publishingHouseId => ({
  type: SubjectActionType.FETCH_SUBJECTS_BY_PUBLISHING_HOUSE,
  payload: {
    publishingHouseId,
  },
});

const fetchSubjectsByPublishingHouseSucces = (publishingHouseId, entities, ids) => ({
  type: SubjectActionType.FETCH_SUBJECTS_BY_PUBLISHING_HOUSE_SUCCESS,
  payload: {
    publishingHouseId,
    entities,
    ids,
  },
});

const fetchSubjectsByPublishingHouseFailed = publishingHouseId => ({
  type: SubjectActionType.FETCH_SUBJECTS_BY_PUBLISHING_HOUSE_FAILURE,
  payload: {
    publishingHouseId,
  },
});

/**
 * Fetch the subjects for the specified publishingHouse.
 *
 * @export
 * @param {string} publishingHouseId
 */
export function forceFetchSubjectsByPublishingHouse(publishingHouseId) {
  return dispatch => {
    dispatch(fetchSubjectsByPublishingHouseRequested(publishingHouseId));

    return api
      .get('/shell/subjects', {
        params: {
          orderBy: 'name',
          publishingHouseId,
        },
      })
      .then(({ data: { data } }) => {
        const {
          entities: { subjects: entities },
          result: ids,
        } = normalize(data, [new schema.Entity('subjects')]);

        dispatch(fetchSubjectsByPublishingHouseSucces(publishingHouseId, entities, ids));
      })
      .catch(err => {
        dispatch(fetchSubjectsByPublishingHouseFailed(publishingHouseId));
        throw err;
      });
  };
}

/**
 * Fetch the subjects for the specified publishingHouse only if not already present in state.
 *
 * @export
 * @param {sting} publishingHouseId
 */
export function fetchSubjectsByPublishingHouse(publishingHouseId) {
  return (dispatch, getState) => {
    const publishingHouseData = get(getState(), ['subject', 'byPublishingHouse', publishingHouseId]) || {};

    if (publishingHouseData.isFetching || publishingHouseData.lastUpdated) return Promise.resolve();

    return dispatch(forceFetchSubjectsByPublishingHouse(publishingHouseId));
  };
}
