import api from '../../services/api';

export type MaintenanceMessage = {
  title: string;
  message: string;
  studioGroup: string;
  isActive: boolean;
};

export async function fetchMaintenanceMessage(studioGroup: string) {
  const { data } = await api.get<MaintenanceMessage | null>(`/studio-groups/${studioGroup}/maintenance-message`);
  return data;
}

export async function patchMaintenanceMessage(
  studioGroup: string,
  { studioGroup: _, ...body }: Partial<MaintenanceMessage>,
) {
  const { data } = await api.patch(`/shell/studio-groups/${studioGroup}/maintenance-message`, body);
  return data;
}
