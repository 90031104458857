import { NotificationActionType } from '../action-types';

const initialState = {
  maintenance: false,
  notifications: {},
  queue: [],
};

export default function notificationReducer(state = initialState, action = {}) {
  switch (action && action.type) {
    case NotificationActionType.SHOW: {
      const { notifications, queue, maintenance } = state;

      return {
        notifications: {
          ...notifications,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        queue: [...queue, action.payload.id],
        maintenance,
      };
    }
    case NotificationActionType.DISMISS: {
      const { id } = action.payload;
      const {
        notifications: { [id]: notification, ...rest },
        queue,
        maintenance,
      } = state;

      return {
        notifications: {
          ...rest,
        },
        queue: queue.filter(x => x !== id),
        maintenance,
      };
    }
    case NotificationActionType.MAINTENANCE: {
      const { notifications, queue } = state;

      return {
        maintenance: true,
        notifications,
        queue,
      };
    }
    default:
      return state;
  }
}
