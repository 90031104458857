import React from 'react';
import { Edit } from '@mui/icons-material';
import LinkIconButton from '../../../components/buttons/link-icon-button';
import { SlideSetVersion } from '../../../api/slideSetVersion';
import { SlideSetVersionState } from '../../../api/slideSet';

type EditProps = {
  version: SlideSetVersion;
};

const EditAction = ({ version }: EditProps) => {
  const isDisabled =
    version.state === SlideSetVersionState.ARCHIVED || version.state === SlideSetVersionState.PUBLISHED;

  return (
    <LinkIconButton
      disabled={isDisabled}
      buttonProps={{
        id: 'edit',
        size: 'large',
        color: 'primary',
      }}
      to={`versions/${version.id}`}
    >
      <span title="Edit">
        <Edit />
      </span>
    </LinkIconButton>
  );
};

export default EditAction;
