import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'get-value';
import classNames from 'classnames';

import { MenuItem, InputLabel, FormControl, TextField, FormHelperText } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import getDigibooksByModule from '../../../selectors/digibook';
import { initDefaultDigibookLink, initDigibookLink, setHierarchyForDigibookLink } from '../../../actions/codex-editor';
import { fetchDigibooksByModule } from '../../../actions/digibook';
import PublishingHouseSelect from '../../../components/hierarchy/publishing-house-select';
import SubjectSelect from '../../../components/hierarchy/subject-select';
import MethodSelect from '../../../components/hierarchy/method-select';
import ModuleSelect from '../../../components/hierarchy/module-select';
import SuperModuleSelect from '../../../components/hierarchy/super-module-select';
import { getModuleById } from '../../../selectors/module';
import moduleType from '../../../constants/module-type';

const styles = {
  select: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
};

export class LinkAreaDigibookLinker extends React.PureComponent {
  componentDidMount() {
    const { initDigibookLinkFor, digibookLink, initDefault, currentDigibook } = this.props;

    return digibookLink && digibookLink.digibook
      ? initDigibookLinkFor(digibookLink.digibook)
      : initDefault(currentDigibook);
  }

  componentWillUnmount() {
    const { setHierarchyForLink } = this.props;

    return setHierarchyForLink(undefined);
  }

  setModule = moduleId => {
    const { publishingHouseId, subjectId, methodId, setHierarchyForLink, fetchDigibooksFor, setFieldValue } =
      this.props;

    setHierarchyForLink(publishingHouseId, subjectId, methodId, moduleId);
    setFieldValue('digibookLink.superModuleId', undefined);
    return fetchDigibooksFor(moduleId);
  };

  setSuperModule = superModuleId => {
    const { setFieldValue } = this.props;

    const sId = superModuleId === '' ? undefined : superModuleId;

    setFieldValue('digibookLink.superModuleId', sId);
  };

  changeDigibook = e => {
    const { setFieldValue } = this.props;
    const digibook = e.target.value;
    setFieldValue('digibookLink.pageNumber', undefined);
    setFieldValue('digibookLink.digibook', digibook);
  };

  changePageNumber = e => {
    const { setFieldValue } = this.props;

    const parsedPageNumber = Math.floor(Number(e.target.value));

    setFieldValue('digibookLink.pageNumber', parsedPageNumber);
  };

  render() {
    const {
      digibookLink,
      publishingHouseId,
      subjectId,
      methodId,
      moduleId,
      digibooks,
      setHierarchyForLink,
      classes,
      errors,
      currentModule,
    } = this.props;
    return (
      <>
        <FormControl variant="standard" margin="dense" className={classes.select} id="publishing-house">
          <InputLabel htmlFor="publishing-house">Publishing house</InputLabel>
          <PublishingHouseSelect
            className={classes.select}
            publishingHouseId={publishingHouseId || ''}
            setPublishingHouseId={phId => setHierarchyForLink(phId)}
            clearPublishingHouseId={() => setHierarchyForLink(undefined)}
          />
        </FormControl>
        {publishingHouseId && (
          <FormControl variant="standard" margin="dense" className={classes.select}>
            <InputLabel>Subject</InputLabel>
            <SubjectSelect
              inputProps={{ className: classes.select }}
              value={subjectId}
              publishingHouseId={publishingHouseId}
              onChange={id => setHierarchyForLink(publishingHouseId, id)}
            />
          </FormControl>
        )}
        {subjectId && (
          <FormControl variant="standard" margin="dense" className={classes.select}>
            <InputLabel>Method</InputLabel>
            <MethodSelect
              inputProps={{ className: classes.select }}
              value={methodId}
              subjectId={subjectId}
              onChange={id => setHierarchyForLink(publishingHouseId, subjectId, id)}
            />
          </FormControl>
        )}
        {methodId && (
          <FormControl variant="standard" margin="dense" className={classes.select}>
            <InputLabel>Module</InputLabel>
            <ModuleSelect
              inputProps={{ className: classes.select }}
              value={moduleId}
              methodId={methodId}
              onChange={this.setModule}
            />
          </FormControl>
        )}
        {moduleId && (
          <FormControl
            variant="standard"
            margin="dense"
            error={errors && !!errors.superModuleId}
            className={classNames(classes.select, {
              [classes.hidden]: currentModule && currentModule.type === moduleType.SUPER,
            })}
          >
            <InputLabel>Super Module</InputLabel>
            <SuperModuleSelect
              inputProps={{ className: classes.select }}
              moduleId={moduleId}
              methodId={methodId}
              onChange={this.setSuperModule}
              value={(digibookLink && digibookLink.superModuleId) || ''}
            />
            {errors && errors.superModuleId && (
              <FormHelperText error id="page-error">
                Super Module is required
              </FormHelperText>
            )}
          </FormControl>
        )}
        {moduleId && (
          <TextField
            variant="standard"
            id="digibook-select"
            label="Digibook"
            margin="dense"
            className={classes.select}
            fullWidth
            onChange={this.changeDigibook}
            value={(digibookLink && digibookLink.digibook) || ''}
            select
            error={errors && !!errors.digibook}
          >
            <MenuItem value="" />
            {digibooks &&
              digibooks.map(digibook => (
                <MenuItem key={digibook.id} value={digibook.id}>
                  {digibook.name}
                </MenuItem>
              ))}
          </TextField>
        )}
        {errors && errors.digibook && (
          <FormHelperText error id="digibook-error">
            Digibook is required
          </FormHelperText>
        )}
        {digibookLink && digibookLink.digibook && (
          <>
            <TextField
              variant="standard"
              className={classes.select}
              fullWidth
              margin="dense"
              label="Page"
              value={digibookLink.pageNumber || ''}
              onChange={this.changePageNumber}
              id="pagenumber-field"
              type="number"
              error={errors && !!errors.pageNumber}
            />
            {errors && errors.pageNumber && (
              <FormHelperText error id="page-error">
                Number is required
              </FormHelperText>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const resolvedModule = get(state, ['codexEditor', 'digibookLinkContext', 'moduleId']);

  return {
    currentDigibook: get(state, ['codexEditor', 'initial', 'id']),
    publishingHouseId: get(state, ['codexEditor', 'digibookLinkContext', 'publishingHouseId']),
    subjectId: get(state, ['codexEditor', 'digibookLinkContext', 'subjectId']),
    methodId: get(state, ['codexEditor', 'digibookLinkContext', 'methodId']),
    moduleId: get(state, ['codexEditor', 'digibookLinkContext', 'moduleId']),
    currentModule: getModuleById(state, resolvedModule),
    digibooks: resolvedModule && getDigibooksByModule(state, { moduleId: resolvedModule }),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  initDigibookLinkFor: digibook => dispatch(initDigibookLink(digibook)),
  setHierarchyForLink: (publishingHouseId, subjectId, methodId, moduleId) =>
    dispatch(setHierarchyForDigibookLink(publishingHouseId, subjectId, methodId, moduleId)),
  fetchDigibooksFor: module => dispatch(fetchDigibooksByModule(module)),
  initDefault: digibookId => {
    ownProps.setFieldValue('digibookLink.digibook', digibookId);
    return dispatch(initDefaultDigibookLink(digibookId));
  },
});

LinkAreaDigibookLinker.propTypes = {
  initDigibookLinkFor: PropTypes.func.isRequired,
  digibookLink: PropTypes.shape({
    digibook: PropTypes.string,
    pageNumber: PropTypes.number,
    superModuleId: PropTypes.string,
  }),
  errors: PropTypes.shape({
    digibook: PropTypes.string,
    superModuleId: PropTypes.string,
    pageNumber: PropTypes.string,
  }),
  initDefault: PropTypes.func.isRequired,
  currentDigibook: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  publishingHouseId: PropTypes.string,
  subjectId: PropTypes.string,
  methodId: PropTypes.string,
  moduleId: PropTypes.string,
  fetchDigibooksFor: PropTypes.func.isRequired,
  digibooks: PropTypes.array,
  classes: PropTypes.shape({
    select: PropTypes.string.isRequired,
    hidden: PropTypes.string.isRequired,
  }).isRequired,
  setHierarchyForLink: PropTypes.func.isRequired,
  currentModule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(moduleType)),
  }),
};

LinkAreaDigibookLinker.defaultProps = {
  digibookLink: undefined,
  currentDigibook: undefined,
  publishingHouseId: undefined,
  subjectId: undefined,
  methodId: undefined,
  moduleId: undefined,
  digibooks: undefined,
  errors: undefined,
  currentModule: undefined,
};

export const ConnectedLinkAreaDigibookLinker = connect(mapStateToProps, mapDispatchToProps)(LinkAreaDigibookLinker);

export default withStyles(styles)(ConnectedLinkAreaDigibookLinker);
