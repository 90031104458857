import { KeyboardEvent, memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { TextField } from '@mui/material';
import { tss } from 'tss-react/mui';
import { useSlidesetVersionGroups } from '../../slideset-version-detail/context/slideset-version-groups-context';
import { SlideEditorParams } from '../utils';
import { SlideGroup } from '../../../api/slideSet';

const useStyles = tss.create(() => ({
  textField: {
    width: 60,
    margin: 'auto',
    '& input': {
      textAlign: 'center',
      padding: '8px 8px',
    },
  },
}));

export default memo(function SlideNumberInput() {
  const { groups } = useSlidesetVersionGroups();
  const { linkId, slideId, moduleId, nodeId, setId, versionId } = useParams<SlideEditorParams>();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [error, setError] = useState(false);

  const getDisplayNumber = useCallback(() => {
    const currentGroupIndex = groups.findIndex(group => group.linkId === Number(linkId));
    const currentGroup = groups[currentGroupIndex];

    if (!currentGroup) return '';

    if (currentGroup.type === 'SINGLE') return `${currentGroupIndex + 1}`;

    const currentSlideIndex = groups
      .find(group => group.linkId === Number(linkId))!
      .slides.findIndex(slide => slide.id === Number(slideId));

    return `${currentGroupIndex + 1}.${currentSlideIndex + 1}`;
  }, [groups, linkId, slideId]);

  const [displayNumber, setDisplayNumber] = useState(getDisplayNumber());

  useEffect(() => {
    setDisplayNumber(getDisplayNumber());
    setError(false);
  }, [getDisplayNumber]);

  function getLastValidGroupAndSlide(remainingGroups: SlideGroup[]) {
    const group = remainingGroups[remainingGroups.length - 1];
    if (group.slides.length === 0) {
      return getLastValidGroupAndSlide(remainingGroups.slice(0, remainingGroups.length - 1));
    }

    return {
      group,
      slide: group.slides[group.slides.length - 1],
    };
  }

  function onNumberEntered(value: string) {
    const [groupNumber, slideNumber] = value.replace(',', '.').split('.').map(Number);

    if (Number.isNaN(Number(value))) return setError(true);

    if (groupNumber < 1) return setError(true);

    if (groupNumber > groups.length) {
      const { group, slide } = getLastValidGroupAndSlide(groups);

      navigate(
        `/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${group.linkId}/slides/${slide.id}`,
      );

      return setError(false);
    }

    const group = groups[groupNumber - 1];

    if (group.type === 'SINGLE') {
      navigate(
        `/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${group.linkId}/slides/${group.slides[0].id}`,
      );

      return setError(false);
    }

    const slide = group.slides[slideNumber - 1 || 0];
    if (!slide) return setError(true);

    navigate(
      `/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${setId}/versions/${versionId}/groups/${group.linkId}/slides/${slide.id}`,
    );

    return setError(false);
  }

  return (
    <TextField
      className={classes.textField}
      margin="normal"
      variant="outlined"
      value={displayNumber}
      onChange={e => setDisplayNumber(e.target.value)}
      error={error}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') onNumberEntered(displayNumber);
      }}
    />
  );
});
