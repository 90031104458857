import React from 'react';
import { TableRow, TableCell, IconButton, Theme } from '@mui/material';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { Delete, Edit } from '@mui/icons-material';
import LinkIconButton from '../../../../components/buttons/link-icon-button';
import SlidingConfirmation from '../../../../components/sliding-confirmation';
import { SlideSet, SlideSetVersionState } from '../../../../api/slideSet';
import { useAppSelector } from '../../../../hooks/store';
import { formatState } from '../../../../utils/slideset-helper';
import { getCurrentHierarchyModuleId, getCurrentTocNodeId } from '../../../../selectors/context';

const styles = (theme: Theme) => ({
  icons: {
    width: 100,
    padding: theme.spacing(),
  },
  button: {
    padding: theme.spacing(0.5),
  },
});

type Props = {
  digislide: SlideSet;
  onDelete: (id: string) => void;
} & WithStyles<typeof styles>;

export const DigiSlideRow = ({ classes, digislide, onDelete }: Props) => {
  const nodeId = useAppSelector(getCurrentTocNodeId);
  const moduleId = useAppSelector(getCurrentHierarchyModuleId);
  const draftVersion = digislide.states.every(slideset => slideset === SlideSetVersionState.DRAFT);

  return (
    <TableRow>
      <TableCell>{digislide.name}</TableCell>
      <TableCell>{formatState(digislide.states)}</TableCell>
      <TableCell className={classes.icons}>
        <LinkIconButton
          buttonProps={{
            id: 'edit',
            className: classes.button,
            color: 'primary',
          }}
          to={`/modules/${moduleId}/table-of-content/${nodeId}/slide-sets/${digislide.id}`}
        >
          <Edit />
        </LinkIconButton>
        <SlidingConfirmation
          title="Warning: permanent delete"
          context="This slide set will be permanently deleted. It cannot be restored."
          disagreeAction="Cancel"
          agreeAction="Delete"
          isWarning
        >
          <IconButton
            id="delete"
            data-testid="delete-button"
            className={classes.button}
            onClick={() => onDelete(digislide.id)}
            color="secondary"
            size="large"
            disabled={!draftVersion}
          >
            <Delete />
          </IconButton>
        </SlidingConfirmation>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(DigiSlideRow);
