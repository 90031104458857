import axios from 'axios';
import qs from 'query-string';

import * as jwt from './jwt';
import store from '../store';
import Storage from './storage';
import StorageKeys from './storage-keys';
import { refreshTokenSucceeded, refreshTokenFailed } from '../actions/authentication';

export const BASE_URI = `${PORTAAL_API_URL}/api`;

let refreshTokenPromise;

const api = axios.create({
  baseURL: BASE_URI,
});

const getAccessToken = () => {
  if (refreshTokenPromise) return refreshTokenPromise;

  const accessToken = Storage.get(StorageKeys.ACCESS_TOKEN);
  const refreshToken = Storage.get(StorageKeys.REFRESH_TOKEN);

  if (!accessToken || !refreshToken) return Promise.resolve();

  if (jwt.isExpired(accessToken, 120)) {
    const query = qs.stringify({
      token: refreshToken,
    });

    refreshTokenPromise = api
      .post(`/oauth/refresh_token?${query}`, undefined, { skipAuthorization: true })
      .then(({ data: { access_token: newAccessToken, refresh_token: newRefreshToken } }) => {
        Storage.set(StorageKeys.ACCESS_TOKEN, newAccessToken);
        Storage.set(StorageKeys.REFRESH_TOKEN, newRefreshToken);

        store.dispatch(refreshTokenSucceeded(jwt.decode(newAccessToken)));

        refreshTokenPromise = null;
        return Promise.resolve(newAccessToken);
      })
      .catch(() => {
        Storage.remove(StorageKeys.ACCESS_TOKEN);
        Storage.remove(StorageKeys.REFRESH_TOKEN);

        store.dispatch(refreshTokenFailed());

        refreshTokenPromise = null;
      });

    return refreshTokenPromise;
  }
  return Promise.resolve(accessToken);
};

api.interceptors.request.use(config => {
  if (config.skipAuthorization || config.headers.Authorization) return Promise.resolve(config);

  return getAccessToken().then(accessToken => {
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
    }
    return Promise.resolve(config);
  });
});

export default api;
