import { ModuleActionType } from '../action-types';

export default function moduleReducer(state = {}, action = {}) {
  switch (action && action.type) {
    case ModuleActionType.FETCH_MODULES_BY_METHOD: {
      const { methodId } = action.payload;
      const { entities, byMethod = {} } = state;

      return {
        entities,
        byMethod: {
          ...byMethod,
          [methodId]: {
            ...byMethod[methodId],
            isFetching: true,
          },
        },
      };
    }
    case ModuleActionType.FETCH_MODULES_BY_METHOD_SUCCESS: {
      const { methodId, entities: modules, ids } = action.payload;
      const { entities, byMethod = {} } = state;

      return {
        entities: {
          ...entities,
          ...modules,
        },
        byMethod: {
          ...byMethod,
          [methodId]: {
            ids,
            lastUpdated: Date.now(),
          },
        },
      };
    }
    case ModuleActionType.FETCH_MODULES_BY_METHOD_FAILURE: {
      const { methodId } = action.payload;
      const { entities, byMethod = {} } = state;

      const { isFetching, ...rest } = byMethod[methodId] || {};

      return {
        entities: {
          ...entities,
        },
        byMethod: {
          ...byMethod,
          [methodId]: {
            ...rest,
          },
        },
      };
    }
    default:
      return state;
  }
}
