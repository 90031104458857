import React from 'react';
import { useBlocker } from 'react-router-dom';

export default function Prompt({ when, message }: { when: boolean; message: string }) {
  const block = when;

  useBlocker(() => {
    if (block) {
      return !window.confirm(message);
    }
    return false;
  });

  return <div key={String(block)} />;
}
