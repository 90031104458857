import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { decode } from '../../services/jwt';

import Storage from '../../services/storage';
import StorageKeys from '../../services/storage-keys';

import { authenticateSucceeded } from '../../actions/authentication';

export function CallBack({ onTokenReceived }) {
  const [search] = useSearchParams();

  if (!search.err) {
    Storage.set(StorageKeys.ACCESS_TOKEN, search.get('access_token'));
    Storage.set(StorageKeys.REFRESH_TOKEN, search.get('refresh_token'));

    onTokenReceived(search.get('access_token'));
  }

  return <Navigate to={search.returnUrl || '/'} />;
}

CallBack.propTypes = {
  onTokenReceived: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onTokenReceived: accessToken => dispatch(authenticateSucceeded(decode(accessToken))),
});

export default connect(undefined, mapDispatchToProps)(CallBack);
